import { Fragment } from 'react'

const Loader = (props) => {
  const { loading = false, caption = '', flex = false, error = '' } = props

  return (
    <Fragment>
      {loading ? (
        flex ? (
          <div className="loader-flex">
            <div>
              {error ? (
                <div className="loader-caption"> {error} </div>
              ) : (
                <>
                  <div className="dot-flashing"></div>{' '}
                  <div className="loader-caption text-sm">
                    {caption}
                    {caption && ' ...'}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loader-caption"> {error} </div>
            ) : (
              <>
                <div className="dot-flashing"></div>{' '}
                <div className="loader-caption">
                  {caption}
                  {caption && ' ...'}
                </div>
              </>
            )}
          </div>
        )
      ) : null}
    </Fragment>
  )
}

export default Loader
