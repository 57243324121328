import React, { useEffect, useState } from 'react'
import Tab from '../../components/Tabs'
import { Container, Section, SectionFixed } from '../../components/Container'
import LibraryHome from '../Library/LibraryHome'
import { useStyles } from './styles'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import {
  checkUserRoleAnnotator,
  checkUserRoleSuperUser
} from '../../utils/User'
import PeopleResume from '../../containers/PeopleResume'
import { useSelector } from 'react-redux'
import ProjectList from '../../containers/ProjectList'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import ClientList from '../../containers/ClientList'

const ListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { currentTab } = useParams()
  const libId = searchParams.get('libraryId')
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { outerContainer, tabSpacing } = classes
  const navigate = useNavigate()
  const {
    LIBRARY_KNOWLEDGE,
    LIBRARY_PEOPLE_RESUME,
    LIBRARY_PROJECTS,
    LIBRARY_CLIENTS_RESUME
  } = ROUTES
  const isAnnotator = checkUserRoleAnnotator()
  const isSuperUser = checkUserRoleSuperUser()
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { domain_id } = domain

  const [activeTab, setActiveTab] = useState('')

  const tabs = [
    {
      label: 'Content',
      children: <LibraryHome libraryId={libId} showSearch />,
      route: LIBRARY_KNOWLEDGE,
      eventTracker: () => {
        trackEvent(mixpanelEvents.KNOWLEDGE_TAB_CLICKED, 'SUCCESS', {}, {})
      }
    },
    {
      label: 'People',
      children: <PeopleResume isSuperUser={isSuperUser} showSearch />,
      route: LIBRARY_PEOPLE_RESUME,
      eventTracker: () => {
        trackEvent(mixpanelEvents.PEOPLE_TAB_CLICKED, 'SUCCESS', {}, {})
      }
    },
    {
      label: 'Projects',
      children: <ProjectList isSuperUser={isSuperUser} showSearch />,
      route: LIBRARY_PROJECTS,
      eventTracker: () => {
        trackEvent(mixpanelEvents.PROJECTS_TAB_CLICKED, 'SUCCESS', {}, {})
      }
    },
    {
      label: 'Companies',
      children: <ClientList isSuperUser={isSuperUser} showSearch />,
      route: LIBRARY_CLIENTS_RESUME,
      eventTracker: () => {
        trackEvent(mixpanelEvents.CLIENTS_TAB_CLICKED, 'SUCCESS', {}, {})
      }
    }
  ]
  useEffect(() => {
    if (currentTab.includes('content')) {
      const index = tabs.findIndex((tab) => tab.label === 'Content')
      if (index !== -1) {
        setActiveTab(index)
      }
    } else if (currentTab.includes('people-resume')) {
      const index = tabs.findIndex((tab) => tab.label === 'People')
      if (index !== -1) {
        setActiveTab(index)
      }
    } else if (currentTab.includes('projects')) {
      const index = tabs.findIndex((tab) => tab.label === 'Projects')
      if (index !== -1) {
        setActiveTab(index)
      }
    } else if (currentTab.includes('companies')) {
      const index = tabs.findIndex((tab) => tab.label === 'Companies')
      if (index !== -1) {
        setActiveTab(index)
      }
    }
  }, [currentTab])

  const changeTab = (e) => {
    tabs[e].eventTracker()
    navigate(tabs[e].route, {
      state: {
        clearSearchParams: true
      }
    })
  }

  return loading ? (
    <></>
  ) : (
    <Container className={outerContainer}>
      <Section id="mui-tab" className={tabSpacing} overFlow>
        <Tab
          sectionOverFlow
          activeTab={activeTab}
          data={tabs}
          isFlex={true}
          tabChangeCallBack={(e) => changeTab(e)}
        />
      </Section>
    </Container>
  )
}

export default ListPage
