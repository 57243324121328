import { fetchLibraryVariations } from '../../store/api'
import {
  getSignedUrl,
  extractDimensions,
  getImageMeta,
  getSignedUrlHead
} from '../../utils/AWS'
import _ from 'lodash'
import moment from 'moment'

const processSearchData = async (res, s3Obj) => {
  const loadedAssets = []
  const returnData = []
  const { resultsets = [], resumeData = [] } = res

  await Promise.all(
    resultsets.map(async (result, index) => {
      const { result_type, es_id } = result
      if (['image', 'video'].includes(result_type)) {
        const {
          similarity = {},
          asset_id,
          content_id,
          result_type,
          tags = [],
          src,
          hidden_from_search,
          tag_center = []
        } = result
        const id = asset_id || content_id
        const { asset_ids = [], content_ids = [] } = similarity || {}
        const alreadyLoadedSimilar = loadedAssets.some((element) =>
          [...asset_ids, ...content_ids, id].includes(element)
        )
        if (alreadyLoadedSimilar) {
          return
        } else {
          loadedAssets.push(id)
        }
        const similarObj = []
        const similarAspectRatios = []

        const el = {
          id,
          resultType: result_type,
          result_type,
          index,
          src,
          similarity,
          similarObj,
          similarAspectRatios,
          hidden_from_search,
          es_id,
          tag_center
        }
        let url = ''
        let thumbnailLocationUrl = ''
        let currTags = tags || []
        if (result_type === 'image') {
          if (src === 'proposal') {
            const { image_data = {} } = result
            const { metadata = {} } = image_data
            let {
              labels,
              image_url,
              image_thumbnail_url,
              uploaded_on,
              uploaded_by,
              image_name,
              uploaded_by_uuid
            } = image_data
            if (!image_url) {
              image_url = metadata?.image_url
            }
            if (!image_thumbnail_url) {
              image_thumbnail_url = metadata?.image_thumbnail_url
            }
            if (!image_name) {
              image_name = metadata?.image_name
            }
            el.data = image_data
            url = image_url || image_thumbnail_url
            el.isOriginal = !!image_thumbnail_url
            el.originalFile = image_url
            el.fileName = image_name
            el.uploaded_on = uploaded_on
            el.uploaded_by = uploaded_by
            el.uploaded_by_uuid = uploaded_by_uuid
            if (!tags) {
              currTags = _.uniq(_.compact(labels?.toString().split(','))).map(
                (label) => ({ value: label.trim(), type: 'generated' })
              )
            }
          } else {
            const { image_data = {} } = result
            const {
              uploaded_by,
              uploaded_by_uuid,
              image_thumbnail_url,
              image_name,
              image_url,
              keywords = '',
              uploaded_on,
              analysed_data,
              proposal_name,
              proposal_id
            } = image_data
            if (!tags) {
              if (!_.isEmpty(keywords)) {
                currTags = _.uniq(
                  _.compact(keywords?.toString().split(','))
                ).map((label) => ({ value: label.trim(), type: 'generated' }))
              } else if (!_.isEmpty(analysed_data?.[0])) {
                const { labels } = analysed_data[0]
                currTags = _.uniq(_.compact(labels?.toString().split(','))).map(
                  (label) => ({ value: label.trim(), type: 'generated' })
                )
              }
            }
            el.data = image_data
            url = image_thumbnail_url || image_url
            el.isOriginal = !!image_thumbnail_url
            el.originalFile = image_url
            el.fileName = image_name
            el.uploaded_on = uploaded_on
            el.uploaded_by = uploaded_by
            el.uploaded_by_uuid = uploaded_by_uuid
            el.proposal_name = proposal_name
            el.proposal_id = proposal_id
          }
        } else if (result_type === 'video') {
          const { video_data = {}, generated_tags } = result
          const {
            uploaded_by,
            uploaded_by_uuid,
            metadata = {},
            keywords = '',
            uploaded_on,
            analysed_data
          } = video_data
          const { location, filename, thumbnail_location } = metadata || {}
          if (!tags) {
            if (!_.isEmpty(keywords)) {
              currTags = _.uniq(_.compact(keywords?.toString().split(','))).map(
                (label) => ({ value: label.trim(), type: 'generated' })
              )
            } else if (!isEmpty(analysed_data)) {
              const labelsArray = []
              analysed_data.forEach((shot) => {
                const { labels } = shot
                if (!_.isEmpty(labels)) {
                  labelsArray.push(...labels)
                }
              })
              currTags = _.uniq(
                _.compact(labelsArray?.toString().split(','))
              ).map((label) => ({ value: label, type: 'generated' }))
            }
          }
          url = location
          thumbnailLocationUrl = thumbnail_location
          el.data = video_data
          el.originalFile = location
          el.fileName = filename
          el.uploaded_on = uploaded_on
          el.uploaded_by = uploaded_by
          el.uploaded_by_uuid = uploaded_by_uuid
        }
        el.tags = currTags
        el.location = result?.location
        if (url) {
          try {
            el.url = (await getSignedUrl(url, s3Obj)) + '&cacheblock=true'
            if (thumbnailLocationUrl) {
              el.thumbnailLocationUrl =
                (await getSignedUrl(thumbnailLocationUrl, s3Obj)) +
                '&cacheblock=true'
            }
            if (el.originalFile) {
              el.originalFile =
                (await getSignedUrl(el.originalFile, s3Obj)) +
                '&cacheblock=true'
            }
          } catch (e) {
            console.log(e)
          }
        }
        const date = new Date(el?.uploaded_on + 'Z')
        el.date = moment(date.toString()).format('ll')

        const { width = 430, height = 290 } = await extractDimensions(
          result,
          el?.url,
          result_type
        )
        if (el.url) {
          el.aspectRatio = width / height
          const tagsValue = _.isArray(el?.tags)
            ? el.tags.map((tag) => tag.values)
            : []
          returnData.push(el)
        }
      } else {
        returnData.push(result)
      }
    })
  )

  const formatedResumeData = []
  await Promise.all(
    resumeData.map(async (result, index) => {
      let {
        emp_name,
        resume_data,
        master_resume_content,
        profile_picture_location,
        id,
        domain_id,
        variations = [],
        master_group_id
      } = result
      // let parsedResumes = []
      const resumes = []
      const profile_pic = ''
      const variationsClone = _.cloneDeep(variations)
      // variationsClone.forEach((variation) => {
      //   try {
      //     variation.resume_data = JSON.parse(variation.resume_data)
      //   } catch (e) {
      //     variation.resume_data = null
      //   }
      // })
      variations = variationsClone

      // try {
      //   parsedResumes = JSON.parse(resume_data)
      // } catch (error) {
      //   parsedResumes = []
      // }
      formatedResumeData.push({
        name: emp_name,
        profile_picture_location,
        resume_data,
        id,
        es_id: id,
        variations,
        master_group_id
      })
    })
  )
  const totalResults = [...returnData]
  const totalResume = [...formatedResumeData]

  return {
    resultsets: totalResults,
    resumesets: totalResume
  }
}

const addScreenshotsToSections = async (
  result,
  index,
  s3Obj,
  domainId,
  setSearchResults,
  searchType
) => {
  try {
    const { screenshots, thumbnail_location } = result
    let combinedScreenshots = [...screenshots]
    if (_.isEmpty(combinedScreenshots)) {
      if (thumbnail_location) {
        combinedScreenshots = [{ src: thumbnail_location }]
      } else {
        const { proposal_id, page_number, document_id } = result
        if (
          process.env.REACT_APP_IMAGE_BUCKET &&
          (proposal_id || document_id) &&
          (page_number === 0 || page_number) &&
          domainId
        ) {
          const potentialLocation = `https://${
            process.env.REACT_APP_IMAGE_BUCKET
          }.s3.amazonaws.com/snapshots/${domainId}/${
            proposal_id || document_id
          }/original/${page_number}.png`
          combinedScreenshots = [{ src: potentialLocation }]
        }
      }
    }
    const promises = combinedScreenshots.map(async (e, i) => {
      let { src, width, height } = { ...e }
      const signedSrc = await getSignedUrlHead(src, s3Obj)
      if (signedSrc) {
        if (!width || !height) {
          const img = await getImageMeta(signedSrc)
          width = img.width
          height = img.height
        }
        return { ...e, src: signedSrc, width, height }
      }
      return e
    })
    const updatedScreenshots = await Promise.all(promises)
    if (searchType) {
      setSearchResults((prev) => {
        const updatedResults = _.cloneDeep(prev)
        if (updatedResults && updatedResults?.[searchType]?.results?.[index]) {
          updatedResults[searchType].results[index].screenshots =
            updatedScreenshots
          return updatedResults
        }
        return prev
      })
    } else {
      setSearchResults((prev) => {
        const updatedResults = _.cloneDeep(prev)
        if (updatedResults && updatedResults.results?.[index]) {
          updatedResults.results[index].screenshots = updatedScreenshots
          return updatedResults
        }
        return prev
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const addVariationsToKnowledges = async (
  result,
  index,
  domainId,
  setSearchResults,
  abortController,
  searchType
) => {
  try {
    const { library_id } = result
    const data = {
      domain_id: domainId,
      id: library_id
    }
    const newController = new AbortController()
    abortController.current = [...abortController.current, newController]
    const res = await fetchLibraryVariations(data, {
      signal: newController.signal
    })
    const variationsFromApiKnowledge = []
    if (res.status === 200) {
      const { resultsets = [] } = res.data
      resultsets.forEach((element) => {
        variationsFromApiKnowledge.push(element)
      })
      if (searchType) {
        setSearchResults((prev) => {
          const updatedResults = _.cloneDeep(prev)
          if (
            updatedResults &&
            updatedResults?.[searchType]?.results?.[index]
          ) {
            updatedResults[searchType].results[index].variations =
              variationsFromApiKnowledge
            return updatedResults
          }
          return prev
        })
      } else {
        setSearchResults((prev) => {
          const updatedResults = _.cloneDeep(prev)
          if (updatedResults && updatedResults.results?.[index]) {
            updatedResults.results[index].variations =
              variationsFromApiKnowledge
            return updatedResults
          }
          return prev
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export {
  processSearchData,
  addScreenshotsToSections,
  addVariationsToKnowledges
}
