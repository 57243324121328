import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import Delete from '@mui/icons-material/DeleteOutline'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import {
  deleteLibraryDocument,
  updateLibraryDocument
} from '../../store/Library/Actions'
import { copyHTMLContent } from '../../utils/CopyHTML'
import AddToCollection from './AddToCollection'
import { IconButton } from '@mui/material'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Button, TextLineLimiter, useConfirmation } from '../../components'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as SimilarIcon } from '../../assets/images/Similar_Icon.svg'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import SuggestionSource from './SuggestionSource'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import moment from 'moment'
import ReactDOM from 'react-dom/client'
import { toast } from 'react-toastify'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { getColorsForInitials } from '../../utils/String'
import {
  CopiedIcon,
  CopyIcon,
  PencilIcon,
  TrashIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'

const cleanMarkTags = (htmlString) => {
  return htmlString.replace(/<\/?mark[^>]*>/g, '')
}

const LibraryCard = (props) => {
  const {
    userId = '',
    enableDrag = false,
    onDrag = () => {},
    result_type = '',

    onDragEnd = () => {},
    data,
    onClick,
    index,
    setCurrentCollection,
    isDeleteable = true,
    isAddToCollection = true,
    handleVariation,
    showVariations = false,
    onPDFClick,
    onTitleClick,
    disableEdit = false,
    src = '',
    isSuggestion = false,
    onSuggestion = () => {},
    selectedImages = [],
    setSelectedImages = () => {},
    s3Obj = {},
    setSelectedImageIndex = () => {},
    variationsLabel = 'Similar Results',
    disabled = false,
    selectedFile = ''
  } = props

  const {
    id,
    metadata_ = {},
    collections = {},
    variationsFromApiKnowledge = []
  } = data

  const variationsCount = variationsFromApiKnowledge.length
  const isSuperUser = checkUserRoleSuperUser()
  const { src: cardSource = 'user' } = metadata_ || {}
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  let {
    document_html = '',
    document_name = '',
    narrative_content = '',
    narrative_title = ''
  } = data
  document_html = document_html || narrative_content
  document_html = cleanMarkTags(document_html)
  document_name = document_name || narrative_title
  document_name = cleanMarkTags(document_name)
  const collectionPublic = []
  const collectionPrivate = []
  Object.keys(collections || {}).forEach((key) => {
    const { name, visibility, visible_users } = collections[key]
    if (visibility === 'public') {
      collectionPublic.push(name)
    } else if (visibility === 'private' && visible_users.includes(userId)) {
      collectionPrivate.push(name)
    }
  })

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [copied, setCopied] = useState(false)
  const classes = useStyles({
    disabled,
    isBodyClickable: !!onClick,
    result_type,
    isTagClickable: !!setCurrentCollection
  })
  const {
    card,
    titleName,
    footerEnd,
    content,
    tagsDetail,
    expandContentDetails,
    titleContainer,
    collapseContent,
    expandContent,
    listActionsContainer,
    tagContainer,
    tagItems,
    expandCollapseContainer,
    contentDisplay,
    moreContentDisplay,
    newLineCharaters,
    defaultHeight,
    titleLink,
    dragIndicator
  } = classes
  const [showSuggestionSource, setShowSuggestionSource] = useState(false)
  const dispatch = useDispatch()

  const isUserViewer = checkUserRoleViewer()

  const contentIdParams = searchParams.get('content_id')

  useEffect(() => {
    if (contentIdParams) {
      const elementId = `${contentIdParams}`
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  const deleteLibrary = () => {
    dispatch(deleteLibraryDocument(selectedLibrary))
    handleDialogClose()
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleDeleteClick = (val, collections) => {
    const data = {
      id: val,
      collections
    }
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(deleteLibraryDocument(data))
      },
      confirmationMessageTitle: `Are you sure you want to delete the knowledge "${document_name}" ?`,
      confirmationMessage:
        'This action will also remove the knowledge from all the collections.'
    })
  }

  const extractContent = (html) => {
    return new DOMParser().parseFromString(html, 'text/html').documentElement
      .textContent
  }

  const copyText = (html) => {
    trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_COPIED, 'SUCCESS', {}, {})
    copyHTMLContent(html)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
    // toast.success('Copied')
  }

  const stripLinks = (html) => {
    return html
      ?.replaceAll('<a', '<span')
      ?.replaceAll('</a>', '</span>')
      ?.replaceAll('class="PlaygroundEditorTheme__link"', '')
  }

  const onClickCard = () => {
    if (onClick && !window.getSelection().toString() && !disabled) {
      onClick(id)
    }
  }
  const handleDragEnd = () => {
    const ghost = document.getElementById('drag-ghost')
    if (ghost?.parentNode) {
      ghost.parentNode.removeChild(ghost)
    }
    onDragEnd()
  }

  const handleCollectionUpdate = (newValues, newCollections) => {
    const { id } = data
    if (id) {
      const params = {
        id,
        collections: newValues,
        collectionValues: newCollections
      }
      dispatch(updateLibraryDocument(params))
    }
  }

  const handleTagClick = (e, tag) => {
    e.stopPropagation()
    if (setCurrentCollection && !disabled) {
      setCurrentCollection(tag)
    }
  }

  const renderSource = () => {
    const {
      proposal_filename = '',
      pageIndex = 0,
      proposal_id
    } = metadata_ || {}
    const path = `/view/pdf/${proposal_id}/${pageIndex}`
    const onClick = (e) => {
      if (onPDFClick && !disableEdit) {
        onPDFClick(metadata_)
      } else {
        e.stopPropagation()
        window.open(path)
      }
    }
    return (
      proposal_filename && (
        <Box
          sx={{ color: 'grey' }}
          className={titleLink}
          onClick={(e) => onClick(e)}
        >
          <Box sx={{ fontSize: '14px' }}>
            (Page&nbsp;{pageIndex}) {proposal_filename}
          </Box>
        </Box>
      )
    )
  }

  const handleTitleClick = () => {
    if (onTitleClick && !disableEdit) {
      const { id, narrative_title } = data
      onTitleClick(id, narrative_title)
    }
  }

  const handleDrag = (event, data) => {
    const { narrative_title, narrative_content } = data
    const newContent = `<html> <body> <b>${narrative_title}</b> <br>${narrative_content}</body></html>`
    event.dataTransfer.setData('text/html', newContent)
    event.dataTransfer.setData('dragcustomrichtext', JSON.stringify(true))
    const elem = document.createElement('div')
    elem.id = 'drag-ghost'
    const name = cleanMarkTags(narrative_title)
    const jsxContent = (
      <Box
        sx={{
          background: '#DBEAFF',
          display: 'flex',
          alignItems: 'center',
          width: '300px',
          position: 'absolute',
          top: '-9999px',
          padding: '10px',
          gap: '10px',
          fontSize: '14px',
          color: '#797979',
          '& .MuiSvgIcon-root': {
            fontSize: '30px !important'
          }
        }}
      >
        <Box>
          <span dangerouslySetInnerHTML={{ __html: name }} />
        </Box>
      </Box>
    )
    const root = ReactDOM.createRoot(elem)
    root.render(jsxContent)
    document.body.appendChild(elem)
    event.dataTransfer.setDragImage(elem, 0, 0)
  }

  const isEditorDrag = src === 'editor'
  const { selectedColor } = useColor()

  return (
    <>
      <div
        className={card + ' parent-card'}
        id={id}
        key={index}
        onClick={() => onClickCard()}
        draggable={!disabled && (enableDrag || isEditorDrag)}
        onDragStart={(event) => {
          if (isEditorDrag) {
            handleDrag(event, data)
          } else {
            onDrag(event, data)
          }
        }}
        onDragEnd={() => handleDragEnd()}
        style={{
          border:
            contentIdParams === id
              ? `2px solid ${selectedColor}`
              : '1px solid var(--grey-200)'
        }}
      >
        <Box className={titleContainer}>
          <Box className={titleName}>
            <Box>
              {result_type === 'narrative' ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={onTitleClick ? titleLink : ''}
                    onClick={() => handleTitleClick()}
                  >
                    <TextLineLimiter
                      content={
                        <span
                          dangerouslySetInnerHTML={{ __html: document_name }}
                        />
                      }
                      startAt="<mark>"
                      isStartAtHTML="mark"
                      limit={1}
                    />
                  </div>
                  <div
                    className="bg-grey-100  rounded-xl text-grey-800 p-1 text-xs px-3"
                    style={{
                      border: '1px solid var(--grey-100)'
                    }}
                  >
                    {result_type === 'narrative' && 'Knowledge'}
                  </div>
                </div>
              ) : (
                <div
                  className={onTitleClick ? titleLink : ''}
                  onClick={() => handleTitleClick()}
                >
                  {/* <TextLineLimiter
                    content={
                      <span
                        dangerouslySetInnerHTML={{ __html: document_name }}
                      />
                    }
                    startAt="<mark>"
                    isStartAtHTML="mark"
                    limit={1}
                    width="70%"
                  /> */}

                  <p className="text-sm">{document_name}</p>
                </div>
              )}
            </Box>
            {['search', 'proposal'].includes(cardSource) && renderSource()}
          </Box>
        </Box>
        <Box className={clsx(content, expandContent)}>
          <div className={isEditorDrag ? dragIndicator : ''}>
            {isEditorDrag && <DragIndicatorIcon />}
            <TextLineLimiter
              content={
                <span dangerouslySetInnerHTML={{ __html: document_html }} />
              }
              startAt="<mark>"
              isStartAtHTML="mark"
              limit={2}
            />
          </div>
        </Box>
        <Grid container className="mt-auto">
          <Grid xs>
            {isSuggestion ? (
              <Box className={footerEnd}>
                <Box className={tagsDetail}>
                  {isSuperUser && (
                    <Button
                      variant="outlined"
                      style={{
                        marginRight: '10px',
                        cursor: 'auto',
                        background: '#FFFFFF',
                        color: '#797979',
                        border: '1px solid'
                      }}
                    >
                      #Score: {data?.score || 0}
                    </Button>
                  )}
                  {!_.isEmpty(data?.metadata_?.content_ids) && (
                    <Button
                      style={{
                        background: '#DBEAFF',
                        color: selectedColor,
                        border: '0px'
                      }}
                      variant="outlined"
                      onClick={() =>
                        setShowSuggestionSource(!showSuggestionSource)
                      }
                    >
                      <SvgIcon>
                        <SimilarIcon />
                      </SvgIcon>
                      <div style={{ marginLeft: '7px' }}>
                        {showSuggestionSource ? 'Hide Source ' : 'View Source'}
                      </div>
                    </Button>
                  )}
                </Box>
                <Box className={listActionsContainer}>
                  <Button
                    disabled={disabled}
                    style={{
                      background: '#FFFFFF',
                      color: '#797979',
                      border: '1px solid'
                    }}
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSuggestion(data, true)
                    }}
                  >
                    Add to Library
                  </Button>
                  <Button
                    disabled={disabled}
                    style={{
                      marginLeft: '10px',
                      background: '#FFFFFF',
                      color: '#797979',
                      border: '1px solid'
                    }}
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSuggestion(data, false)
                    }}
                  >
                    Ignore
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box className={footerEnd}>
                  <Box
                    className={listActionsContainer}
                    style={{
                      width: '100%'
                    }}
                  >
                    <div className="flex items-center gap-1">
                      <div className="flex items-center gap-1 text-grey-600 text-xs">
                        <div
                          className="size-4 text-xxs grid place-content-center text-white rounded-full"
                          style={{
                            background: getColorsForInitials(
                              data?.created_by_user
                            )
                          }}
                        >
                          {data?.created_by_user?.[0]}
                        </div>
                        <div>{data?.created_by_user}</div>
                      </div>
                      <div className="bg-grey-400 size-1 rounded-full"></div>
                      {data?.created_at && (
                        <Box className="text-grey-600 text-xs">
                          {/* <AccessTimeIcon sx={{ fontSize: '18px' }} /> */}
                          {moment
                            .utc(data?.created_at)
                            .local()
                            .format('DD MMM YYYY')}
                        </Box>
                      )}
                    </div>

                    <Box
                      className={tagsDetail}
                      style={{
                        marginRight: 'auto',
                        marginLeft: '8px',
                        gap: '4px'
                      }}
                    >
                      {handleVariation && variationsCount > 0 && (
                        <>
                          <div className="bg-grey-400 size-1 rounded-full"></div>
                          <Box className="relative">
                            <Button2
                              secondary
                              style={{
                                padding: '5px 10px'
                              }}
                              disabled={parseInt(variationsCount) < 1}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                handleVariation(id)
                              }}
                            >
                              <SvgIcon
                                style={{
                                  height: '15px',
                                  width: '15px'
                                }}
                              >
                                <SimilarIcon />
                              </SvgIcon>
                              <div style={{ marginLeft: '2px' }}>
                                {showVariations
                                  ? `Hide ${variationsLabel}`
                                  : `${variationsLabel}`}
                              </div>
                            </Button2>

                            {!showVariations && (
                              <span
                                className="absolute top-0 right-0 text-xxs bg-grey-700 rounded-full size-4 text-white grid place-content-center font-medium"
                                style={{
                                  top: '-6px',
                                  right: '-6px',
                                  fontStyle: 'normal'
                                }}
                              >
                                {variationsCount}
                              </span>
                            )}
                          </Box>
                        </>
                      )}
                      {collectionPrivate.length > 0 ||
                        (collectionPublic.length > 0 && (
                          <div className="bg-grey-400 size-1 rounded-full"></div>
                        ))}
                      {collectionPublic.map((collection, index) => (
                        <Box
                          key={index}
                          className="bg-grey-100 font-medium rounded-xl text-grey-800 p-1 text-xxs px-2 flex items-center gap-1"
                          sx={{
                            border: '1px solid var(--grey-200)'
                          }}
                          onClick={(e) => handleTagClick(e, collection)}
                        >
                          <Tooltip title={'Visible to all'}>
                            <LanguageOutlinedIcon
                              sx={{
                                strokeWidth: 1,
                                stroke: '#ffffff',
                                fontSize: '13px'
                              }}
                            />
                          </Tooltip>
                          <Box
                            variant={'span'}
                            sx={{ fontSize: '10px', fontStyle: 'normal' }}
                          >
                            {collection}
                          </Box>
                        </Box>
                      ))}
                      {collectionPrivate.map((collection, index) => (
                        <Box
                          key={index}
                          className="bg-grey-100 font-medium rounded-xl text-grey-800 p-1 text-xxs px-2 flex items-center gap-1"
                          sx={{
                            border: '1px solid var(--grey-200)'
                          }}
                          onClick={(e) => handleTagClick(e, collection)}
                        >
                          <Tooltip title={'Visible to only you'}>
                            <LockOutlinedIcon
                              sx={{
                                strokeWidth: 1,
                                stroke: '#ffffff',
                                fontSize: '13px'
                              }}
                            />
                          </Tooltip>
                          <Box
                            variant={'span'}
                            sx={{ fontSize: '10px', fontStyle: 'normal' }}
                          >
                            {collection}
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <div className="card-options2 flex items-center gap-1">
                      <div className="flex items-center gap-1">
                        {!isUserViewer && (
                          <Button2
                            secondary
                            noOutline
                            style={{
                              padding: '5px 6px'
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDeleteClick(id, collections)
                            }}
                          >
                            <TrashIcon className="text-grey-600 size-4" />
                          </Button2>
                        )}
                        <Button2
                          secondary
                          noOutline
                          style={{
                            padding: '5px 6px'
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            copyText(document_html)
                          }}
                        >
                          {copied ? (
                            <span className="flex items-center gap-1 text-xxs">
                              <CopiedIcon className="text-grey-600 size-4" />
                              copied
                            </span>
                          ) : (
                            <CopyIcon className="text-grey-600 size-4" />
                          )}
                        </Button2>
                      </div>
                      {/* <Button2
                        primary
                        style={{
                          padding: '5px 6px'
                        }}
                      >
                        <PencilIcon className="size-4" />
                      </Button2> */}

                      {isAddToCollection && !disableEdit && (
                        <AddToCollection
                          collections={collections}
                          onSelect={handleCollectionUpdate}
                          isFilled={collectionPrivate?.length > 0}
                          disabled={disabled || isUserViewer}
                        />
                      )}
                    </div>

                    {/* {data?.number_of_views > -1 && (
                      <Tooltip title="Number of Views">
                        <Box
                          sx={{
                            padding: '6.5px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            color: '#797979'
                          }}
                        >
                          <VisibilityIcon sx={{ fontSize: '18px' }} />
                          {data?.number_of_views}
                        </Box>
                      </Tooltip>
                    )} */}
                    {/* {isAddToCollection && !disableEdit && (
                      <AddToCollection
                        collections={collections}
                        onSelect={handleCollectionUpdate}
                        isFilled={collectionPrivate?.length > 0}
                        disabled={disabled || isUserViewer}
                      />
                    )}
                    <Tooltip title="Copy">
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          copyText(document_html)
                        }}
                      >
                        <ContentCopyIcon
                          sx={{ color: '#797979', fontSize: '16px' }}
                        />
                      </IconButton>
                    </Tooltip>
                    {isDeleteable && (
                      <Tooltip title="Delete">
                        <IconButton
                          disableRipple
                          disabled={disabled || isUserViewer}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteClick(id, collections)
                          }}
                        >
                          <TrashIcon className="text-grey-600 size-4" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      {ConfirmDialog}
      {isSuggestion && (
        <SuggestionSource
          showSuggestionSource={showSuggestionSource}
          setShowSuggestionSource={() => setShowSuggestionSource(false)}
          data={data}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          setSelectedImageIndex={setSelectedImageIndex}
          s3Obj={s3Obj}
        />
      )}
    </>
  )
}

export default LibraryCard
