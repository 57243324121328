import { USER_SIGN_IN, SET_LOADING } from '../../../actionTypes'
import { fetchUser } from '../../../api'
import eventMapping from '../../../../config/eventMapping'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import { toast } from 'react-toastify'

const userSignIn =
  (user, callback = () => {}) =>
  async (dispatch) => {
    const MAX_RETRIES = 2

    const signIn = async (retries = 0) => {
      const response = await fetchUser()
      const userObj = { auth: user }

      if (response.status === 200) {
        userObj.domain = response.data
        const { domain_id } = response.data

        if (domain_id) {
          callback(response.data, userObj)
          dispatch({
            type: USER_SIGN_IN,
            payload: userObj
          })
        } else {
          dispatch({
            type: USER_SIGN_IN,
            payload: userObj
          })
        }
      } else if (retries < MAX_RETRIES) {
        const delay = (retries + 1) * 5 * 1000
        setTimeout(() => signIn(retries + 1), delay)
      } else {
        userObj.domain = {
          error: true
        }
        dispatch({
          type: USER_SIGN_IN,
          payload: userObj
        })
      }
      dispatch({
        type: SET_LOADING,
        payload: false
      })
    }

    signIn()
  }

export default userSignIn
