import React, { useEffect, useState } from 'react'
import { getDomain } from '../../store/api'
import { Container, Section, SectionFixed } from '../../components/Container'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { useStyles } from './styles'
import JoinDomain from '../../sections/DomainList'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from '../../config/routes'
import Loader from '../../components/Loader'
import Logo from '../../assets/images/JoistLogo.png'
import team from '../../assets/images/team2.gif'
import teamAnim from '../../assets/lottie/team.json'
import { isArray, isEmpty } from 'lodash'
import Lottie from 'react-lottie'
import Button2 from '../../components/Button/Button2'
import { signOut } from '../../utils/User'

const Domain = () => {
  const userData = useSelector((state) => state.authenticate?.user)
  const domainData = userData?.domain
  const { domain_id = '', email = '' } = domainData
  const [domain, setDomain] = useState({})
  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { container, titleH3, titleH4, box, loaderHeight } = classes
  const { domain_list = '' } = domain
  const navigate = useNavigate()
  const { DASHBOARD } = ROUTES
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const res = await getDomain()
      if (res.status === 200) {
        setDomain(res.data)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (domain_id) {
      navigate(DASHBOARD)
    }
  }, [domain_id])

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={loaderHeight}
      >
        <Loader loading={loading} caption={'Fetching your team'} />
      </Grid>
    )
  }
  return (
    !domain_id && (
      <Container>
        <Section>
          <div className="grid grid-col-2 h-full">
            <div
              className="grid place-content-center"
              style={{
                padding: '50px'
              }}
            >
              <p
                className="m-0 text-2xl font-medium"
                style={{
                  width: '90%',
                  marginBottom: '30px'
                }}
              >
                Join your team to get started & collaborate seamlessly
              </p>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: teamAnim
                }}
                // height={350}
              />
            </div>
            <div
              className=""
              style={{
                padding: '24px',
                borderLeft: '0.7px solid var(--grey-200)',
                overflow: 'auto',
                maxHeight: 'calc(100vh - 48px)'
              }}
            >
              {(isArray(domain?.domain_list) &&
                !isEmpty(domain?.domain_list)) ||
              (isArray(domain?.invite_list) &&
                !isEmpty(domain?.invite_list)) ? (
                <div className="grid place-content-center">
                  <div style={{ padding: '10px 18px', textAlign: 'center' }}>
                    <img
                      src={Logo}
                      alt="applogo"
                      style={{
                        width: '100px',
                        padding: '10px 18px'
                      }}
                    />
                    <p
                      // variant="h5"
                      className="m-0 text-2xl font-medium"
                    >
                      Hello there! Welcome aboard!
                    </p>
                  </div>
                  <Box className={box}>
                    <JoinDomain domain={domain} />
                  </Box>
                  <Box className="flex justify-center mt-4">
                    <Button2 onClick={() => signOut(dispatch)}>Log out</Button2>
                  </Box>
                </div>
              ) : (
                <Box
                  sx={{
                    // width: '500px',
                    textAlign: 'center',
                    marginTop: '25px'
                  }}
                >
                  <Typography
                    sx={{ marginTop: '20px' }}
                    variant="h5"
                    className={titleH3}
                  >
                    Uh oh! It appears that you don't have any available domains
                    to join.
                  </Typography>
                  <Box
                    sx={{
                      textAlign: 'center',
                      marginTop: '20px',
                      fontSize: '15px'
                    }}
                    className={titleH3}
                  >
                    If your organization is already a part of Joist, please
                    request an invite from your administrator. If you are new
                    here, please contact us at{' '}
                    <a href="https://www.joist.ai">joist.ai</a>&nbsp;to get
                    started.
                  </Box>
                  <Box className="flex justify-center mt-4">
                    <Button2 onClick={() => signOut(dispatch)}>Log out</Button2>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </Section>
      </Container>
    )
  )
}

export default Domain
