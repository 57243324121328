import React, { useState } from 'react'
import {
  CircleLoader,
  CrossIcon,
  PencilIcon,
  PlusIcon,
  ProjectIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import { Dialog, DialogContent, Fade } from '@mui/material'
import { SelectDropDown } from '../../components'
import { updatePeopleInfo } from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { changeClientInfo, changeProjectInfo } from '../../store/api'

const EditClientModal = ({ open, setOpen, client, onUpdate, logo }) => {
  const [updating, setUpdating] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [search, setSearch] = useState('')
  const [fetchingPeople, setFetchingPeople] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const dispatch = useDispatch()
  console.log('people profile', client)

  const handleClose = () => {
    setSelectedPeople(null)
    setOpen(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }
  console.log(client)
  const [basicInfo, setBasicInfo] = useState({
    id: client?.id,
    name: client?.title,
    logo,
    // logo: people?.profile_picture ?? people?.profile_picture_location,
    tags: client?.tags ?? [],
    clientType: client?.properties?.client_type?.value ?? 'client'
  })

  const handleProfileUpdate = async (info) => {
    // const id = toast.loading('Changing name...')

    if (info.name === '') {
      toast.error('Name cannot be empty')
      return
    }

    const data = {
      client_group_id: info.id,
      client_group_name: info.name,
      properties: {
        client_type: {
          verified: true,
          value: info.clientType
        }
      }
    }

    setUpdating(true)

    const callback = () => {
      // setEditedName('')
      // toast.dismiss(id)
      onUpdate()
      setUpdating(false)
    }
    trackEvent(
      mixpanelEvents.CLIENT_INFO_UPDATED,
      'Success',
      {},
      {
        ...data
      }
    )
    console.log('data', data)
    const res = await changeClientInfo(data)

    if (res.status === 200) {
      //   toast.success('Project updated successfully')
      callback()
    } else {
      toast.error('Client update failed')
      setUpdating(false)
    }
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        className="p-3 border-box text-sm"
        style={{
          width: '450px'
        }}
      >
        <DialogContent
          style={{
            padding: '0',
            minHeight: '400px',
            overflow: 'hidden'
          }}
        >
          <div className="">
            <div
              className="flex justify-center"
              style={{
                height: '100px'
              }}
            >
              {' '}
              {basicInfo.logo ? (
                <img
                  src={basicInfo.logo}
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    border: '1px solid var(--grey-200)'
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    // padding: '6px',
                    borderRadius: '10px',
                    border: '1px solid var(--grey-200)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--grey-100)'
                  }}
                >
                  {basicInfo.name?.split(' ')?.[0]?.[0]}
                  {basicInfo.name?.split(' ')?.[1]?.[0]
                    ? basicInfo.name?.split(' ')?.[1]?.[0]
                    : basicInfo.name?.split(' ')?.[0]?.[1]?.toUpperCase()}
                </div>
              )}
            </div>
            <div className="text-left">
              <span className="text-xs text-grey-600 ml-1 ">Name</span>
              <input
                type="text"
                className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded-md text-sm border-box mt-1"
                autoFocus
                value={basicInfo?.name}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  e.stopPropagation()
                  setBasicInfo({
                    ...basicInfo,
                    name: e.target.value
                  })
                }}
                onBlur={(e) => {
                  e.stopPropagation()
                }}
              />
            </div>
            <div className="text-left mt-4">
              <span className="text-xs text-grey-600 ml-1">Client type</span>
              <div className="mt-1">
                <SelectDropDown
                  name="employeeType"
                  label=""
                  value={basicInfo.clientType}
                  options={[
                    { label: 'Client', value: 'client' },
                    { label: 'Partner', value: 'partner' },
                    { label: 'Competitor', value: 'competitor' }
                  ]}
                  handleChange={(e) => {
                    setBasicInfo({
                      ...basicInfo,
                      clientType: e
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </div>
      <div className="flex justify-end gap-2 items-center p-2 border-t-1">
        <Button2
          secondary
          noOutline={true}
          onClick={handleClose}
          style={{
            padding: '8px 16px'
          }}
        >
          Cancel
        </Button2>
        <Button2
          onClick={() => {
            handleProfileUpdate(basicInfo)
          }}
          primary
          style={{
            padding: '8px 16px'
          }}
        >
          {updating ? (
            <CircleLoader className="animate-spin text-white size-4" />
          ) : (
            'Update'
          )}
        </Button2>
      </div>
    </Dialog>
  )
}

export default EditClientModal
