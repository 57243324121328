import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  SemiCircleIcon,
  TrashIcon
} from '../../components/Icons/Icons'
import {
  ImageAvatarFallback,
  ReactSelect,
  SelectDropDown,
  useConfirmation
} from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useDispatch } from 'react-redux'
import { mergePeople } from '../../store/People/Actions'
import { toast } from 'react-toastify'
import {
  createProfile,
  deleteGoupProfile,
  getAllProposalNames,
  updateProperties
} from '../../store/api'

const CreateObjectModal = ({ isOpen, onSuccess, onClose, s3Obj, type }) => {
  const [collection, setCollection] = useState(null)
  const [isPrivate, setIsPrivate] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [loading, setLoading] = useState(false)
  const [finalObject, setFinalObject] = useState(null)
  const [finalProperties, setFinalProperties] = useState(null)
  const [selectedData, setSelectedData] = useState([])
  const [profileImages, setProfileImages] = useState([])
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('')
  const [groupIds, setGroupIds] = useState(
    selectedData.map((person) => person.id)
  )
  const [validationError, setValidationError] = useState(true)
  const [uploadedImages, setUploadedImages] = useState([])
  const dispatch = useDispatch()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const handleCreate = async () => {
    setLoading(true)

    const body = {
      group_name: finalObject?.name,
      group_type: type,
      properties: finalObject?.properties
    }

    if (type === 'people') {
      if (finalObject?.profile_picture) {
        body.profile_picture = finalObject?.profile_picture
      }
    }

    if (type === 'client') {
      body.client_domain = finalObject?.client_domain
      body.proposal_id_list = finalObject?.proposal_id_list
    }

    if (type === 'project') {
      body.project_location = finalObject?.project_location
    }

    // const id = toast.loading('Please wait...')

    // const event = {
    //   people: mixpanelEvents.PEOPLE_BULK_UPDATE,
    //   client: mixpanelEvents.CLIENT_BULK_UPDATE,
    //   project: mixpanelEvents.PROJECT_BULK_UPDATE
    // }

    // trackEvent(
    //   event[type],
    //   'SUCCESS',
    //   {},
    //   {
    //     group_ids: groupIds,
    //     group_type: type,
    //     property: finalProperties
    //   }
    // )
    // setIsMerge('loading')

    const callback = () => {
      // setIsMerge(false)
      // setSelectedData([])
      // toast.dismiss(id)

      console.log('success')
      setLoading(false)

      onSuccess()
    }

    const fallback = () => {
      // setIsMerge(true)
      // toast.dismiss(id)
      toast.error('Failed to Create')
      setLoading(false)
    }

    console.log('body create', body)
    createProfile(body).then(callback).catch(fallback)
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const dropdownValues = {
    people: {
      label: 'Employee type',
      key: 'employee_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' },
        { label: 'Inactive', value: 'inactive' }
      ]
    },

    client: {
      label: 'Client type',
      key: 'client_type',
      options: [
        { label: 'Client', value: 'client' },
        { label: 'Partner', value: 'partner' },
        { label: 'Competitor', value: 'competitor' }
      ]
    },

    project: {
      label: 'Project type',
      key: 'project_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' }
      ]
    }
  }

  useEffect(() => {
    // check validation for mandatory fields
    const mandatoryFields = config[type].form.filter((field) => field.mandatory)

    console.log('mandatoryFields', mandatoryFields)
    let validationErrorTemp = false

    mandatoryFields.map((field) => {
      if (field.property) {
        if (
          !finalObject?.properties?.[field.key]?.value ||
          finalObject?.properties?.[field.key]?.value === ''
        ) {
          validationErrorTemp = true
        }
        return null
      }

      if (field.type === 'text' || field.type === 'dropdown') {
        console.log(
          'validation error',
          finalObject?.[field.key],
          finalObject,
          field.key,
          finalObject?.[field.key] === ''
        )
        if (!finalObject?.[field.key] || finalObject?.[field.key] === '') {
          validationErrorTemp = true
        }
      }

      return null
    })

    setValidationError(validationErrorTemp)
  }, [finalObject])

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          maxHeight: '450px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div
        className="flex flex-col justify-between h-full relative"
        style={{
          width: '400px',
          maxHeight: '450px'
          // overflow: 'auto'
        }}
      >
        <div className="">
          <p className="m-0 text-sm mb-3 font-medium">
            New
            {type === 'people'
              ? ' Person'
              : type === 'client'
              ? ' Company'
              : ' Project'}
          </p>
        </div>

        <div>
          <FormSchema
            form={config[type].form}
            data={finalObject}
            setData={setFinalObject}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 mt-4 ml-1 bg-white w-full">
        <div className="flex gap-1 ml-auto">
          <Button2 variant="outlined" onClick={onClose} secondary noOutline>
            Cancel
          </Button2>
          <Button2 onClick={handleCreate} primary disabled={validationError}>
            {loading ? (
              <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
            ) : (
              'Create'
            )}
          </Button2>
        </div>
      </div>{' '}
      {ConfirmDialog}
    </Dialog>
  )
}

export default CreateObjectModal

const config = {
  people: {
    form: [
      {
        type: 'img-upload',
        label: 'Profile Picture',
        key: 'profile_picture'
      },
      {
        type: 'text',
        label: 'Name',
        key: 'name',
        mandatory: true
      },
      {
        type: 'dropdown',
        label: 'Employee Type',
        key: 'employee_type',
        mandatory: true,
        options: [
          { label: 'Internal', value: 'internal' },
          { label: 'External', value: 'external' },
          { label: 'Unknown', value: 'unknown' },
          { label: 'Inactive', value: 'inactive' }
        ],
        property: true
      }
    ]
  },

  client: {
    form: [
      {
        type: 'text',
        label: 'Name',
        mandatory: true,
        key: 'name'
      },
      {
        type: 'text',
        label: 'Domain',
        key: 'client_domain'
      },
      {
        type: 'dropdown',
        label: 'Company Type',
        key: 'company_type',
        mandatory: true,

        options: [
          { label: 'Client', value: 'client' },
          { label: 'Partner', value: 'partner' },
          { label: 'Competitor', value: 'competitor' }
        ],
        property: true
      }
      // {
      //   type: 'proposal-dropdown',
      //   label: 'Proposal',
      //   key: 'proposals'
      // }
    ]
  },
  project: {
    form: [
      {
        type: 'text',
        label: 'Name',
        mandatory: true,
        key: 'name'
      },
      {
        type: 'dropdown',
        label: 'Project Type',
        mandatory: true,
        key: 'project_type',
        options: [
          { label: 'Internal', value: 'internal' },
          { label: 'External', value: 'external' },
          { label: 'Unknown', value: 'unknown' }
        ],
        property: true
      },
      {
        type: 'text',
        label: 'Location',
        key: 'project_location'
      }
    ]
  }
}

const FormSchema = ({ form, data, setData }) => {
  const [profileImage, setProfileImage] = useState(null)
  const [profileImageURL, setProfileImageURL] = useState(null)
  const [profileImageFile, setProfileImageFile] = useState(null)
  const [profileImageError, setProfileImageError] = useState(null)
  const [proposalNameIDList, setProposalNameIDList] = useState([])
  const [proposalList, setProposalList] = useState([])
  const [uploadedImage, setUploadedImage] = useState(null)
  useEffect(() => {
    if (data?.profile_picture) {
      setProfileImageURL(data?.profile_picture)
    }

    form.map((field) => {
      if (field.type === 'proposal-dropdown') {
        getProposalNames()
      }
      return null
    })
  }, [data])

  const getProposalNames = async () => {
    if (proposalNameIDList.length > 0) {
      return
    }
    const proposalRes = await getAllProposalNames()
    if (proposalRes.status === 200) {
      const { proposal_names } = proposalRes.data
      const formattedProposals = proposal_names.map((proposal) => {
        return {
          label: proposal.name,
          value: proposal.id
        }
      })
      setProposalNameIDList(formattedProposals)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {form.map((field, index) => {
        switch (field.type) {
          case 'img-upload':
            return (
              <div
                className="flex justify-center"
                style={{
                  height: '75px'
                }}
              >
                {' '}
                <label
                  htmlFor="profileUpload"
                  className="relative cursor-pointer"
                >
                  <input
                    id="profileUpload"
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                      const file = e.target.files?.[0]
                      if (file) {
                        const reader = new FileReader()
                        reader.onloadend = () => {
                          const base64String = reader.result
                            ?.toString()
                            .split(',')[1]
                          if (base64String) {
                            const prefix = `data:${file.type};base64,`
                            const src = prefix + base64String

                            if (src) {
                              setUploadedImage({
                                src,
                                name: file.name
                              })

                              setData({
                                ...data,
                                profile_picture: {
                                  src,
                                  name: file.name
                                }
                              })
                            }
                          }
                        }
                        reader.readAsDataURL(file)
                      }
                    }}
                  />
                  {uploadedImage ? (
                    <img
                      src={uploadedImage.src}
                      style={{
                        width: '75px',
                        height: '75px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        border: '1px solid var(--grey-200)'
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '75px',
                        height: '75px',
                        // padding: '6px',
                        borderRadius: '10px',
                        border: '1px solid var(--grey-200)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'var(--grey-100)',
                        fontSize: '12px'
                      }}
                    >
                      Upload
                    </div>
                  )}
                  <span className="absolute bottom-1 right-1 bg-white rounded-full p-1 border-grey-200 grid place-content-center">
                    <PencilIcon className="size-3" />
                  </span>
                </label>
              </div>
            )
          case 'text':
            return (
              <div key={index} className="flex flex-col gap-1">
                <label htmlFor={field.key} className="text-xs">
                  {field?.label}{' '}
                  {field?.mandatory && <span className="text-red-500">*</span>}
                </label>
                <input
                  className="input-base border-grey-300 p-2 rounded-lg"
                  id={field?.key}
                  value={data?.[field?.key] ?? ''}
                  onChange={(e) =>
                    setData({
                      ...data,
                      [field.key]: e.target.value
                    })
                  }
                />
              </div>
            )
          case 'dropdown':
            return (
              <div key={index} className="flex flex-col gap-1">
                <label htmlFor={field.key} className="text-xs">
                  {field.label}{' '}
                  {field?.mandatory && <span className="text-red-500">*</span>}
                </label>
                <SelectDropDown
                  name={field?.key}
                  label=""
                  value={data?.properties?.[field.key]?.value ?? ''}
                  options={field.options}
                  handleChange={(e) =>
                    setData({
                      ...data,
                      properties: {
                        ...data?.properties,
                        [field.key]: {
                          value: e,
                          verified: true
                        }
                      }
                    })
                  }
                />
              </div>
            )

          case 'proposal-dropdown':
            return (
              <div className="">
                <span className="text-xs ml-1">{field.label}</span>
                <div className="mt-1">
                  <ReactSelect
                    options={proposalNameIDList}
                    placeholder="Select Proposals"
                    isMulti={true}
                    isClearable
                    value={proposalNameIDList.filter((item) =>
                      data?.proposal_id_list?.includes(item.value)
                    )}
                    onChange={(values) => {
                      setProposalList(values?.map((item) => item.value) || [])

                      setData({
                        ...data,
                        proposal_id_list:
                          values?.map((item) => item.value) || []
                      })
                    }}
                  />
                </div>
              </div>
            )
          default:
            return null
        }
      })}
    </div>
  )
}
