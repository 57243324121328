import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import { ImageAvatarFallback, SelectDropDown } from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useDispatch } from 'react-redux'
import { mergePeople } from '../../store/People/Actions'
import { toast } from 'react-toastify'
import { mergeProfiles } from '../../store/api'

const GenericMergeModal = ({
  isOpen,
  selectedData,
  onMerge,
  type = 'project' || 'client',
  onClose,
  s3Obj
}) => {
  const [loading, setLoading] = useState(false)
  const [finalRecord, setFinalRecord] = useState(null)
  const [selectedList, setSelectedList] = useState(selectedData)
  const [profileImages, setProfileImages] = useState([])
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('')
  const [groupIds, setGroupIds] = useState(
    selectedList.map((record) => record.id)
  )

  const dispatch = useDispatch()

  const [uploadedImages, setUploadedImages] = useState([])
  useEffect(() => {
    initMerge()
  }, [selectedData])

  const initMerge = async () => {
    const type = ''
    const name = selectedList[0]?.name ?? selectedList[0]?.title
    const key = type === 'project' ? 'project_type' : 'client_type'
    const finalPersonTemp = {
      name,
      properties: {
        [key]: {
          value: '',
          verified: false
        }
      }
    }

    const profileTemp = []
    const resumes = []

    setFinalRecord({
      ...finalPersonTemp
    })
  }

  const dropdownValues = {
    people: {
      label: 'Employee type',
      key: 'employee_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' },
        { label: 'Inactive', value: 'inactive' }
      ]
    },

    client: {
      label: 'Client type',
      key: 'client_type',
      options: [
        { label: 'Client', value: 'client' },
        { label: 'Partner', value: 'partner' },
        { label: 'Competitor', value: 'competitor' }
      ]
    },

    project: {
      label: 'Project type',
      key: 'project_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' }
      ]
    }
  }

  const handleMergePeople = async () => {
    if (finalRecord?.name.length < 1) {
      toast.error('Please enter a name')
      return
    }

    setLoading(true)

    const body = {
      group_ids: groupIds,
      group_name: finalRecord?.name,
      group_type: type,
      properties: finalRecord?.properties
    }

    console.log('mergePeople', body)

    // const id = toast.loading('Please wait...')
    const event = {
      people: mixpanelEvents.PEOPLE_RESUME_MERGED,
      client: mixpanelEvents.CLIENT_MERGED,
      project: mixpanelEvents.PROJECTS_MERGED
    }

    trackEvent(event[type], 'SUCCESS', {}, {})
    // setIsMerge('loading')

    const callback = () => {
      // setIsMerge(false)
      // setSelectedPeople([])
      // toast.dismiss(id)
      setLoading(false)

      onMerge()
    }

    const fallback = () => {
      // setIsMerge(true)
      // toast.dismiss(id)
      toast.error('Failed to merge people')
      setLoading(false)
    }

    mergeProfiles(body).then(callback).catch(fallback)
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          maxHeight: '450px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div
        className="flex flex-col justify-between h-full relative"
        style={{
          width: '700px',
          height: '300px',
          overflow: 'auto'
        }}
      >
        <div className="grid grid-col-2">
          <div>
            <p className="m-0 text-sm px-2 text-center font-medium">
              Selected{' '}
              {type === 'people'
                ? 'People'
                : type === 'client'
                ? 'Companies'
                : 'Projects'}
            </p>
            <div className="p-3">
              {selectedList.map((record, index) => {
                return (
                  <div className="flex items-center gap-2 mt-3" key={index}>
                    <div className="flex items-center gap-2">
                      <ImageAvatarFallback
                        profilePic={record?.profile_picture}
                        s3Obj={s3Obj}
                        type="project"
                        style={{
                          width: '35px',
                          height: '35px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                          border: '1px solid var(--grey-200)'
                        }}
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <p className="m-0 font-medium text-xs">
                          {record?.name ?? record?.title}
                        </p>
                      </div>
                    </div>
                    {selectedList.length > 2 && (
                      <Button2
                        secondary
                        // noOutline
                        onClick={() => {
                          const finalRecords = selectedList.filter(
                            (item) => item.id !== record.id
                          )
                          setSelectedList(finalRecords)

                          setGroupIds(
                            groupIds.filter((item) => item !== record.id)
                          )

                          // initMerge()
                        }}
                        style={{
                          marginLeft: 'auto',
                          padding: '2px 6px',
                          fontSize: '10px'
                        }}
                      >
                        Deselect
                      </Button2>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          {finalRecord && (
            <div
              style={{
                borderLeft: '1px solid var(--grey-200)'
              }}
            >
              <p className="m-0 text-sm px-2 text-center font-medium">Review</p>

              <div className="p-3">
                <div className="flex flex-col justify-center w-full">
                  <div className="w-full">
                    <span className="text-xs text-grey-600 ml-1">Name</span>
                    <input
                      className="input-base border-grey-200 p-1 rounded-md w-full text-sm mb-1"
                      value={finalRecord?.name}
                      onChange={(e) => {
                        setFinalRecord({
                          ...finalRecord,
                          name: e.target.value
                        })
                      }}
                    />
                    {/* <p className="m-0">{finalPerson?.name}</p> */}

                    {/* <p className="m-0 text-xs ml-2">
                      {finalPerson.resumes.length} Resumes
                    </p> */}
                  </div>
                  <div className="text-left mt-2">
                    <span className="text-xs text-grey-600 ml-1">
                      {dropdownValues[type].label}
                    </span>
                    <div className="mt-1">
                      <SelectDropDown
                        name="employeeType"
                        label=""
                        value={
                          finalRecord?.properties?.[dropdownValues[type].key]
                            ?.value
                        }
                        options={dropdownValues[type].options}
                        handleChange={(e) => {
                          setFinalRecord({
                            ...finalRecord,
                            properties: {
                              [dropdownValues[type].key]: {
                                value: e,
                                verfied: true
                              }
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 mt-3 ml-1 bg-white w-full">
        <div className="flex gap-1 ml-auto">
          <Button2 variant="outlined" onClick={onClose} secondary noOutline>
            Cancel
          </Button2>
          <Button2
            variant="outlined"
            onClick={handleMergePeople}
            primary
            // disabled={collectionName === ''}
          >
            {loading ? (
              <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
            ) : (
              'Merge'
            )}
          </Button2>
        </div>
      </div>{' '}
    </Dialog>
  )
}

export default GenericMergeModal
