import React, { useEffect, useState } from 'react'
import Button2 from '../../components/Button/Button2'
import {
  CheckIcon,
  ChevronDownIcon,
  CrossIcon,
  RedirectIcon,
  RightIcon,
  SemiCircleIcon,
  SparkIcon2,
  StarIcon
} from '../../components/Icons/Icons'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Menu,
  Tooltip,
  tooltipClasses
} from '@mui/material'
import { styled } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Bar, Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as Tooltipchart,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js'
import {
  getBrandMessageReport,
  getStatisticalReport,
  getStrategyReport
} from '../../store/api'
import { Auth } from 'aws-amplify'
import { initalizeAWSSigner } from '../../utils/AWS'
import { toast } from 'react-toastify'
import moment from 'moment'
import Lottie from 'react-lottie'
import Stats from '../../assets/lottie/stats.json'

ChartJS.register(
  ArcElement,
  Tooltipchart,
  CategoryScale,
  LinearScale,
  BarElement
)

const Insights = () => {
  const [currentView, setCurrentView] = useState('overview')
  const [statisticalReport, setStatisticalReport] = useState(null)
  const [generating, setGenerating] = useState(false)
  const [strategyReport, setStrategyReport] = useState(null)
  const [brandReport, setBrandReport] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [signerObj, setSignerObj] = useState(null)
  const [selectedDateFilter, setSelectedDateFilter] = useState(30)
  const open = Boolean(anchorEl)
  const datesFilter = [7, 14, 30, 60, 90, 180]
  const [dateRange, setDateRange] = useState(null)

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(endDate.getDate() - selectedDateFilter)

    const start = startDate.toISOString().split('T')[0]
    const end = endDate.toISOString().split('T')[0]

    console.log({ start_date: start, end_date: end })

    setDateRange({ start_date: start, end_date: end })
  }, [selectedDateFilter])

  useEffect(() => {
    if (dateRange) {
      getAllData()
    }
  }, [dateRange])

  const getAllData = async () => {
    // start date of the week

    setGenerating(true)
    try {
      const statisticalReportResponse = await getStatisticalReport(dateRange)

      setStatisticalReport(statisticalReportResponse?.data?.result)

      const strategyReportResponse = await getStrategyReport(dateRange)

      if (JSON.stringify(strategyReportResponse?.data?.result) === '{}') {
        setStrategyReport({
          error: true
        })
      } else {
        setStrategyReport(strategyReportResponse?.data?.result)
      }

      const brandReportResponse = await getBrandMessageReport(dateRange)

      setBrandReport(brandReportResponse?.data?.result)

      setGenerating(false)
    } catch (error) {
      console.log(error)
      setGenerating(false)
    }
  }

  const calculateRange = () => {}

  return (
    <div
      className="w-full"
      style={{
        height: 'calc(100% - 40px)'
      }}
    >
      <div className="flex items-center gap-2 mr-auto px-4 p-2 mt-4">
        <Button2
          secondary
          style={{
            padding: '4px 8px',
            fontSize: '10px',
            borderRadius: '6px',
            border:
              currentView === 'overview'
                ? '1px solid var(--grey-400) !important'
                : '1px solid var(--grey-200) !important',
            color:
              currentView === 'overview'
                ? 'var(--grey-700)'
                : 'var(--grey-600)',
            backgroundColor:
              currentView === 'overview' ? 'var(--grey-200)' : 'transparent'
          }}
          onClick={() => {
            setCurrentView('overview')
          }}
        >
          Overview
        </Button2>
        <Button2
          secondary
          style={{
            padding: '4px 8px',
            fontSize: '10px',
            borderRadius: '6px',
            border:
              currentView === 'strategy'
                ? '1px solid var(--grey-400) !important'
                : '1px solid var(--grey-200) !important',
            color:
              currentView === 'strategy'
                ? 'var(--grey-700)'
                : 'var(--grey-600)',
            backgroundColor:
              currentView === 'strategy' ? 'var(--grey-200)' : 'transparent'
          }}
          onClick={() => {
            setCurrentView('strategy')
          }}
        >
          Strategies
        </Button2>
        <Button2
          secondary
          style={{
            padding: '4px 8px',
            fontSize: '10px',
            borderRadius: '6px',
            color:
              currentView === 'brand' ? 'var(--grey-700)' : 'var(--grey-600)',
            border:
              currentView === 'brand'
                ? '1px solid var(--grey-400) !important'
                : '1px solid var(--grey-200) !important',
            backgroundColor:
              currentView === 'brand' ? 'var(--grey-200)' : 'transparent'
          }}
          onClick={() => {
            setCurrentView('brand')
          }}
        >
          Brand
        </Button2>
        <Button2
          secondary
          style={{
            padding: '4px 8px',
            fontSize: '10px',
            borderRadius: '6px',
            color:
              currentView === 'executive'
                ? 'var(--grey-700)'
                : 'var(--grey-600)',
            border:
              currentView === 'executive'
                ? '1px solid var(--grey-400) !important'
                : '1px solid var(--grey-200) !important',
            backgroundColor:
              currentView === 'executive' ? 'var(--grey-200)' : 'transparent'
          }}
          onClick={() => {
            setCurrentView('executive')
          }}
        >
          <StarIcon className="size-3" />
          Recommendations
        </Button2>

        {generating ? (
          <Button2
            secondary
            noOutline
            style={{
              marginLeft: 'auto',
              background: 'var(--orange-100)',
              color: 'var(--orange-600)',
              fontFamily: 'PoppinsMedium'
            }}
          >
            <SemiCircleIcon className="size-3 animate-spin" /> Generating
          </Button2>
        ) : (
          <Button2
            secondary
            style={{
              marginLeft: 'auto'
            }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
            }}
          >
            Last {selectedDateFilter} days{' '}
            <ChevronDownIcon className="size-3" />
          </Button2>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          TransitionComponent={Fade}
          className="p-2"
        >
          {datesFilter.map((days) => {
            return (
              <Button2
                secondary
                noOutline
                style={{
                  // padding: '4px 16px',
                  margin: '4px'
                  // width: '30px'
                }}
                key={days}
                onClick={() => {
                  setSelectedDateFilter(days)
                  setAnchorEl(null)
                }}
              >
                Last {days} days{' '}
                <CheckIcon
                  className={`size-3 ml-1
                      ${selectedDateFilter === days ? 'visible' : 'invisible'}
                    `}
                />
              </Button2>
            )
          })}
        </Menu>
      </div>

      {generating ? (
        <div className="grid place-content-center h-full">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Stats
            }}
            height={350}
          />
        </div>
      ) : (
        <>
          {currentView === 'overview' && statisticalReport && (
            <OverviewTab OverviewData={statisticalReport} key={dateRange} />
          )}
          {currentView === 'strategy' && strategyReport && (
            <StrategyTab strategyData={strategyReport} key={dateRange} />
          )}
          {currentView === 'brand' && brandReport && (
            <BrandTab data={brandReport} key={dateRange} />
          )}

          {currentView === 'executive' && signerObj && (
            <ExcecutiveTab
              signerObj={signerObj}
              dateRange={dateRange}
              key={dateRange}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Insights

const OverviewTab = ({ OverviewData }) => {
  const uploadSummary = OverviewData.uploads_summary
  const readabilityMetrics =
    OverviewData.analytics_statistics.readability_metrics
  const [readingStats, setReadingStats] = useState(null)
  const [grammerStats, setGrammerStats] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [v1, setV1] = useState(true)

  useEffect(() => {
    if (uploadSummary?.users_summary?.length > 0) {
      setSelectedUser(uploadSummary.users_summary[0])
    }

    const readingStatsTemp = OverviewData.analytics_statistics

    const issuesDetected = []
    const issuesResolved = []
    const labels = []
    Object.keys(readingStatsTemp.grammar_analysis).map((key) => {
      issuesDetected.push(
        readingStatsTemp.grammar_analysis[key].issues_detected
      )
      issuesResolved.push(
        readingStatsTemp.grammar_analysis[key].issues_resolved
      )
      labels.push(key)
      return null
    })

    console.log('data')

    setGrammerStats({
      issuesDetected,
      issuesResolved,
      labels
    })
  }, [])

  // if (!selectedUser) return null

  return (
    <div
      className="px-1 mt-3 bg-grey-50"
      style={{
        height: 'calc(100% - 41px)',
        overflowY: 'auto'
      }}
    >
      <div className=" gap-3 p-4">
        <div
          style={
            {
              // width: '115%'
            }
          }
        >
          <div className="grid grid-col-4 gap-2">
            <div
              className="rounded-xl p-3 bg-white"
              style={{
                border: '1px solid var(--grey-200)',
                padding: '14px'
              }}
            >
              <p className="m-0 text-xs font-medium text-grey-500">
                Analytics Uploaded
              </p>
              <p className="text-3xl m-0 font-medium">
                {uploadSummary.total_analytics_uploaded}
              </p>
              {/* <p className="text-xxs">
                <span className="bg-green-100 text-green-800 p-1 rounded-md">
                  +23%
                </span>{' '}
                <span className="text-grey-500 ml-1">since last month</span>
              </p> */}
            </div>

            <div
              className="rounded-xl p-3 bg-white"
              style={{
                border: '1px solid var(--grey-200)',
                padding: '14px'
              }}
            >
              <p className="m-0 text-xs font-medium text-grey-500">
                RFx Uploaded
              </p>
              <p className="text-3xl m-0">{uploadSummary.total_rfx_uploaded}</p>
              {/* <p className="text-xxs">
                <span className="bg-red-100 text-red-800 p-1 rounded-md">
                  -15%
                </span>{' '}
                <span className="text-grey-500 ml-1">since last month</span>
              </p> */}
            </div>
            <div
              className="rounded-xl p-3 bg-white"
              style={{
                border: '1px solid var(--grey-200)',
                padding: '14px'
              }}
            >
              <p className="m-0 text-xs font-medium text-grey-500">
                Compliance Checks
              </p>
              <p className="text-3xl m-0">
                {uploadSummary.total_compliance_checks}
              </p>
              {/* <p className="text-xxs">
                <span className="bg-green-100 text-green-800 p-1 rounded-md">
                  +10%
                </span>{' '}
                <span className="text-grey-500 ml-1">since last month</span>
              </p> */}
            </div>

            <div
              className="rounded-xl p-3 bg-white"
              style={{
                border: '1px solid var(--grey-200)',
                padding: '14px'
              }}
            >
              <p className="m-0 text-xs font-medium text-grey-500">
                Average Reading Time
              </p>
              <p className="text-3xl m-0 font-medium">
                {readabilityMetrics.average_reading_time.time}
                <span className="text-xxs ml-1 text-grey-600 mb-1">min</span>
              </p>
              <p className="text-xxs text-grey-500 ">
                Average pages -
                <span className="ml-1 text-grey-800">
                  {readabilityMetrics.average_reading_time.document_length}{' '}
                  pages
                </span>
              </p>
            </div>
          </div>

          <div className="mt-3 grid grid-col-2 gap-3">
            {/* <p className="m-0 px-1 text-grey-600 text-sm">User Summary</p> */}
            {
              v1 && selectedUser ? (
                <div
                  className="grid grid-col-2 gap-2 bg-white p-4 rounded-xl items-center"
                  style={{
                    border: '1px solid var(--grey-100)'
                  }}
                >
                  <div
                    className="mb-auto"
                    style={{ height: '100%', maxHeight: '380px' }}
                  >
                    <p className="m-0 text-xs ml-2 text-grey-500 font-medium">
                      User Summary
                    </p>
                    <div
                      className="flex flex-col overflow-auto"
                      style={{ height: '80%' }}
                    >
                      {uploadSummary.users_summary.map((user) => {
                        return (
                          <div
                            key={user.id}
                            className="p-1 mt-1 cursor-pointer transition-all contianerCard"
                            onClick={() => {
                              setSelectedUser(user)
                            }}
                          >
                            <div
                              className="text-sm rounded-md px-2 p-2"
                              style={{
                                background:
                                  selectedUser?.id === user.id
                                    ? 'var(--grey-50)'
                                    : 'transparent',
                                border:
                                  selectedUser?.id === user.id
                                    ? '1px solid var(--grey-200)'
                                    : '1px solid transparent'
                              }}
                            >
                              {user.name}
                              <p
                                className="flex items-center justify-between gap-2 m-0 text-xs"
                                style={{
                                  width: '80%'
                                }}
                              >
                                <span className="text-grey-500">
                                  {user.analytics_uploaded} Analytics
                                </span>
                                <div className="size-1 bg-grey-300 rounded-full"></div>
                                <span className="text-grey-500">
                                  {user.rfx_uploaded} Rfx
                                </span>
                                <div className="size-1 bg-grey-300 rounded-full"></div>
                                <span className="text-grey-500">
                                  {user.compliance_checks} Compliance
                                </span>
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className="grid place-content-center p-4 "
                    style={{
                      maxHeight: '230px',
                      width: '300px'
                    }}
                  >
                    <Doughnut
                      data={{
                        labels: [
                          'Analytics Uploaded',
                          'RFx Uploaded',
                          'Compliance Checks'
                        ],
                        datasets: [
                          {
                            data: [
                              selectedUser.analytics_uploaded,
                              selectedUser.rfx_uploaded,
                              selectedUser.compliance_checks
                            ],
                            backgroundColor: ['#3f3f46', '#9ca3af', '#cbd5e1'],
                            borderColor: 'transparent'
                          }
                        ]
                      }}
                      style={{
                        width: '60px',
                        height: '60px'
                      }}
                    />
                    <div className="flex items-center gap-4 justify-center mt-4">
                      <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
                        <div
                          className="size-2 rounded-md"
                          style={{
                            background: '#3f3f46'
                          }}
                        ></div>
                        Analytics
                      </p>
                      <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
                        <div
                          className="size-2 rounded-md"
                          style={{
                            background: '#9ca3af'
                          }}
                        ></div>
                        Rfx
                      </p>
                      <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
                        <div
                          className="size-2 rounded-md"
                          style={{
                            background: '#cbd5e1'
                          }}
                        ></div>
                        Compliance
                      </p>
                    </div>
                  </div>
                </div>
              ) : null
              // <div
              //   className="grid grid-col-1 gap-2 bg-white rounded-xl p-4"
              //   style={{
              //     border: '1px solid var(--grey-100)'
              //   }}
              // >
              //   <div className="flex items-center justify-between">
              //     <p className="m-0 text-xs ml-1 text-grey-500 font-medium">
              //       User Summary
              //     </p>

              //     <div className="flex items-center gap-4 justify-center">
              //       <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
              //         <div
              //           className="size-2 rounded-md"
              //           style={{
              //             background: '#3f3f46'
              //           }}
              //         ></div>
              //         Analytics
              //       </p>
              //       <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
              //         <div
              //           className="size-2 rounded-md"
              //           style={{
              //             background: '#9ca3af'
              //           }}
              //         ></div>
              //         Rfx
              //       </p>
              //       <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
              //         <div
              //           className="size-2 rounded-md"
              //           style={{
              //             background: '#cbd5e1'
              //           }}
              //         ></div>
              //         Compliance
              //       </p>
              //     </div>
              //   </div>

              //   <div
              //     className="grid place-content-center p-4 m-auto"
              //     style={{
              //       maxHeight: '230px',
              //       width: '300px'
              //     }}
              //   >
              //     <Doughnut
              //       data={{
              //         labels: [
              //           'Analytics Uploaded',
              //           'RFx Uploaded',
              //           'Compliance Checks'
              //         ],
              //         datasets: [
              //           {
              //             data: [
              //               selectedUser.analytics_uploaded,
              //               selectedUser.rfx_uploaded,
              //               selectedUser.compliance_checks
              //             ],
              //             backgroundColor: ['#3f3f46', '#9ca3af', '#cbd5e1'],
              //             borderColor: 'transparent'
              //           }
              //         ]
              //       }}
              //       style={{
              //         width: '60px',
              //         height: '60px'
              //       }}
              //     />
              //   </div>
              //   <div>
              //     {uploadSummary.users_summary.map((user) => {
              //       return (
              //         <div
              //           key={user.id}
              //           className="p-1 mt-1 cursor-pointer transition-all contianerCard"
              //           onClick={() => {
              //             setSelectedUser(user)
              //           }}
              //         >
              //           <div
              //             className=" rounded-md px-2 p-2"
              //             style={{
              //               background:
              //                 selectedUser?.id === user.id
              //                   ? 'var(--grey-50)'
              //                   : 'transparent',
              //               border:
              //                 selectedUser?.id === user.id
              //                   ? '1px solid var(--grey-200)'
              //                   : '1px solid transparent'
              //             }}
              //           >
              //             <p
              //               className="flex items-center justify-between gap-2 m-0 text-xs"
              //               style={{
              //                 width: '80%'
              //               }}
              //             >
              //               <span className="text-sm w-28">{user.name}</span>
              //               <div className="size-1 bg-grey-300 rounded-full"></div>
              //               <span className="text-grey-500">
              //                 {user.analytics_uploaded} Analytics
              //               </span>
              //               <div className="size-1 bg-grey-300 rounded-full"></div>
              //               <span className="text-grey-500">
              //                 {user.rfx_uploaded} Rfx
              //               </span>
              //               <div className="size-1 bg-grey-300 rounded-full"></div>
              //               <span className="text-grey-500">
              //                 {user.compliance_checks} Compliance
              //               </span>
              //             </p>
              //           </div>
              //         </div>
              //       )
              //     })}
              //   </div>
              // </div>
            }
            {grammerStats && (
              <div
                className="p-4 bg-white rounded-xl border-box"
                style={{
                  border: '1px solid var(--grey-100)'
                }}
              >
                <div className="flex items-center justify-between mb-2">
                  <p className="m-0 text-xs font-medium text-grey-500">
                    Grammer Analysis
                  </p>

                  <div className="flex items-center gap-4 justify-center">
                    <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
                      <div
                        className="size-2 rounded-md"
                        style={{
                          background: '#3f3f46'
                        }}
                      ></div>
                      Detected
                    </p>
                    <p className="m-0 text-xxs text-grey-500 flex items-center gap-1">
                      <div
                        className="size-2 rounded-md"
                        style={{
                          background: '#9ca3af'
                        }}
                      ></div>
                      Resolved
                    </p>
                  </div>
                </div>

                <div className="">
                  <Bar
                    data={{
                      labels: [...grammerStats?.labels],
                      datasets: [
                        {
                          label: 'Detected',
                          data: grammerStats
                            ? grammerStats?.issuesDetected
                            : [],
                          backgroundColor: ['#3f3f46']
                        },
                        {
                          label: 'Resolved',
                          data: grammerStats
                            ? grammerStats?.issuesResolved
                            : [],
                          backgroundColor: ['#9ca3af']
                        }
                      ]
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const StrategyTab = ({ strategyData }) => {
  const [sortedData, setSortedData] = useState([])
  const [selectedStrategy, setSelectedStrategy] = useState(null)
  const [currentView, setCurrentView] = useState('strategy')

  console.log('strategyData', strategyData)
  useEffect(() => {
    if (strategyData.error) return
    sortDataByCount(strategyData)
  }, [])
  const sortDataByCount = (data) => {
    const sortedData = Object.keys(data).sort(
      (a, b) => data[b].count - data[a].count
    )

    setSortedData(sortedData)
    setSelectedStrategy({
      ...strategyData[sortedData[0]],
      name: sortedData[0]
    })
    // console.log(sortedData)
  }

  if (strategyData.error) {
    return (
      <div
        className="grid place-content-center"
        style={{
          height: 'calc(100% - 35px)'
        }}
      >
        <p>Insufficient Data</p>
      </div>
    )
  }

  return (
    <div
      className="grid grid-col-2 px-4"
      style={{
        height: 'calc(100% - 35px)'
      }}
    >
      <div
        className="p-3"
        style={{
          height: 'calc(100% - 20px)',
          overflowY: 'auto'
        }}
      >
        <div className="flex flex-col gap-1">
          {sortedData.map((key) => {
            if (!strategyData[key]) return null
            return (
              <div
                key={key}
                onClick={() => {
                  setSelectedStrategy({
                    ...strategyData[key],
                    name: key
                  })
                }}
                className={`p-2 px-4 transition-all cursor-pointer ${
                  selectedStrategy.name === key
                    ? 'bg-grey-100'
                    : 'containerCard'
                }`}
                style={{
                  borderRadius: '0 10px 10px 0',
                  borderLeft:
                    selectedStrategy.name === key
                      ? '4px solid var(--grey-700)'
                      : '4px solid white'
                }}
              >
                <p className="font-medium m-0 text-xs mb-1 text-grey-600">
                  {key}
                </p>
                <div className="bg-grey-50 rounded-lg">
                  <p
                    className="text-xxs m-0 text-white px-3 rounded-lg text-right border-box font-medium"
                    style={{
                      padding: '2px 10px',
                      background: 'var(--grey-700)',
                      width:
                        (strategyData[key].count /
                          Math.max(strategyData[sortedData[0]].count, 10)) *
                          100 +
                        '%'
                    }}
                  >
                    {strategyData[key].count}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {selectedStrategy && (
        <div
          className="p-3"
          style={{
            height: 'calc(100% - 20px)',
            overflowY: 'auto'
          }}
        >
          <p className="m-0 text-lg font-medium px-4">
            {selectedStrategy.name}
          </p>

          <div className="flex flex-col gap-2 mt-2">
            {Object.keys(selectedStrategy.references).map((key, i) => {
              return (
                <>
                  <div
                    className="p-4 containerCard rounded-lg parent-card cursor-pointer"
                    key={key}
                  >
                    <div className="flex flex-col gap-2">
                      {selectedStrategy.references[key].map((ref) => {
                        return (
                          <div className="rounded-lg" key={ref.source}>
                            <p className="m-0 text-sm font-medium flex items-center">
                              {ref.document_name}
                              <div
                                className="card-options text-xs ml-auto bg-grey-800 p-1 rounded-full text-white px-3"
                                onClick={() => {
                                  window.open(
                                    `/analytic/pdf/${ref.document_id}/analytics`,
                                    '_blank'
                                  )
                                }}
                              >
                                More <RedirectIcon className="size-3" />
                              </div>
                              {/* <span className="text-xxs text-grey-500 ml-2">{key}</span> */}
                            </p>
                            {ref.evidence && (
                              <p className="m-0 text-xs mt-2 text-grey-600">
                                {ref.evidence}
                                <span className="italic text-xs">
                                  - Evidence
                                </span>
                              </p>
                            )}
                            {ref.explanation && (
                              <div className="mt-2">
                                <p className="m-0 text-xs text-grey-600">
                                  Explanation
                                </p>
                                <p className="m-0 text-sm text-grey-600">
                                  {ref.explanation}
                                </p>
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className="bg-grey-100"
                    style={{
                      height:
                        i ===
                        Object.keys(selectedStrategy.references).length - 1
                          ? '0px'
                          : '1.5px'
                    }}
                  ></div>
                </>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const BrandTab = ({ data }) => {
  const [sortedData, setSortedData] = useState([])
  const [selectedBrandTopic, setSelectedBrandTopic] = useState(null)
  const [selectedEvidence, setSelectedEvidence] = useState(null)
  const [openEvidenceDialog, setOpenEvidenceDialog] = useState(false)
  const [brandData, setBrandData] = useState(brandDataJSON)
  const [selectedRefrence, setSelectedRefrence] = useState(null)
  const [emptyData, setEmptyData] = useState(false)
  const [coreBrand, setCoreBrand] = useState(null)
  const [otherBrand, setOtherBrand] = useState(null)
  const [maxValue, setMaxValue] = useState(10)
  const [proposalData, setProposalData] = useState(data?.proposals)
  useEffect(() => {
    const other = brandData?.other

    const tempOther = {}

    if (JSON.stringify(data?.summary?.core) !== '{}') {
      setCoreBrand(data?.summary?.core)
    }

    if (JSON.stringify(data?.summary?.others) !== '{}') {
      setOtherBrand(data?.summary?.others)
    }

    Object.keys(other.topics).map((key) => {
      tempOther[key] = {
        ...other.topics[key],
        type: 'other'
      }

      return null
    })

    setBrandData({
      ...brandData,
      ...tempOther
    })

    const tempData = { ...brandData }

    delete tempData.other

    sortDataByCount()
  }, [])

  useEffect(() => {
    if (coreBrand) {
      if (coreBrand?.[sortedData?.[0]]) {
        handleBrandTopic(coreBrand[sortedData[0]], sortedData[0])
        return
      }
    }
    if (otherBrand) {
      if (otherBrand?.[sortedData?.[0]]) {
        handleBrandTopic(otherBrand[sortedData[0]], sortedData[0])
      }
    }
  }, [coreBrand, otherBrand])

  const sortDataByCount = () => {
    let sortedData = Object.keys(data?.summary?.core).sort(
      (a, b) => data?.summary?.core[b].count - data?.summary?.core[a].count
    )

    const sortedData2 = Object.keys(data?.summary?.others).sort(
      (a, b) => data?.summary?.others[b].count - data?.summary?.others[a].count
    )

    console.log('sortedData brand', sortedData, sortedData2)

    sortedData = [...sortedData, ...sortedData2]

    let tempMax = 10

    Object.keys(data?.summary?.core).map((key) => {
      if (data?.summary?.core[key].count > tempMax) {
        tempMax = data?.summary?.core[key].count
      }

      return null
    })

    Object.keys(data?.summary?.others).map((key) => {
      if (data?.summary?.others[key].count > tempMax) {
        tempMax = data?.summary?.others[key].count
      }

      return null
    })

    setMaxValue(tempMax)

    setSortedData(sortedData)
  }

  const handleBrandTopic = (KeyData, key) => {
    console.log('KeyData', KeyData)
    const brandTopic = {
      ...KeyData,
      name: key,
      references: KeyData.ids.map((id) => {
        return {
          ...proposalData[id],
          id
        }
      })
    }
    setSelectedBrandTopic(brandTopic)

    console.log('brandTopic', brandTopic)
    setSelectedRefrence(brandTopic.references[0])
  }

  // if (Object.keys(data?.proposals)?.length === 0 || !data?.proposals) {
  //   return (
  //     <div
  //       className="grid place-content-center"
  //       style={{
  //         height: 'calc(100% - 35px)'
  //       }}
  //     >
  //       Insufficient Data
  //     </div>
  //   )
  // }

  return (
    <div
      className="grid grid-col-2 px-4"
      style={{
        height: 'calc(100% - 35px)'
      }}
    >
      <div
        className="p-3"
        style={{
          height: 'calc(100% - 20px)',
          overflowY: 'auto'
        }}
      >
        {coreBrand && (
          <div
            className="flex flex-col gap-1 p-3 rounded-lg"
            style={{
              border: '1px solid var(--grey-200)'
            }}
          >
            <p className="m-0 mb-1 text-xs px-4 font-medium text-center text-grey-600">
              Core Values
            </p>
            {sortedData.map((key) => {
              if (!coreBrand[key]) return null

              return (
                <div
                  key={key}
                  onClick={() => {
                    handleBrandTopic(coreBrand[key], key)
                  }}
                  className={`p-2 px-3 transition-all cursor-pointer ${
                    selectedBrandTopic?.name === coreBrand[key]?.topic_label
                      ? 'bg-grey-100'
                      : 'containerCard'
                  }`}
                  style={{
                    borderRadius: '0 10px 10px 0',
                    borderLeft:
                      selectedBrandTopic?.name === key
                        ? '4px solid var(--grey-700)'
                        : '4px solid white'
                  }}
                >
                  <p className="font-medium m-0 text-xxs mb-1 text-grey-600">
                    {coreBrand[key].topic_label}{' '}
                  </p>
                  <div className="bg-grey-50 rounded-lg">
                    <p
                      className="text-xxs m-0 text-white px-3 rounded-lg text-right border-box font-medium"
                      style={{
                        padding: '2px 10px',
                        background: 'var(--grey-700)',
                        width:
                          (coreBrand[key].count / Math.max(maxValue)) * 100 +
                          '%'
                      }}
                    >
                      {coreBrand[key].count}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {otherBrand && (
          <div
            className="flex flex-col gap-1 p-3 rounded-lg mt-3"
            style={{
              border: '1px solid var(--grey-200)'
            }}
          >
            <p className="m-0 mb-1 text-xs px-4 font-medium text-center text-grey-600">
              Additional Values
            </p>
            {sortedData.map((key) => {
              if (!otherBrand[key]) return null
              return (
                <div
                  key={key}
                  onClick={() => {
                    handleBrandTopic(otherBrand[key], key)
                  }}
                  className={`p-2 px-3 transition-all cursor-pointer ${
                    selectedBrandTopic?.name === otherBrand[key]?.topic_label
                      ? 'bg-grey-100'
                      : 'containerCard'
                  }`}
                  style={{
                    borderRadius: '0 10px 10px 0',
                    borderLeft:
                      selectedBrandTopic?.name === key
                        ? '4px solid var(--grey-700)'
                        : '4px solid white'
                  }}
                >
                  <p className="font-medium m-0 text-xxs mb-1 text-grey-600">
                    {otherBrand[key].topic_label}{' '}
                  </p>
                  <div className="bg-grey-50 rounded-lg">
                    <p
                      className="text-xxs m-0 text-white px-3 rounded-lg text-right border-box font-medium"
                      style={{
                        padding: '2px 10px',
                        background: 'var(--grey-700)',
                        width:
                          (otherBrand[key].count / Math.max(maxValue)) * 100 +
                          '%'
                      }}
                    >
                      {otherBrand[key].count}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      {selectedBrandTopic && (
        <div
          className="p-3"
          style={{
            height: 'calc(100% - 20px)',
            overflowY: 'auto'
          }}
        >
          <p className="m-0 text-lg font-medium">{selectedBrandTopic.name}</p>

          <div className="flex flex-col gap-2 mt-2">
            {selectedBrandTopic?.references?.map((doc, i) => {
              return (
                <>
                  <div
                    className="p-4 rounded-lg parent-card cursor-pointer"
                    key={i}
                    style={{
                      border: '1px solid var(--grey-200)'
                      // boxShadow: 'rgb(116 116 116 / 10%) 0px 0px 15px 4px'
                    }}
                    onClick={() => {
                      setSelectedRefrence(doc)
                    }}
                  >
                    <div className="flex items-center justify-between">
                      {selectedRefrence?.id === doc?.id ? (
                        <p
                          className="m-0 text-xs font-medium flex items-center"
                          onClick={() => {
                            window.open(
                              `/analytic/pdf/${doc?.id}/analytics`,
                              '_blank'
                            )
                          }}
                        >
                          {doc?.document_name}{' '}
                          <RedirectIcon
                            className={`text-grey-600 size-3 ml-2 transition-all`}
                          />
                        </p>
                      ) : (
                        <p className="m-0 text-xs font-medium flex items-center">
                          {doc?.document_name}{' '}
                          <ChevronDownIcon
                            className={`text-grey-600 size-4 ml-2 transition-all ${
                              selectedRefrence?.id === doc?.id
                                ? 'rotate-180'
                                : ''
                            }`}
                          />
                        </p>
                      )}

                      {doc?.metrics?.coverage_ratio && (
                        <div
                          className="text-xxs p-1 rounded-full text-white px-3 text-center"
                          style={{
                            background: colorWithScore(
                              (doc?.metrics?.coverage_ratio.covered /
                                doc?.metrics?.coverage_ratio.total) *
                                100
                            )
                          }}
                        >
                          <span className="text-xs font-medium">
                            {(doc.metrics.coverage_ratio.covered /
                              doc.metrics.coverage_ratio.total) *
                              100}
                            %
                          </span>
                        </div>
                      )}
                    </div>

                    {selectedRefrence?.id === doc?.id && (
                      <div className="mt-2">
                        <div>
                          {selectedRefrence?.topic_areas?.core_topics.map(
                            (topic) => {
                              if (selectedBrandTopic.name === topic.topic) {
                                return (
                                  <div
                                    className="w-84 p-3 gap-3"
                                    key={topic.topic}
                                  >
                                    <CircularProgressWithLabel
                                      value={(topic.score / 5) * 100}
                                    />

                                    <p className="m-0 text-xs">
                                      {topic.evidence}{' '}
                                      <span className="italic text-grey-500">
                                        {' '}
                                        - Evidence
                                      </span>
                                    </p>

                                    <p className="m-0 text-xs mt-3 ">
                                      {topic.explanation}
                                      <span className="italic text-grey-500">
                                        {' '}
                                        - Explanation
                                      </span>
                                    </p>
                                  </div>
                                )
                              }

                              return null
                            }
                          )}
                          {selectedRefrence?.topic_areas?.other_topics.map(
                            (topic) => {
                              if (selectedBrandTopic.name === topic.topic) {
                                return (
                                  <div
                                    className="w-84 p-3 gap-3"
                                    key={topic.topic}
                                  >
                                    <CircularProgressWithLabel
                                      value={(topic.score / 5) * 100}
                                    />

                                    <p className="m-0 text-xs text-grey-600 mt-3">
                                      Evidences
                                    </p>
                                    <div className="mt-2">
                                      {topic?.evidences.map((evidence) => {
                                        return (
                                          <div
                                            key={evidence}
                                            className="flex flex-col gap-1 mt-4"
                                          >
                                            {evidence?.quote && (
                                              <p className="m-0 text-xs">
                                                "{evidence.quote}"
                                                <span className="italic ml-1">
                                                  {' '}
                                                  - Page {evidence.page}
                                                </span>
                                              </p>
                                            )}

                                            {evidence?.section && (
                                              <p className="m-0 text-xs">
                                                {evidence.section}{' '}
                                                <span className="italic ml-1">
                                                  {' '}
                                                  - Section
                                                </span>
                                              </p>
                                            )}

                                            <p className="m-0 text-xs"></p>
                                          </div>
                                        )
                                      })}
                                      {topic?.evidences.length > 2 && (
                                        <Button2
                                          secondary
                                          // noOutline
                                          style={{
                                            marginTop: '10px'
                                          }}
                                          onClick={() => {
                                            setSelectedEvidence(topic.evidences)
                                            setOpenEvidenceDialog(true)
                                          }}
                                        >
                                          Show More Evidences
                                        </Button2>
                                      )}
                                    </div>

                                    <p className="m-0 text-xs text-grey-600 mt-3">
                                      Explanation
                                    </p>
                                    <p className="m-0 text-xs">
                                      {topic.explanation}
                                    </p>
                                  </div>
                                )
                              }

                              return null
                            }
                          )}
                        </div>

                        <MoreEvidenceDialog
                          open={openEvidenceDialog}
                          setOpen={setOpenEvidenceDialog}
                          evidences={selectedEvidence}
                        />
                        {selectedRefrence?.topic_areas?.core_topics.length >
                          0 && (
                          <div>
                            <div className="bg-grey-100 p-1 rounded-md mt-3 mb-3">
                              <p className="text-xxs text-grey-500 m-0 px-4 text-center">
                                Core Values
                              </p>
                            </div>
                            <div className="flex gap-3 px-3">
                              {selectedRefrence?.topic_areas?.core_topics?.map(
                                (topic) => {
                                  if (selectedBrandTopic.name === topic.topic) {
                                    return null
                                  }
                                  return (
                                    <HtmlTooltip
                                      key={topic.topic}
                                      placement="bottom"
                                      title={
                                        <div className="w-84 p-3">
                                          <p className="m-0 text-xs text-grey-400">
                                            Evidence
                                          </p>
                                          <p className="m-0 text-xs">
                                            {topic.evidence}
                                          </p>

                                          <p className="m-0 text-xs text-grey-400 mt-3">
                                            Explanation
                                          </p>
                                          <p className="m-0 text-xs">
                                            {topic.explanation}
                                          </p>
                                        </div>
                                      }
                                    >
                                      <div className="text-xs text-center w-28">
                                        <CircularProgressWithLabel
                                          value={(topic.score / 5) * 100}
                                        />
                                        <p className="m-0 mb-1">
                                          {topic.topic_label}
                                        </p>
                                      </div>
                                    </HtmlTooltip>
                                  )
                                }
                              )}

                              {Object.keys(coreBrand).map((topic) => {
                                if (
                                  !selectedRefrence?.topic_areas?.core_topics
                                    .map((topic) => topic.topic)
                                    .includes(topic)
                                ) {
                                  return (
                                    <HtmlTooltip
                                      key={topic.topic}
                                      placement="bottom"
                                      title={
                                        <div className="w-84 p-3">N/A</div>
                                      }
                                    >
                                      <div className="text-xs text-center w-28">
                                        <CircularProgressWithLabel
                                          value={'NA'}
                                        />
                                        <p className="m-0 mb-1">
                                          {coreBrand[topic].topic_label}
                                        </p>
                                      </div>
                                    </HtmlTooltip>
                                  )
                                }

                                return null
                              })}
                            </div>
                          </div>
                        )}
                        {selectedRefrence?.topic_areas?.other_topics.length >
                          0 && (
                          <>
                            <div className="bg-grey-100 p-1 rounded-md mt-3 mb-3">
                              <p className="text-xxs text-grey-500 m-0 px-4 text-center">
                                Additional Values
                              </p>
                            </div>
                            <div className="flex gap-3 px-3">
                              {selectedRefrence.topic_areas.other_topics.map(
                                (topic) => {
                                  if (selectedBrandTopic.name === topic.topic) {
                                    return null
                                  }
                                  return (
                                    <HtmlTooltip
                                      key={topic.topic}
                                      placement="bottom"
                                      title={
                                        <div className="w-84 p-3">
                                          <p className="m-0 text-xs text-grey-400">
                                            Explanation
                                          </p>
                                          <p className="m-0 text-xs">
                                            {topic.explanation}
                                          </p>
                                        </div>
                                      }
                                    >
                                      <div className="text-xs text-center w-28 mt-2">
                                        <CircularProgressWithLabel
                                          value={(topic.score / 5) * 100}
                                        />
                                        <p className="m-0 mb-1">
                                          {topic.topic_label}
                                        </p>
                                        {/* <div
                                      style={{
                                        width: `${(topic.score / 5) * 75}%`,
                                        height: '5px',
                                        borderRadius: '5px',
                                        background: colorWithScore(topic.score)
                                      }}
                                    ></div> */}
                                      </div>
                                    </HtmlTooltip>
                                  )
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const ExcecutiveTab = ({ signerObj, dateRange }) => {
  const [recommendations, setRecommendations] = useState(null)
  const [serverDateRange, setServerDateRange] = useState(null)
  const colors = ['#8338ec', '#3a86ff', '#fb5607', '#ff006e']
  const [generating, setGenerating] = useState(false)
  useEffect(() => {
    generateRecommendations(dateRange?.start_date, dateRange?.end_date)
  }, [])

  const generateRecommendations = async (start_date, end_date) => {
    setGenerating(true)

    const req = {
      requestType: 'extractRecommendation',
      start_date,
      end_date
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(req),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    let completeText = ''
    try {
      if (response.status === 200 && response.body) {
        // trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'SUCCESS', {}, {})
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }

          const text = new TextDecoder().decode(value)
          console.log(text)
          completeText = completeText + text
        }
        completeText = completeText.split(/__END_OF_CHAT__/g)
        if (JSON.parse(completeText[0])) {
          console.log()
          const data = JSON.parse(completeText[0])
          setRecommendations(data?.executive_recommendations)
          setServerDateRange(data?.generated_for)
          setGenerating(false)
        }

        // handleAnalysisReportChange(key, completeText)
      } else {
        // trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'FAILED', {}, {})
        console.log('Something went wrong. Extraction Failed.')
      }
    } catch (error) {
      toast.error('Something went wrong. Extraction Failed.')
      setGenerating(false)
      console.log(error)
    }
  }

  if (generating) {
    return (
      <div
        className="grid px-4 place-content-center"
        style={{
          height: 'calc(100% - 35px)'
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Stats
          }}
          height={350}
        />
      </div>
    )
  }

  return (
    <div
      className="grid px-4"
      style={{
        height: 'calc(100% - 35px)'
      }}
    >
      <div
        className="p-3"
        style={{
          height: 'calc(100% - 20px)',
          overflowY: 'auto'
        }}
      >
        <div className="flex items-center justify-between">
          <p className="m-0 mt-4 px-2 font-medium text-lg mb-4">
            Executive Recommendations
          </p>
          <p className="m-0 text-xxs text-grey-500">
            Generated for{' '}
            {moment.utc(serverDateRange?.start_date).local().format('ll')} -{' '}
            {moment.utc(serverDateRange?.end_date).local().format('ll')}
          </p>
        </div>

        {recommendations && (
          <div
            className="grid grid-col-2 gap-3 mt-2"
            style={{
              height: 'calc(100% - 70px)'
            }}
          >
            {recommendations.map((doc, i) => {
              return (
                <div
                  key={doc}
                  className="p-3 px-4 rounded-xl text-white"
                  style={{
                    background: colors[i],
                    padding: '25px'
                  }}
                >
                  <div className="flex items-center text-2xl font-medium">
                    {/* <div className="rounded-full grid place-content-center text-3xl w-16 h-28">
                    {i + 1}
                  </div> */}
                    {doc.recommendation_type}
                  </div>

                  {doc.recommendations.map((x) => {
                    return (
                      <div className="text-sm mt-3 flex gap-2" key={x}>
                        <div
                          style={{
                            minWidth: '15px'
                          }}
                        >
                          <SparkIcon2 className="size-4 mr-2 mt-1" />
                        </div>
                        {x}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const brandDataJSON = {
  'Innovative Problem-Solving': {
    count: 12,
    ids: [
      '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
      'bb4398e1-60d9-47ef-855b-06340cf6c912',
      '5e68be11-452e-4bcc-93de-473a10487b37',
      '3f61a7b5-a7b5-4cd5-aca1-d10bd94f371e',
      'e725043b-a214-4438-84b2-d742d1d8c2c5',
      '45b265af-fc1c-463f-a5cb-e828eea29a95',
      '85a80bb4-2773-4005-9789-a250bb9ccf90',
      '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
      '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f',
      '34b413d8-714a-491f-863f-c0dfc68d1017',
      'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
      '090810aa-b97b-4173-8f6e-db0785676208'
    ],
    topic_label: 'Innovation'
  },
  'Sustainability Commitment': {
    count: 11,
    ids: [
      '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
      'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
      'bb4398e1-60d9-47ef-855b-06340cf6c912',
      '5e68be11-452e-4bcc-93de-473a10487b37',
      '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
      'e725043b-a214-4438-84b2-d742d1d8c2c5',
      '85a80bb4-2773-4005-9789-a250bb9ccf90',
      '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
      '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f',
      'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
      '090810aa-b97b-4173-8f6e-db0785676208'
    ],
    topic_label: 'Sustainability'
  },
  'Client-Centric Excellence': {
    count: 13,
    ids: [
      '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
      '5e68be11-452e-4bcc-93de-473a10487b37',
      '3f61a7b5-a7b5-4cd5-aca1-d10bd94f371e',
      '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
      'ad84c71f-1e61-44c0-a500-f8533ff3624f',
      '185f40af-e999-4fb1-a96e-20e401c67ed7',
      'e725043b-a214-4438-84b2-d742d1d8c2c5',
      '45b265af-fc1c-463f-a5cb-e828eea29a95',
      '85a80bb4-2773-4005-9789-a250bb9ccf90',
      '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
      '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f',
      'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
      '090810aa-b97b-4173-8f6e-db0785676208'
    ],
    topic_label: 'Client Focus'
  },
  other: {
    count: 48,
    ids: [
      '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
      'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
      'bb4398e1-60d9-47ef-855b-06340cf6c912',
      '5e68be11-452e-4bcc-93de-473a10487b37',
      '3f61a7b5-a7b5-4cd5-aca1-d10bd94f371e',
      '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
      'ad84c71f-1e61-44c0-a500-f8533ff3624f',
      '185f40af-e999-4fb1-a96e-20e401c67ed7',
      'e725043b-a214-4438-84b2-d742d1d8c2c5',
      '45b265af-fc1c-463f-a5cb-e828eea29a95',
      '85a80bb4-2773-4005-9789-a250bb9ccf90',
      '1c9552b1-5dff-46ad-b9ef-629554e9cf89',
      '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
      '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f',
      '34b413d8-714a-491f-863f-c0dfc68d1017',
      'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
      '090810aa-b97b-4173-8f6e-db0785676208'
    ],
    topics: {
      'Collaborative Partnerships': {
        count: 12,
        ids: [
          '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
          'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
          'bb4398e1-60d9-47ef-855b-06340cf6c912',
          '5e68be11-452e-4bcc-93de-473a10487b37',
          '3f61a7b5-a7b5-4cd5-aca1-d10bd94f371e',
          '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
          'e725043b-a214-4438-84b2-d742d1d8c2c5',
          '85a80bb4-2773-4005-9789-a250bb9ccf90',
          '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
          '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f',
          'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
          '090810aa-b97b-4173-8f6e-db0785676208'
        ],
        topic_label: 'Collaboration'
      },
      'Trusted Integrity': {
        count: 5,
        ids: [
          '14192532-f71f-4649-a6cf-9ceb08b5a0b1',
          'e725043b-a214-4438-84b2-d742d1d8c2c5',
          '45b265af-fc1c-463f-a5cb-e828eea29a95',
          '85a80bb4-2773-4005-9789-a250bb9ccf90',
          '7a1baa4d-e081-4b56-8a36-5c75eb00ece0'
        ],
        topic_label: 'Trusted Integrity'
      },
      'Comprehensive Project Management': {
        count: 7,
        ids: [
          'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
          '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
          '85a80bb4-2773-4005-9789-a250bb9ccf90',
          '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
          '34b413d8-714a-491f-863f-c0dfc68d1017',
          'bd7c484a-cdba-499b-9fd7-d64f67a4fd62',
          '090810aa-b97b-4173-8f6e-db0785676208'
        ],
        topic_label: 'Project Management'
      },
      'Environmental Leadership': {
        count: 4,
        ids: [
          'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
          'e725043b-a214-4438-84b2-d742d1d8c2c5',
          '45b265af-fc1c-463f-a5cb-e828eea29a95',
          'bd7c484a-cdba-499b-9fd7-d64f67a4fd62'
        ],
        topic_label: 'Environmental Leadership'
      },
      'Integrated Expertise': {
        count: 2,
        ids: [
          '5e68be11-452e-4bcc-93de-473a10487b37',
          '1c9552b1-5dff-46ad-b9ef-629554e9cf89'
        ],
        topic_label: 'Unified Expertise'
      },
      'Enhanced Safety Standards': {
        count: 4,
        ids: [
          '5e68be11-452e-4bcc-93de-473a10487b37',
          'ad84c71f-1e61-44c0-a500-f8533ff3624f',
          '185f40af-e999-4fb1-a96e-20e401c67ed7',
          'bd7c484a-cdba-499b-9fd7-d64f67a4fd62'
        ],
        topic_label: 'Safety Standards'
      },
      'Proactive Risk Management': {
        count: 3,
        ids: [
          '3f61a7b5-a7b5-4cd5-aca1-d10bd94f371e',
          '1c9552b1-5dff-46ad-b9ef-629554e9cf89',
          '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f'
        ],
        topic_label: 'Risk Management'
      },
      'Tailored & Resilient Solutions': {
        count: 3,
        ids: [
          'e725043b-a214-4438-84b2-d742d1d8c2c5',
          '45b265af-fc1c-463f-a5cb-e828eea29a95',
          '090810aa-b97b-4173-8f6e-db0785676208'
        ],
        topic_label: 'Tailored Solutions'
      },
      'Long-Term Value Creation': {
        count: 2,
        ids: [
          'e725043b-a214-4438-84b2-d742d1d8c2c5',
          '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f'
        ],
        topic_label: 'Long-Term Value'
      },
      'Reliable Execution': {
        count: 3,
        ids: [
          '85a80bb4-2773-4005-9789-a250bb9ccf90',
          '1c9552b1-5dff-46ad-b9ef-629554e9cf89',
          '0b80dc2d-1fee-42d1-aa07-c0c9649ca91f'
        ],
        topic_label: 'Reliability'
      },
      'Holistic Planning Approach': {
        count: 1,
        ids: ['1c9552b1-5dff-46ad-b9ef-629554e9cf89'],
        topic_label: 'Holistic Planning'
      },
      'Value-Driven Solutions': {
        count: 1,
        ids: ['7a1baa4d-e081-4b56-8a36-5c75eb00ece0'],
        topic_label: 'Value Solutions'
      },
      'Cross-Sector Versatility': {
        count: 1,
        ids: ['34b413d8-714a-491f-863f-c0dfc68d1017'],
        topic_label: 'Versatility'
      }
    }
  },
  'Proven Track Record': {
    count: 4,
    ids: [
      'd7d4b67a-f4d1-433c-9fc2-7de5914c2f8a',
      'bb4398e1-60d9-47ef-855b-06340cf6c912',
      '7bb40bc3-b1cf-486a-b35f-a75dbd3b812c',
      '34b413d8-714a-491f-863f-c0dfc68d1017'
    ],
    topic_label: 'Proven Success'
  },
  'Community Transformation': {
    count: 4,
    ids: [
      'ad84c71f-1e61-44c0-a500-f8533ff3624f',
      '185f40af-e999-4fb1-a96e-20e401c67ed7',
      '7a1baa4d-e081-4b56-8a36-5c75eb00ece0',
      'bd7c484a-cdba-499b-9fd7-d64f67a4fd62'
    ],
    topic_label: 'Community Impact'
  }
}

const colorWithScore = (score) => {
  if (score >= 80) {
    return '#65a30d'
  }

  if (score >= 60) {
    return '#f97316'
  }

  if (score >= 40) {
    return '#facc15'
  }

  return '#ef4444'
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0',
    maxWidth: '400px',
    backgroundColor: '#131516',
    color: 'white',
    textAlign: 'left',
    width: '100%',
    marginTop: '5px',
    height: '100%',
    // border: '1px solid #dadde9',
    gap: '3px',
    display: 'flex',
    flexDirection: 'column'
  }
}))

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color: colorWithScore(props.value),
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round'
          }
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" sx={{ fontSize: '10px' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const MoreEvidenceDialog = ({ open, setOpen, evidences }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogTitle>
        <div className="flex items-center justify-between">
          <p className="m-0">Evidences</p>
          <CrossIcon
            className="cursor-pointer size-3"
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-3">
          {evidences?.map((evidence) => {
            return (
              <div key={evidence.quote} className="flex flex-col gap-1">
                <p className="m-0 text-xs">
                  "{evidence.quote}" - Page {evidence.page}
                </p>
                <p className="m-0 text-xs text-grey-500">{evidence.section}</p>
              </div>
            )
          })}
        </div>
      </DialogContent>
    </Dialog>
  )
}
