import React, { useState, useEffect, useRef } from 'react'
import { useStyles } from './styles'
import {
  getChatHistory,
  saveImagetoAsset,
  putUserDomain,
  chatFeedback,
  chatStop,
  generateImages,
  getChatInstructions,
  getChatDomainPrompts,
  getChatVoiceConfigs
} from '../../store/api'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Section, SectionFixed } from '../../components/Container'
import StopIcon from '@mui/icons-material/Stop'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import { htmlToText, textToHtml } from '../../utils/CopyHTML'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import { AvatarIcon } from '../../components/UserAvatar'
import ReactPullToRefresh from 'react-pull-to-refresh'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import Tooltip from '@mui/material/Tooltip'
import { Auth } from 'aws-amplify'
import { initalizeAWSSigner, getSignedUrl } from '../../utils/AWS'
import ImageRender from '../../components/ImageRender'
import _, { set } from 'lodash'
import { checkUserRoleSuperUser, checkUserRoleAdmin } from '../../utils/User'
import { Button, ImageGallery, TagFilter } from '../../components'
import Tab from '../../components/Tabs'
import {
  Box,
  Popover,
  MenuItem,
  MenuList,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Rating,
  DialogActions
} from '@mui/material'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../config/eventMapping'
import { setToasterAlert, fetchPrompts } from '../../store/Common/Actions'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import CloseIcon from '@material-ui/icons/Close'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import { v4 as uuid } from 'uuid'
import Loader from '../../components/Loader'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ButtonSelect from '../../components/ButtonSelect'
import SaveIcon from '@mui/icons-material/Save'
import DownloadIcon from '@mui/icons-material/Download'
import PreviewIcon from '@mui/icons-material/Preview'
import { toast } from 'react-toastify'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import CircularProgress from '@mui/material/CircularProgress'
import mixpanelEvents from '../../config/mixpanelEvents'
import { defaultChatInstructions } from '../../config/constants'
import markdownit from 'markdown-it'
import moment from 'moment'
import Sockette from 'sockette'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import TextLineLimiter from '../../components/TextLineLimiter'
import ServerDown from '../../assets/svg/ServerDown.svg'
import Error from '../../components/Error/Error'
import ContentEditable from 'react-contenteditable'
import { InsertResumeDailog } from '../../components/Lexical/plugins/ResumePlugin'
import useModal from '../../components/Lexical/hooks/useModal'
import { ReactComponent as ChatStatusLoading } from '../../assets/svg/loading.svg'
import { ReactComponent as ChatStatusLoadingDone } from '../../assets/svg/loadingDone.svg'
import Lottie from 'react-lottie'
import AiLoader from '../../assets/lottie/ai.json'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  DOMAIN_CONFIG_UPDATE,
  REVERT_FAVPROMPT_DOMAIN_CONFIG
} from '../../store/actionTypes'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import AddIcon from '@mui/icons-material/Add'
import { UpArrowIcon } from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'

const TimeStamp = (props) => {
  const { message } = props || {}
  const { created_at } = message || {}
  const classes = useStyles()
  return (
    created_at && (
      <Box className={classes.chatTimeStamp}>
        {moment.utc(created_at).local().format('lll')}
      </Box>
    )
  )
}

const SortLibrary = (props) => {
  const { filterBy = [], setFilterBy = () => {}, disabled = false } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterOptions = [
    {
      key: 'title',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'title',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        disableRipple
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const LibraryPrompt = (props) => {
  const {
    prompts = [],
    promptSearch = '',
    promptFilter = {},
    handlePromptSelection = () => {},
    showCreateNew = false,
    createNew = () => {},
    favPrompts = [],
    handlePromptFav = () => {},
    promptType = '',
    isAdmin = false
  } = props

  const [tagOptions, setTagOptions] = useState({})
  const [selectedTag, setSelectedTag] = useState({
    key: 'All',
    value: '@default'
  })
  const [filteredPrompts, setFilteredPrompts] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const tags = {}
    prompts.forEach((item) => {
      Object.keys(item.tags || {}).forEach((tag) => {
        if (!tags[tag]) {
          tags[tag] = []
        }
        tags[tag] = [...new Set([...tags[tag], ...item.tags[tag]])]
      })
    })
    setTagOptions(tags)
  }, [prompts])

  const sortData = (data) => {
    return data.sort((a, b) => {
      const key = promptFilter.key
      let order = promptFilter.order
      let comparisonA = a[key]
      let comparisonB = b[key]
      if (key === 'created_at' || key === 'updated_at') {
        comparisonA = new Date(a[key])
        comparisonB = new Date(b[key])
        if (comparisonA.getTime() === comparisonB.getTime()) {
          comparisonA = a.title?.toLowerCase()
          comparisonB = b.title?.toLowerCase()
          order = 'asc'
        }
      } else if (key === 'title') {
        comparisonA = a[key]?.toLowerCase()
        comparisonB = b[key]?.toLowerCase()
      }
      if (comparisonA < comparisonB) {
        return order === 'desc' ? 1 : -1
      }
      if (comparisonA > comparisonB) {
        return order === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  useEffect(() => {
    let filteredPrompts = prompts.filter((item) => {
      if (selectedTag.key === 'All' && selectedTag.value === '@default') {
        return true
      } else if (selectedTag.value === '@tag') {
        return Object.keys(item.tags || {}).includes(selectedTag.key)
      } else {
        return (
          Object.keys(item.tags || {}).includes(selectedTag.key) &&
          item.tags[selectedTag.key].includes(selectedTag.value)
        )
      }
    })

    filteredPrompts = filteredPrompts.filter((item) => {
      if (promptSearch === '') {
        return true
      }
      return (
        item.title.toLowerCase().includes(promptSearch.toLowerCase()) ||
        item.prompt.toLowerCase().includes(promptSearch.toLowerCase())
      )
    })

    filteredPrompts = sortData(filteredPrompts)

    setFilteredPrompts(filteredPrompts)
  }, [promptSearch, promptFilter, selectedTag, prompts])

  return (
    <Box className={classes.promptLibraryWrapper}>
      <List sx={{ padding: '0px' }} className={classes.promptTags}>
        <ListItem disablePadding>
          <ListItemButton
            selected={
              selectedTag.key === 'All' && selectedTag.value === '@default'
            }
            onClick={() =>
              setSelectedTag({
                key: 'All',
                value: '@default'
              })
            }
          >
            <ListItemText
              primary={
                <Box className={classes.promptTag} sx={{ fontSize: '16px' }}>
                  All
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
        {Object.keys(tagOptions).map((tag, index) => {
          return (
            <Box key={index} sx={{ marginTop: '5px' }}>
              <ListItem disablePaddin>
                <ListItemButton
                  selected={
                    selectedTag.key === tag && selectedTag.value === '@tag'
                  }
                  onClick={() =>
                    setSelectedTag({
                      key: tag,
                      value: '@tag'
                    })
                  }
                >
                  <ListItemText
                    primary={<Box className={classes.promptTag}>{tag}</Box>}
                  />
                </ListItemButton>
              </ListItem>
              {tagOptions[tag].map((item, index) => {
                return (
                  <ListItem disablePadding key={index}>
                    <ListItemButton
                      selected={
                        selectedTag.key === tag && selectedTag.value === item
                      }
                      onClick={() =>
                        setSelectedTag({
                          key: tag,
                          value: item
                        })
                      }
                    >
                      <ListItemText
                        primary={
                          <Box className={classes.promptTagValue}>{item}</Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </Box>
          )
        })}
      </List>
      <Box className={classes.promptContent}>
        <Container>
          <Section overFlow>
            <Box sx={{ marginTop: '15px' }}>
              {_.isEmpty(filteredPrompts) ? (
                <Box
                  className={classes.promptContainer}
                  sx={{ textAlign: 'center' }}
                >
                  <Box className={classes.noPrompt}>No prompts found</Box>
                  {showCreateNew && isAdmin && (
                    <Box sx={{ marginTop: '15px' }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          createNew()
                        }}
                      >
                        Create Prompts
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                filteredPrompts.map((item, index) => {
                  const {
                    title,
                    description = '',
                    prompt = '',
                    id,
                    disabled = false
                  } = item
                  return (
                    <Box
                      className={
                        disabled
                          ? clsx(
                              classes.promptContainer,
                              classes.promptContainerDisabled
                            )
                          : classes.promptContainer
                      }
                      key={id}
                    >
                      <Box className={classes.promptWrapperOver}>
                        <Box sx={{ flex: 1 }}>
                          <Box className={classes.promptTitle}>{title}</Box>
                          <Box className={classes.prompt}>
                            <TextLineLimiter
                              content={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: textToHtml(prompt)
                                  }}
                                />
                              }
                              limit={3}
                              wordBreak={'break-word'}
                              indicatorStyle={{
                                color: 'blue',
                                cursor: 'pointer',
                                fontStyle: 'normal',
                                fontWeight: '100',
                                fontSize: '12px'
                              }}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <CopyButton text={prompt} isPrompt />
                        </Box>
                        {isAdmin && (
                          <Box
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                          >
                            <Tooltip
                              title={
                                favPrompts.includes(item.id)
                                  ? 'Remove from favourites'
                                  : 'Add to favourites'
                              }
                            >
                              <IconButton
                                className={classes.favIconWrapper}
                                disabled={disabled}
                                disableRipple
                                onClick={() => handlePromptFav(id, promptType)}
                              >
                                {favPrompts.includes(item.id) ? (
                                  <StarIcon />
                                ) : (
                                  <StarBorderIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <Box>
                          <Box
                            onClick={() => {
                              handlePromptSelection(item)
                            }}
                            className={classes.promptButton}
                          >
                            <Box className={classes.usePrompt}>Use Prompt</Box>
                          </Box>
                        </Box>
                      </Box>

                      <Divider />
                    </Box>
                  )
                })
              )}
            </Box>
          </Section>
        </Container>
      </Box>
    </Box>
  )
}

const findNode = (id, nestedObject) => {
  function recursiveSearch(nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        return nodes[i]
      }
      if (nodes[i].children) {
        const result = recursiveSearch(nodes[i].children)
        if (result) {
          return result
        }
      }
    }
    return null
  }
  return recursiveSearch(nestedObject)
}

const ChatFilterTag = (props) => {
  const { message, voiceConfigs = [] } = props || {}
  const [dataMap, setDataMap] = useState({})
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const collectionState = useSelector((state) => state.resource?.collections)

  useEffect(() => {
    const { data } = message || {}
    const { filter } = data || {}
    const { filter_options = {} } = filter || {}
    let {
      collections = {},
      tags = [],
      tag_dates = [],
      index_type = {},
      voice_id
    } = filter_options || {}
    tags = tags || []
    tag_dates = tag_dates || []
    collections = collections || {}
    index_type = index_type || {}

    const map = {}
    if (voice_id) {
      const voice = voiceConfigs.find((item) => item.id === voice_id)
      if (voice) {
        map.Voice = {
          condition: ':',
          values: [voice?.title]
        }
      }
    }
    if (!_.isArray(index_type) && !_.isEmpty(index_type)) {
      let type = index_type?.values
      type = type || []
      type = type.map((item) => {
        return _.startCase(item)
      })
      let { condition } = index_type || {}
      condition = condition || 'is'
      map.source_type = {
        condition,
        values: type
      }
    }

    tag_dates?.forEach((item, index) => {
      const { key, start, end } = item || {}
      let { condition } = item || {}
      condition = condition || 'is'
      condition = condition === 'is' ? 'is between' : 'is not between'
      map[key] = {
        condition,
        values: [`${moment(start).format('ll')} - ${moment(end).format('ll')}`]
      }
    })
    const tags_values = {}
    tags.forEach((item) => {
      const { key, condition, values } = item
      if (key) {
        tags_values[key] = {
          condition: condition || 'is',
          tags: values
        }
      }
    })
    Object.keys(tagsCenterStateTags || {}).forEach((k, index) => {
      const { data = [] } = tagsCenterStateTags[k] || {}
      data.forEach((element) => {
        if (tags_values[k]?.tags?.includes(element.id) && element.value) {
          if (tags_values[k].values) {
            tags_values[k].values.push(element.value)
          } else {
            tags_values[k].values = [element.value]
          }
        }
      })
    })

    Object.keys(tags_values || {}).forEach((key, index) => {
      map[key] = {
        condition: tags_values[key].condition,
        values: tags_values[key].values
      }
    })
    if (!_.isArray(collections) && !_.isEmpty(collections)) {
      const collection_values = []
      const { condition, values } = collections || {}
      values?.forEach((item) => {
        const node = findNode(item, collectionState)
        if (node) {
          collection_values.push(node.name)
        }
      })
      if (collection_values.length > 0) {
        map.collections_selected = {
          condition: condition || 'is',
          values: collection_values
        }
      }
    }
    setDataMap(map)
  }, [message, tagsCenterStateTags])

  const classes = useStyles()
  return (
    <RestrictContentOverFlow>
      {Object.keys(dataMap || {}).map((key, index) => {
        return (
          <>
            <Box key={index} className={classes.chatFiltersText}>
              {key.includes('_') ? _.startCase(key) : key}{' '}
              {dataMap[key].condition}
              {dataMap[key]?.values?.map(
                (item, index) =>
                  index === 0 && (
                    <span key={index}>
                      {' '}
                      {item}
                      {dataMap[key]?.values.length > 1 && (
                        <Tooltip
                          arrow
                          title={dataMap[key]?.values.slice(1).join(', ')}
                        >
                          <span> +{dataMap[key]?.values.length - 1}</span>
                        </Tooltip>
                      )}
                    </span>
                  )
              )}
            </Box>
          </>
        )
      })}
    </RestrictContentOverFlow>
  )
}

const CopyButton = ({ text, isPrompt = false }) => {
  const [isCopying, setIsCopying] = useState(false)
  const handleCopy = (e) => {
    if (isPrompt) {
      trackEvent(mixpanelEvents.CHAT_PROMPT_COPIED, 'SUCCESS', {}, {})
    } else {
      trackEvent(mixpanelEvents.CHAT_CONTENT_COPIED, 'SUCCESS', {}, {})
    }
    e.stopPropagation()
    e.preventDefault()
    if (!isCopying) {
      text = text.replaceAll('\n', ' ')
      text = text.replaceAll('<br>', '\n')
      const lastIndex = text.lastIndexOf('References')
      if (lastIndex !== -1) {
        text = text.substring(0, lastIndex)
      }
      text = htmlToText(text)
      navigator.clipboard.writeText(text)
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 500)
    }
  }

  return (
    <div
      style={{
        padding: '0px 8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      disabled={isCopying}
      onClick={(e) => handleCopy(e)}
    >
      <Tooltip title={'Copy Text'}>
        {isCopying ? (
          <DoneOutlinedIcon className="size-4" />
        ) : (
          <ContentCopyIcon className="size-4" />
        )}
      </Tooltip>
    </div>
  )
}

const SelectButtonCustom = (props) => {
  return (
    <ButtonSelect
      buttonGroupProps={{
        sx: {
          boxShadow: 'none',
          textTransform: 'uppercase'
        }
      }}
      buttonProps={{
        variant: 'outlined',
        sx: {
          padding: '2px 6px',
          fontWeight: '400 !important',
          border: '1px solid transparent !important',
          fontSize: '13px',
          '&:before': {
            content: '""',
            position: 'absolute',
            inset: '0',
            borderRadius: '4px',
            padding: '1px',
            background: 'linear-gradient(100deg, #471CA8, #FF3465)',
            WebkitMask:
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude'
          }
        }
      }}
      menuProps={{
        sx: {
          '& .MuiMenuItem-root': {
            fontSize: '13px'
          }
        }
      }}
      {...props}
    />
  )
}

export const SystemPrompt = ({ text, convertToHTML }) => {
  const classes = useStyles()
  const [showPrompt, setShowPrompt] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const handleCopy = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!isCopying) {
      navigator.clipboard.writeText(htmlToText(text.replaceAll('**', '')))
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 500)
    }
  }

  return (
    text && (
      <Box className={classes.showPromptWrapper}>
        <span
          onClick={() => setShowPrompt(!showPrompt)}
          className={classes.showPrompt}
        >
          {showPrompt ? 'Hide' : 'Show'} Prompt
        </span>
        {showPrompt && (
          <span onClick={(e) => handleCopy(e)} className={classes.showPrompt}>
            {isCopying ? 'Copied' : 'Copy Prompt'}
          </span>
        )}
        {showPrompt && (
          <div
            className={classes.messageText}
            dangerouslySetInnerHTML={{ __html: convertToHTML(text) }}
            style={{ background: '#ececec' }}
          />
        )}
      </Box>
    )
  )
}

const RestrictContentOverFlow = (props) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const containerRef = useRef(null)
  const classes = useStyles()
  useEffect(() => {
    const checkOverflow = () => {
      if (!containerRef.current) return
      const { scrollHeight, clientHeight } = containerRef.current
      setIsOverflowing(scrollHeight > clientHeight)
    }

    setTimeout(checkOverflow, 100)
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  return (
    <Box>
      <Box
        ref={containerRef}
        className={
          isExpanded
            ? clsx(classes.chatFilters, classes.chatFiltersExpanded)
            : classes.chatFilters
        }
      >
        {props.children}
      </Box>
      {isOverflowing && (
        <Box className={classes.showPromptWrapper}>
          <Box
            className={classes.showPrompt}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Show Less Tags' : 'Show More Tags'}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const labels = {
  1: 'Useless',
  2: 'Poor',
  3: 'Ok',
  4: 'Good',
  5: 'Excellent'
}

const ChatBot = (props) => {
  const {
    historyProps = {},
    chatType = 'session',
    trackerId = '',
    initalPrompt = '',
    relayLoading = () => {},
    handleChatAddition = () => {},
    showEmptyChatElements = false,
    prefixElements = () => {},
    textBoxPlaceholder = '',
    imageTextBoxPlaceHolder = '',
    updateFeedbackCallback = () => {},
    showHistoryByDefault = false,
    chatTextBox = '',
    showFilter = false,
    proposalIds = [],
    showImageGeneration = false,
    enableTextSelect = false,
    s3Obj = {}
  } = props

  const isSuperUser = checkUserRoleSuperUser()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { SETTINGS_CHAT } = ROUTES
  const reqSocket = !!['session', 'workspace'].includes(chatType)

  // Signer
  const [signerObj, setSignerObj] = useState({})

  // Message List
  const [messageList, setMessageList] = useState([])

  // Controller Ref
  const currentTrackerRef = useRef(null)
  const streamLoadingRef = useRef(null)
  const messageListRef = useRef(messageList)
  const historyRef = useRef(null)
  const spanRef = useRef(null)
  const prevContentRef = useRef('')
  const currentChatIdRef = useRef(null)

  const [initalPromptLoaded, setInitalPromptLoaded] = useState(false)

  // Input Text
  const [textBox, setTextBox] = useState('')

  // History
  const [stateLoading, setStateLoading] = useState(false)
  const [initalizing, setInitalizing] = useState(true)
  const [initalizePrompts, setInitalizePrompts] = useState(true)
  const [loadingHistory, setLoadingHistory] = useState(false)
  const [history, setHistory] = useState([])
  const [page, setPage] = useState(null)
  const [showHistory, setShowHistory] = useState(showHistoryByDefault)
  const [hasMoreHistory, setHasMoreHistory] = useState(true)
  const prevHistoryCountRef = useRef()

  // Disclaimer
  const [chatInstructions, setChatInstructions] = useState(null)
  const disclaimer = chatInstructions?.disclaimer || ''

  // Socket Connection
  const [socket, setSocket] = useState(null)
  const [socketStatus, setSocketStatus] = useState(!reqSocket)

  // Feedback
  const [feedbackObj, setFeedbackObj] = useState({
    reaction: '',
    feedback: '',
    id: ''
  })
  const [openFeedback, setOpenFeedback] = useState(false)
  const [newFeedback, setNewFeedback] = useState('')
  const [starsValue, setStarsValue] = React.useState(0)
  const [starsHover, setStarsHover] = React.useState(-1)
  const [feedbackAtMessage, setFeedbackAtMessage] = useState(0)
  const [openChatFeedback, setOpenChatFeedback] = useState(false)

  // Scroll
  const listRef = useRef(null)

  // Chat Mode
  const [chatMode, setChatMode] = useState('chat')
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageData, setImageData] = useState()

  // Tags and Filters
  const [selectedTags, setSelectedTags] = useState(null)
  const selectedTagsRef = useRef(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)

  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name = '', domain_id, domain_config } = domain || {}
  const promptFav = domain_config?.prompt_fav || {}
  const {
    domain_prompts: domain_prompts_fav = [],
    default_prompts: default_prompts_fav = []
  } = promptFav

  const loading = streamLoadingRef?.current?.[trackerId] || false

  const scrollLock = useRef(false)

  // Text Select
  const [anchorEl, setAnchorEl] = useState(null)

  const [voiceConfigs, setVoiceConfigs] = useState(null)
  const [domainPrompts, setDomainPrompts] = useState(null)
  const [randomPrompts, setRandomPrompts] = useState([])
  const [voiceOptions, setVoiceOptions] = useState([])
  const [defaultVoiceIndex, setDefaultVoiceIndex] = useState(0)

  const [showPromptLibrary, setShowPromptLibrary] = useState(false)
  const [selectedVoice, setSelectedVoice] = useState(null)
  const selectedVoiceRef = useRef(null)

  const textBoxRef = useRef(null)

  // Resume Generate
  const [modal, showModal] = useModal()

  // Prompt Library
  const [promptSearch, setPromptSearch] = useState('')
  const [promptFilter, setPromptFilter] = useState({
    key: 'created_at',
    order: 'desc'
  })

  const [stopChatLoading, setStopChatLoading] = useState({})

  const common = useSelector((userState) => userState?.common)
  const collectionState = useSelector((state) => state.resource?.collections)
  const { prompts: generatePrompts = [] } = common
  const [selectPrompts, setSelectPrompts] = useState([])

  const isSocketConnected = !reqSocket
    ? true
    : chatMode !== 'chat'
    ? true
    : socketStatus === 'connected'

  const isAdmin = checkUserRoleAdmin()

  useEffect(() => {
    if (!generatePrompts) {
      dispatch(fetchPrompts())
    } else {
      const prompts = generatePrompts.filter(
        (item) => item.promptType === 'chat'
      )
      setSelectPrompts(prompts)
    }
  }, [generatePrompts])

  const handleSelect = (event, message, index, isMessage) => {
    if (!enableTextSelect) return
    const selection = window.getSelection()
    const selectedText = selection.toString().trim()
    if (selectedText !== '') {
      const range = selection.getRangeAt(0)
      const boundingRect = range.getBoundingClientRect()
      const position = {
        top: boundingRect.bottom + window.scrollY,
        left: boundingRect.left + window.scrollX + boundingRect.width / 2
      }
      setAnchorEl({
        position,
        selectedText,
        edit: false,
        message,
        index,
        isMessage
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const md = markdownit({
    html: true
  })

  const preprocessMarkdown = (markdownText) => {
    return markdownText.replace(/<br>/g, '  \n')
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    markdownText = preprocessMarkdown(markdownText)
    let updatedMarkdownText = markdownText
    try {
      updatedMarkdownText = markdownText.replace(
        /<a\s+([^>]*?)>/g,
        (match, attributes) => {
          if (!/target=/.test(attributes)) {
            return `<a target="_blank" ${attributes}>`
          }
          return match
        }
      )
    } catch (e) {}
    return md.render(updatedMarkdownText)
  }

  useEffect(() => {
    currentTrackerRef.current = trackerId
    setShowHistory(showHistoryByDefault)
    setMessageList([])
    setSelectedTags(null)
    setStateLoading(true)
    scrollToBottom(true)
  }, [trackerId])

  useEffect(() => {
    messageListRef.current = messageList
  }, [messageList])

  useEffect(() => {
    historyRef.current = history
  }, [history])

  useEffect(() => {
    selectedTagsRef.current = selectedTags
  }, [selectedTags])

  useEffect(() => {
    selectedVoiceRef.current = selectedVoice
  }, [selectedVoice])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const { data = [], type, ...rest } = tagsCenterStateTags[key] || {}
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })
    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  const setPrevFilters = (chats) => {
    const lastChat = chats[chats.length - 1]
    const { data = {} } = lastChat || {}
    const { filter = {} } = data || {}
    const { filter_options = {} } = filter || {}
    let {
      tags = [],
      tag_dates = [],
      collections = {},
      index_type = {},
      voice_id
    } = filter_options || {}
    const selectedTags = {}
    const tag_values = []
    let collectionIds = {}
    tags.forEach((item) => {
      const { key, condition, values } = item
      const options = tagsOptions[key]?.values || []
      const valuesArray = []
      values?.forEach((item) => {
        const option = options.find((option) => option.value === item)
        if (option) {
          valuesArray.push(option)
        }
      })
      if (key) {
        tag_values.push({
          key,
          condition: condition || 'is',
          values: valuesArray
        })
      }
    })
    tag_dates.forEach((item) => {
      const { key } = item
      let { condition } = item
      condition = condition || 'is'
      condition = condition === 'is' ? 'is between' : 'is not between'
      tag_values.push({
        key,
        condition,
        values: [{ ...item }]
      })
    })
    if (!_.isArray(collections) && !_.isEmpty(collections)) {
      const collection_values = []
      const { condition, values } = collections || {}
      values?.forEach((item) => {
        const node = findNode(item, collectionState)
        if (node) {
          collection_values.push({
            label: node.name,
            name: node.name,
            id: item
          })
        }
      })
      if (collection_values.length > 0) {
        collectionIds = {
          condition: condition || 'is',
          values: collection_values
        }
      }
    }
    if (!_.isArray(index_type) && !_.isEmpty(index_type)) {
      let type = index_type?.values
      type = type || []
      type = type.map((item) => {
        return {
          value: item,
          label: _.startCase(item)
        }
      })
      let { condition } = index_type || {}
      condition = condition || 'is'
      index_type = {
        condition,
        values: type
      }
    }
    tag_values.forEach((item) => {
      const { key, condition, values } = item
      if (key) {
        selectedTags[key] = {
          condition,
          values
        }
      }
    })

    if (collectionIds && !_.isEmpty(collectionIds)) {
      selectedTags.collections_selected = collectionIds
    }
    if (index_type && !_.isEmpty(index_type)) {
      selectedTags.source_type = index_type
    }
    setSelectedTags(selectedTags)
    if (voice_id && voiceConfigs) {
      const findIndex = voiceOptions.findIndex(
        (item) => item.value === voice_id
      )
      if (findIndex !== -1) {
        setDefaultVoiceIndex(findIndex)
        setSelectedVoice(voiceOptions[findIndex])
      }
    }
  }

  useEffect(() => {
    if (stateLoading) {
      if (historyProps && !_.isEmpty(historyProps)) {
        const { chat } = historyProps
        if (_.isEmpty(chat)) {
          const callback = () => {
            setStateLoading(false)
          }
          fetchHistory(1, callback)
        } else {
          const { chats, page_num, has_next_page } = chat
          setHistory(_.cloneDeep(chats))
          setPrevFilters(chats)
          setHasMoreHistory(has_next_page)
          setPage(page_num)
          setStateLoading(false)
        }
      } else {
        setStateLoading(false)
        setHasMoreHistory(false)
        setHistory([])
      }
    }
  }, [stateLoading, trackerId])

  useEffect(() => {
    setTextBox('')
    setImageData({
      imageType: 'Real',
      imageSize: 512,
      imageNumber: 4
    })
  }, [trackerId])

  useEffect(() => {
    async function initializeData() {
      const res1 = getChatInstructions()
      const res2 = getChatVoiceConfigs()
      const res3 = getChatDomainPrompts()
      setInitalizing(true)
      const [chatInstructions, voiceConfigs, domainPrompts] = await Promise.all(
        [res1, res2, res3]
      )
      if (
        chatInstructions.status === 200 &&
        !_.isEmpty(chatInstructions.data)
      ) {
        setChatInstructions(chatInstructions.data)
      } else {
        setChatInstructions(defaultChatInstructions)
      }
      if (voiceConfigs.status === 200 && !_.isEmpty(voiceConfigs.data)) {
        const voiceConfigsRes = voiceConfigs.data
        setVoiceConfigs(voiceConfigsRes)
        const voiceOptions = voiceConfigsRes?.map((item, index) => {
          return {
            value: item.id,
            label: item.title
          }
        })
        setVoiceOptions(voiceOptions)
        let defaultVoiceIndex = voiceConfigsRes.findIndex(
          (item) => item?.is_default
        )
        defaultVoiceIndex = defaultVoiceIndex === -1 ? 0 : defaultVoiceIndex
        setDefaultVoiceIndex(defaultVoiceIndex)
        setSelectedVoice(voiceOptions[defaultVoiceIndex])
      }
      if (domainPrompts.status === 200 && !_.isEmpty(domainPrompts.data)) {
        setDomainPrompts(domainPrompts.data)
      }
      setInitalizing(false)
    }
    initializeData()
  }, [])

  useEffect(() => {
    if (domainPrompts) {
      const allFavorites = [...default_prompts_fav, ...domain_prompts_fav]
      const { domain_prompts = [], default_prompts = [] } = domainPrompts || {}
      const allPrompts = [...default_prompts, ...domain_prompts]
      if (_.isEmpty(allFavorites) && _.isEmpty(randomPrompts)) {
        const randomSet = getRandomElements(allPrompts, 5)
        setRandomPrompts(randomSet)
      } else {
        const favPrompts = allPrompts.filter((item) =>
          allFavorites.includes(item.id)
        )
        setRandomPrompts(favPrompts)
      }
      setInitalizePrompts(false)
    }
  }, [domain_config, domainPrompts])

  useEffect(() => {
    if (chatTextBox) {
      setTextBox(chatTextBox)
    }
  }, [chatTextBox])

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (initalPrompt && socketStatus === 'connected' && !initalPromptLoaded) {
      handleChatInit(initalPrompt, trackerId)
      setInitalPromptLoaded(true)
    }
  }, [initalPrompt, socketStatus])

  const fetchHistory = async (page, callback) => {
    setLoadingHistory(true)
    if (!page) {
      setLoadingHistory(false)
      return
    }
    try {
      const data = {
        id: trackerId,
        page_num: page,
        page_size: 10,
        type: chatType,
        time: new Date().toISOString()
      }
      const response = await getChatHistory(data)
      if (response.status === 200) {
        const { data = [] } = response
        const { chats = [], has_next_page, page_num } = data
        const signChats = chats
        const promiseChats = chats.map(async (item, index) => {
          const { data = {}, type, author } = item || {}
          const { text, error } = data || {}
          if (
            type === 'image' &&
            author === 'Them' &&
            !error &&
            text !== 'Generation Stopped by User'
          ) {
            if (!_.isEmpty(s3Obj) && text) {
              const imageUrls = text?.split(',')
              const signedUrls = []
              const promiseImages = imageUrls.map(async (url) => {
                const signed = await getSignedUrl(url, s3Obj)
                signedUrls.push(signed)
              })
              await Promise.all(promiseImages)
              const obj = {
                ...item,
                data: {
                  ...data,
                  imageUrls: signedUrls
                }
              }
              signChats[index] = obj
            } else {
              const obj = {
                ...item,
                data: {
                  ...data,
                  text: 'Unable to load images'
                }
              }
              signChats[index] = obj
            }
          }
        })
        await Promise.all(promiseChats)
        if (currentTrackerRef?.current === trackerId) {
          setHasMoreHistory(has_next_page)
          if (page_num === 1) {
            setHistory(signChats)
            setPrevFilters(signChats)
          } else {
            setHistory((prev) => {
              const newHistory = [...signChats, ...prev]
              return newHistory
            })
          }
          setPage(page)
        }
        handleChatAddition({ ...data, chats: signChats })
        callback && callback()
      } else {
        toast.error('Unable to fetch chat history')
        setHasMoreHistory(false)
      }
    } catch (error) {
      setHasMoreHistory(false)
    }
    setLoadingHistory(false)
  }

  const { regenerateTitle = false } = historyProps || {}

  const trackChatInit = (id, chatId, newMessage, trackerId) => {
    let initType = ''
    if (chatType === 'session') {
      if (trackerId === 'new') {
        if (initalPrompt) {
          initType = eventMapping.CHAT_SESSION_SEARCH
        } else {
          initType = eventMapping.CHAT_NEW_SESSION
        }
      } else {
        initType = eventMapping.CHAT_EXISTING_SESSION
      }
    } else if (chatType === 'proposal') {
      initType = mixpanelEvents.PROPOSAL_CHAT
    } else if (chatType === 'analytics') {
      initType = mixpanelEvents.ANALYTICS_CHAT
    } else if (chatType === 'rfx') {
      initType = mixpanelEvents.RFX_CHAT
    } else if (chatType === 'workspace') {
      initType = mixpanelEvents.WORKSPACE_CHAT
    } else if (chatType === 'document') {
      initType = mixpanelEvents.DOCUMENT_CHAT
    }
    const additionalParams = {
      chat_id: chatId,
      chat_type: chatType,
      chat_prompt: newMessage,
      chat_tracker_id: id
    }
    if (initType) {
      trackEvent(initType, 'SUCCESS', {}, additionalParams)
    }
  }

  useEffect(() => {
    if (!socketStatus && reqSocket) {
      connectToWebSocket()
    }
  }, [])

  useEffect(() => {
    if (reqSocket) {
      const handleFocus = () => {
        establishSocketConnection()
      }
      window.addEventListener('focus', handleFocus)
      return () => {
        window.removeEventListener('focus', handleFocus)
      }
    }
  }, [socketStatus])

  const establishSocketConnection = () => {
    if (socketStatus === 'disconnected') {
      connectToWebSocket()
    }
  }

  const connectToWebSocket = async () => {
    if (reqSocket) {
      const currentSession = await Auth.currentSession()
      const token = currentSession?.accessToken?.getJwtToken()
      console.log('Connecting to the chat server')
      const ws = new Sockette(
        process.env.REACT_APP_WEB_SOCKET_URL + `?token=${token}`,
        {
          timeout: 5e3,
          maxAttempts: 3,
          onopen: (e) => {
            setSocketStatus('connected')
            console.log('connected:', e)
            trackEvent(mixpanelEvents.CHAT_SOCKET_CONNECTED, 'SUCCESS', {}, {})
          },
          onmessage: (e) => {
            const { data } = e
            const messageObj = JSON.parse(data)
            const messageList = messageListRef.current
            const { session_id, chat_id, message, type, referrer_id } =
              messageObj || {}

            // console.log('1 session ', messageObj)
            currentChatIdRef.current = chat_id
            if (type === 'chat_status' && message === '__END_OF_CHAT__') {
              streamLoadingRef.current = {
                ...streamLoadingRef.current,
                [session_id]: false,
                [trackerId]: false
              }
            }
            if (
              session_id === currentTrackerRef.current ||
              chatType !== 'session' ||
              referrer_id === currentTrackerRef.current
            ) {
              let messageIndex = messageList.findIndex(
                (item) => item.id === chat_id && item.author === 'Them'
              )
              const newMessageList = [...messageList]
              let filters = {}
              let isUserStopped = false
              if (messageIndex === -1) {
                if (_.isArray(historyRef?.current)) {
                  const historyIndex = historyRef.current.findIndex(
                    (item) => item.id === chat_id && item.author === 'Them'
                  )
                  if (historyIndex !== -1) {
                    const newHistory = [...historyRef.current]
                    filters = newHistory[historyIndex]?.data?.filter || {}
                    isUserStopped =
                      newHistory[historyIndex]?.userstopped || false
                    newHistory.splice(historyIndex, 1)
                    setHistory(newHistory)
                  }
                }
                if (!isUserStopped) {
                  messageIndex = messageList.length
                  newMessageList.push({
                    id: chat_id,
                    type: 'text',
                    author: 'Them',
                    created_at: new Date().toUTCString(),
                    loading: true,
                    data: {
                      text: ' ',
                      systemPrompt: '',
                      inputText: '',
                      filter: showFilter ? filters : null,
                      error: '',
                      status: [],
                      startedChat: false
                    }
                  })
                }
              } else {
                isUserStopped =
                  newMessageList[messageIndex].userstopped || false
              }
              if (!isUserStopped) {
                if (type === 'chat') {
                  if (newMessageList[messageIndex].data.text === ' ') {
                    newMessageList[messageIndex].data.text = message
                  } else {
                    newMessageList[messageIndex].data.text =
                      newMessageList[messageIndex].data.text + message
                  }
                } else if (type === 'status') {
                  newMessageList[messageIndex].data.status.push(message)
                } else if (type === 'prompt') {
                  newMessageList[messageIndex].data.systemPrompt =
                    newMessageList[messageIndex].data?.systemPrompt + message
                } else if (
                  type === 'chat_status' &&
                  message === '__START_OF_CHAT__'
                ) {
                  newMessageList[messageIndex].data.startedChat = true
                } else if (
                  type === 'chat_status' &&
                  message === '__END_OF_CHAT__'
                ) {
                  newMessageList[messageIndex].loading = false
                  setTextBox('')
                }
                console.log('newMessageList', newMessageList[messageIndex])
                setMessageList(newMessageList)
                if (type === 'chat_status' && message === '__END_OF_CHAT__') {
                  scrollToBottom(false, 100)
                } else {
                  scrollToBottom()
                }
              }
            }
            handleChatAddition(messageObj, false, true)
          },
          onreconnect: (e) => {
            setSocketStatus('reconnecting')
            console.log('Reconnecting...', e)
          },
          onmaximum: (e) => {
            console.log('Stop Attempting!', e)
            trackEvent(mixpanelEvents.CHAT_SOCKET_ERRORED, 'SUCCESS', {}, {})
            setSocketStatus('errored')
          },
          onclose: (e) => {
            console.log('Closed!', e)
            const { wasClean } = e
            if (wasClean) {
              setSocketStatus('disconnected')
              trackEvent(
                mixpanelEvents.CHAT_SOCKET_DISCONNECTED,
                'SUCCESS',
                {},
                {}
              )
            }
          },
          onerror: (e) => {
            console.log('Error:', e)
          }
        }
      )
      setSocket(ws)
    }
  }

  const removeBraces = (text) => {
    return text.replace(/[{()}]/g, '')
  }

  const handleChatInit = async (
    newMessage,
    trackerId,
    chatMode = 'chat',
    imageData = null,
    resumeData = {}
  ) => {
    const newId = uuid()
    const chatId = uuid()
    currentChatIdRef.current = chatId
    const id = trackerId === 'new' ? newId : trackerId
    const msg = removeBraces(newMessage)
    const newMessageHtml = msg
    newMessage = htmlToText(msg)
    trackChatInit(id, chatId, newMessage, trackerId)
    const tag_values = []
    const tag_dates = []
    let collectionIds = {}
    let index_type = {}
    const selectedTags = selectedTagsRef.current
    const selectedVoice = selectedVoiceRef.current
    Object.keys(selectedTags || {}).forEach((key, index) => {
      if (key === 'collections_selected') {
        const { condition, values = [] } = selectedTags[key] || {}
        const value = values.map((item) => item.id)
        collectionIds = { condition: condition || 'is', values: value }
      } else if (key === 'source_type') {
        const { condition, values = [] } = selectedTags[key] || {}
        const value = values.map((item) => item.value)
        index_type = { condition: condition || 'is', values: value }
      } else {
        const tags = []
        let { condition, values = [] } = selectedTags[key] || {}
        values = values || []
        values.forEach((item) => {
          if (item?.type?.includes('date')) {
            tag_dates.push({ ...item, condition })
          } else {
            tags.push(item.value)
          }
        })
        if (tags.length > 0) {
          tag_values.push({
            key,
            condition,
            values: tags
          })
        }
      }
    })

    const filter = {
      collections: collectionIds,
      tags: tag_values,
      tag_dates,
      index_type,
      voice_id: selectedVoice?.value || null
    }
    if (
      !_.isEmpty(collectionIds) ||
      !_.isEmpty(tag_values) ||
      !_.isEmpty(tag_dates) ||
      !_.isEmpty(index_type)
    ) {
      trackEvent(
        mixpanelEvents.CHAT_FILTER_APPLIED,
        'SUCCESS',
        {},
        { ...filter, chat_id: chatId, session_id: id }
      )
    }
    if (selectedVoice?.value) {
      trackEvent(
        mixpanelEvents.CHAT_VOICE_APPLIED,
        'SUCCESS',
        {},
        {
          voice_id: selectedVoice?.value,
          chat_id: chatId,
          session_id: id
        }
      )
    }
    if (newMessage.trim()) {
      const newMessageList = [...messageList]
      let objReq = {}
      let objReqIndex = -1
      if (currentTrackerRef.current === trackerId) {
        objReq = {
          id: chatId,
          type: 'text',
          author: 'Me',
          data: {
            text: newMessageHtml,
            filter: showFilter ? { filter_options: { ...filter } } : null
          },
          created_at: new Date().toUTCString()
        }
        objReqIndex = newMessageList.length
        if (trackerId !== 'new') {
          newMessageList.push(objReq)
          setMessageList(newMessageList)
          scrollToBottom(false, 100)
        }
      }
      let updateData = { chats: [objReq], count: 1, id }
      let regTitle = regenerateTitle
      if (trackerId === 'new') {
        const tag_values = []
        const tag_dates = []
        let collectionIds = {}
        let index_type = {}
        Object.keys(selectedTags || {}).forEach((key, index) => {
          if (key === 'collections_selected') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.id)
            collectionIds = { condition: condition || 'is', values: value }
          } else if (key === 'source_type') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.value)
            index_type = { condition: condition || 'is', values: value }
          } else {
            const tags = []
            let { condition, values = [] } = selectedTags[key] || {}
            values = values || []
            values.forEach((item) => {
              if (item?.type?.includes('date')) {
                tag_dates.push({ ...item, condition })
              } else {
                tags.push(item.value)
              }
            })
            if (tags.length > 0) {
              tag_values.push({
                key,
                condition,
                values: tags
              })
            }
          }
        })
        updateData = {
          data: {
            [id]: {
              id,
              title: newMessage,
              created_at: new Date().toISOString(),
              filter: {
                collections: collectionIds,
                tags: tag_values,
                tag_dates,
                index_type
              },
              chat: {
                chats: [objReq],
                count: 1,
                id
              }
            }
          },
          id: trackerId,
          newId: id
        }
        regTitle = false
      }
      handleChatAddition(updateData, regTitle)
      scrollToBottom(scrollLock.current)
      const referrer_id =
        chatType === 'session'
          ? trackerId === 'new'
            ? newId
            : trackerId
          : trackerId
      try {
        streamLoadingRef.current = {
          ...streamLoadingRef.current,
          [id]: chatId
        }

        const requestType =
          chatType === 'analytics' ? 'anthopicChat' : 'docChat'
        if (chatMode === 'image') {
          let { imageType, imageSize, imageNumber } = imageData
          imageType = imageType?.value || 'Real'
          imageSize = imageSize?.value || 512
          imageNumber = imageNumber?.value || 4
          const payload = {
            chat_id: chatId,
            chat_type: chatType,
            referrer_id,
            prompt: newMessage,
            resolution: imageSize,
            num_images: imageNumber,
            image_type: imageType
          }
          const obj = {
            id: chatId,
            type: 'image',
            author: 'Them',
            created_at: new Date().toUTCString(),
            loading: true,
            data: {}
          }
          newMessageList.push(obj)
          const messageIndex = newMessageList.length - 1
          const res = await generateImages(payload)
          const currentObj = messageListRef?.current?.[messageIndex]
          if (!currentObj?.userstopped) {
            if (res.status === 200) {
              trackEvent(mixpanelEvents.CHAT_IMAGE_GENERATED, 'SUCCESS', {}, {})
              const { image_url, system_prompt } = res?.data || {}
              const obj = {
                id: chatId,
                type: 'image',
                author: 'Them',
                created_at: new Date().toUTCString(),
                loading: false,
                data: {
                  text: image_url,
                  imageUrls: image_url.split(','),
                  systemPrompt: system_prompt,
                  inputText: newMessage,
                  filter: showFilter ? { filter_options: { ...filter } } : null
                }
              }
              newMessageList[messageIndex] = obj
              const data = { chats: [obj], count: 1, id }
              handleChatAddition(data)
              if (currentTrackerRef.current === id) {
                setMessageList(newMessageList)
                scrollToBottom(false, 100)
                setTextBox('')
              }
              streamLoadingRef.current = {
                ...streamLoadingRef.current,
                [id]: false
              }
            } else {
              trackEvent(mixpanelEvents.CHAT_IMAGE_GENERATED, 'FAILED', {}, {})
              const { exception } = res?.response?.data || {}
              const text =
                'Error during Image Generation\nSomething went wrong! Please try again.'
              console.log(exception)
              const obj = {
                id: chatId,
                type: 'text',
                author: 'Them',
                created_at: new Date().toUTCString(),
                data: {
                  text,
                  error: exception,
                  inputText: newMessage,
                  filter: showFilter ? { filter_options: { ...filter } } : null
                }
              }
              newMessageList.push(obj)
              const data = { chats: [obj], count: 1, id }
              handleChatAddition(data)

              if (currentTrackerRef.current === id) {
                setMessageList(newMessageList)
                setTextBox('')
              }
              streamLoadingRef.current = {
                ...streamLoadingRef.current,
                [id]: false
              }
            }
          }
        } else if (chatMode === 'resume') {
          let prompt = ''
          let remainingText = ''
          let completeText = ''
          const req = {
            referrer_id,
            chat_type: chatType,
            chat_id: chatId,
            user_message: newMessage,
            forwardPayload: resumeData.data,
            is_resume: true,
            prompt: '',
            document_id: '',
            is_analytics: false,
            requestType:
              resumeData.type === 'people' ? 'resume' : 'projectGenerate'
          }
          const url = process.env.REACT_APP_CHAT_URL || ''
          const apiUrl = new URL(url)
          const currentSession = await Auth.currentSession()
          const token = currentSession?.getAccessToken()?.getJwtToken()
          const signedUrl = await signerObj.sign({
            method: 'POST',
            hostname: apiUrl.host,
            path: apiUrl.pathname,
            body: JSON.stringify(req),
            protocol: apiUrl.protocol,
            headers: {
              'Content-Type': 'application/json',
              host: apiUrl.hostname,
              userAuth: 'Bearer ' + token
            }
          })
          const obj = {
            id: chatId,
            type: 'text',
            author: 'Them',
            loading: true,
            created_at: new Date().toUTCString(),
            data: {
              text: ' ',
              systemPrompt: '',
              inputText: newMessage,
              startedChat: false,
              filter: showFilter ? { filter_options: { ...filter } } : null
            }
          }
          handleChatAddition(
            {
              session_id: id,
              chat_id: chatId,
              message: ' ',
              type: 'chat'
            },
            false,
            true
          )
          newMessageList.push(obj)
          const messageIndex = newMessageList.length - 1
          setMessageList(newMessageList)
          const response = await fetch(`${url}`, { ...signedUrl })
          let isMessageEnd = false
          if (response.status === 200 && response.body) {
            const reader = response.body.getReader()
            while (true) {
              const currentObj = messageListRef?.current?.[messageIndex]
              if (currentObj?.userstopped) {
                break
              }
              const { done, value } = await reader.read()
              if (done) {
                break
              }
              let text = new TextDecoder().decode(value)
              if (text.includes('__END_OF_CHAT__')) {
                isMessageEnd = true
                remainingText = text.split('__END_OF_CHAT__')[0]
              }
              if (!isMessageEnd) {
                if (text.includes('\n')) {
                  const newlineMatches = text.match(/\n/g)
                  const newLines =
                    newlineMatches.length === 1 ? '<br>' : '<br><br>'
                  text = text.replace('\n', newLines)
                }
                completeText = completeText + text
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: convertToHTML(completeText),
                    type: 'chat_full'
                  },
                  false,
                  true
                )
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: '__START_OF_CHAT__',
                    type: 'chat_status'
                  },
                  false,
                  true
                )
                if (currentTrackerRef.current === id) {
                  newMessageList[messageIndex].data.text =
                    convertToHTML(completeText)
                  setMessageList(newMessageList)
                }
                scrollToBottom(false, 100)
              } else {
                if (remainingText) {
                  if (remainingText.includes('\n')) {
                    const newlineMatches = remainingText.match(/\n/g)
                    const newLines =
                      newlineMatches.length === 1 ? '<br>' : '<br><br>'
                    remainingText = remainingText.replace('\n', newLines)
                  }
                  completeText = completeText + remainingText
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: convertToHTML(completeText),
                      type: 'chat_full'
                    },
                    false,
                    true
                  )
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.text =
                      convertToHTML(completeText)
                    newMessageList[messageIndex].loading = false
                    setMessageList(newMessageList)
                  }
                  scrollToBottom(false, 100)
                }
                if (text.includes('__END_OF_CHAT__')) {
                  prompt = prompt + text.split('__END_OF_CHAT__')[1]
                } else {
                  prompt = prompt + text
                }
                if (prompt) {
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: prompt,
                      type: 'prompt'
                    },
                    false,
                    true
                  )
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.systemPrompt = prompt
                    setMessageList(newMessageList)
                  }
                }
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: '__END_OF_CHAT__',
                    type: 'chat_status'
                  },
                  false,
                  true
                )
                scrollToBottom(false, 100)
              }
            }
            streamLoadingRef.current = {
              ...streamLoadingRef.current,
              [id]: false
            }
          }
          console.log('messageList', newMessageList)
        } else {
          if (reqSocket) {
            const data = {
              requestType,
              prompt: newMessage,
              referrer_id,
              chat_type: chatType,
              chat_id: chatId,
              proposal_ids: proposalIds,
              filter_options: filter,
              input_type: 'text'
            }
            trackEvent(
              mixpanelEvents.CHAT_MESSAGE_SENT,
              'SUCCESS',
              {},
              { message: newMessage, chat_id: chatId, session_id: id }
            )
            const query = {
              action: 'stream',
              query: data
            }
            if (!socket) {
              return
            }
            socket.send(JSON.stringify(query))
            const obj = {
              id: chatId,
              type: 'text',
              author: 'Them',
              created_at: new Date().toUTCString(),
              loading: true,
              data: {
                text: ' ',
                systemPrompt: '',
                inputText: '',
                error: '',
                status: [],
                startedChat: false,
                filter: showFilter ? { filter_options: { ...filter } } : null
              }
            }
            newMessageList.push(obj)
            handleChatAddition(
              {
                session_id: id,
                chat_id: chatId,
                message: showFilter ? { filter_options: { ...filter } } : null,
                type: 'filter'
              },
              false,
              true
            )
            handleChatAddition(
              {
                session_id: id,
                chat_id: chatId,
                message: ' ',
                type: 'chat'
              },
              false,
              true
            )
            console.log('newMessageList', newMessageList[objReqIndex])
            if (currentTrackerRef.current === id) {
              setMessageList(newMessageList)
            }
          } else {
            let prompt = ''
            let remainingText = ''
            let completeText = ''
            const req = {
              requestType,
              prompt: newMessage,
              referrer_id,
              chat_type: chatType,
              chat_id: chatId,
              proposal_ids: proposalIds,
              filter_options: showFilter
                ? { filter_options: { ...filter } }
                : null
            }
            const url = process.env.REACT_APP_CHAT_URL || ''
            const apiUrl = new URL(url)
            const currentSession = await Auth.currentSession()
            const token = currentSession?.getAccessToken()?.getJwtToken()
            const signedUrl = await signerObj.sign({
              method: 'POST',
              hostname: apiUrl.host,
              path: apiUrl.pathname,
              body: JSON.stringify(req),
              protocol: apiUrl.protocol,
              headers: {
                'Content-Type': 'application/json',
                host: apiUrl.hostname,
                userAuth: 'Bearer ' + token
              }
            })
            const obj = {
              id: chatId,
              type: 'text',
              author: 'Them',
              loading: true,
              created_at: new Date().toUTCString(),
              data: {
                text: ' ',
                systemPrompt: '',
                inputText: newMessage,
                startedChat: false,
                filter: showFilter ? { filter_options: { ...filter } } : null
              }
            }
            newMessageList.push(obj)
            const messageIndex = newMessageList.length - 1
            setMessageList([...newMessageList])
            const response = await fetch(`${url}`, { ...signedUrl })
            let isMessageEnd = false
            if (response.status === 200 && response.body) {
              const reader = response.body.getReader()
              while (true) {
                const currentObj = messageListRef?.current?.[messageIndex]
                if (currentObj?.userstopped) {
                  break
                }
                const { done, value } = await reader.read()
                if (done) {
                  break
                }
                let text = new TextDecoder().decode(value)
                if (text.includes('__END_OF_CHAT__')) {
                  isMessageEnd = true
                  remainingText = text.split('__END_OF_CHAT__')[0]
                }
                if (!isMessageEnd) {
                  if (text.includes('\n')) {
                    const newlineMatches = text.match(/\n/g)
                    const newLines =
                      newlineMatches.length === 1 ? '<br>' : '<br><br>'
                    text = text.replace('\n', newLines)
                  }
                  completeText = completeText + text
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.text = completeText
                    setMessageList([...newMessageList])
                  }
                  scrollToBottom(false, 100)
                } else {
                  if (remainingText) {
                    if (remainingText.includes('\n')) {
                      const newlineMatches = remainingText.match(/\n/g)
                      const newLines =
                        newlineMatches.length === 1 ? '<br>' : '<br><br>'
                      remainingText = remainingText.replace('\n', newLines)
                    }
                    completeText = completeText + remainingText
                    if (
                      currentTrackerRef.current === id &&
                      newMessageList[messageIndex].loading
                    ) {
                      newMessageList[messageIndex].data.text = completeText
                      newMessageList[messageIndex].loading = false
                      setMessageList([...newMessageList])
                    }
                    scrollToBottom(false, 100)
                  }
                  if (text.includes('__END_OF_CHAT__')) {
                    prompt = prompt + text.split('__END_OF_CHAT__')[1]
                  } else {
                    prompt = prompt + text
                  }
                  if (prompt) {
                    if (currentTrackerRef.current === id) {
                      newMessageList[messageIndex].data.systemPrompt = prompt
                      setMessageList([...newMessageList])
                    }
                  }
                  scrollToBottom(false, 100)
                }
              }
            }
            setTextBox('')
            console.log('newMessageList', newMessageList[messageIndex])
            streamLoadingRef.current = {
              ...streamLoadingRef.current,
              [id]: false
            }
          }
        }
      } catch (error) {
        console.log('error', error)
        const obj = {
          id: chatId,
          type: 'text',
          author: 'Them',
          created_at: new Date().toUTCString(),
          data: {
            text: 'Error\nSomething went wrong! Please try again.',
            error,
            inputText: newMessage,
            filter: showFilter ? { filter_options: { ...filter } } : null
          }
        }
        newMessageList.push(obj)
        const data = { chats: [obj], count: 1, id }
        handleChatAddition(data)
        if (currentTrackerRef.current === id) {
          setMessageList(newMessageList)
          console.log('newMessageList', newMessageList)
          setTextBox('')
        }
        streamLoadingRef.current = {
          ...streamLoadingRef.current,
          [id]: false
        }
      }
    }
  }

  useEffect(() => {
    const prevHistoryCount = prevHistoryCountRef.current
    const count = history.length
    const focusIndex = count - prevHistoryCount
    if (focusIndex < 1 || page === 1) {
      const elementId = 'message' + 0
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView()
      } else {
        scrollToBottom(true)
      }
    } else {
      const elementId = 'history' + focusIndex
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView()
      }
    }
    prevHistoryCountRef.current = count
  }, [history, showHistory])

  const scrollToBottom = (force = false, delay) => {
    if (force) {
      if (
        listRef.current &&
        listRef.current?.scrollTop < listRef.current?.scrollHeight
      ) {
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight
          }
        }, 50)
      }
    } else {
      if (scrollLock.current) {
        if (
          listRef.current &&
          listRef.current?.scrollTop < listRef.current?.scrollHeight
        ) {
          if (delay) {
            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollTop = listRef.current.scrollHeight
              }
            }, delay)
          } else {
            listRef.current.scrollTop = listRef.current.scrollHeight
          }
        }
      }
    }
  }

  const handlePull = () => {
    setShowHistory(true)
    fetchHistory(1)
  }

  const handleStopGeneration = async () => {
    const chat_id = currentChatIdRef.current
    let partial_text = 'Generation Stopped by User'
    setStopChatLoading((prev) => {
      return {
        ...prev,
        [trackerId]: true
      }
    })
    const messageIndex = messageList.findIndex(
      (item) => item.id === chat_id && item.author === 'Them'
    )
    if (messageIndex > -1) {
      partial_text = messageList[messageIndex].data.text
      const newMessageList = [...messageList]
      newMessageList[messageIndex].loading = false
      newMessageList[messageIndex].userstopped = true
      newMessageList[messageIndex].data.partialText = partial_text
      setMessageList(newMessageList)
      handleChatAddition(
        {
          session_id: trackerId,
          chat_id,
          message: true,
          type: 'userstopped'
        },
        false,
        true
      )
    }
    partial_text = partial_text || 'Generation Stopped by User'

    const payload = {
      id: chat_id,
      partial_text
    }
    try {
      chatStop(payload)
    } catch (error) {
      console.log('error', error)
    }
    setTimeout(() => {
      streamLoadingRef.current = {
        ...streamLoadingRef.current,
        [trackerId]: false
      }
      setStopChatLoading((prev) => {
        return {
          ...prev,
          [trackerId]: false
        }
      })
    }, 500)
  }

  useEffect(() => {
    relayLoading(loading)
  }, [loading])

  const convertFromBraces = (text) => {
    if (!text) {
      return ''
    }
    const regex = /{([^}]+)}/g
    let position = 0
    const newText = text.replace(regex, (match, p1) => {
      const result = `<span class="chat-variable-span" tabindex=${position}>${p1}</span>&nbsp;`
      position++
      return result
    })
    return newText
  }

  useEffect(() => {
    const handleSpanClick = (event) => {
      if (event.target.classList.contains('chat-variable-span')) {
        prevContentRef.current = event.target.innerText
        event.target.innerHTML = '\u00A0'
        spanRef.current = event.target
        event.target.focus()
        event.target.addEventListener('blur', handleSpanBlur)
        event.target.addEventListener('keydown', handleSpanKeydown)
      }
    }

    const handleSpanBlur = (event) => {
      if (event.target.innerText.replace(/\u00A0/g, '').trim() === '') {
        event.target.innerText = prevContentRef.current
      }
      event.target.removeEventListener('blur', handleSpanBlur)
      event.target.removeEventListener('keydown', handleSpanKeydown)
    }

    const handleSpanKeydown = (event) => {
      if (event.target.innerHTML === '&nbsp;') {
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          event.target.innerText = event.key
          event.preventDefault()
          const range = document.createRange()
          range.selectNodeContents(event.target)
          range.collapse(false)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    }

    document.addEventListener('click', handleSpanClick)

    return () => {
      document.removeEventListener('click', handleSpanClick)
      if (spanRef.current) {
        spanRef.current.removeEventListener('blur', handleSpanBlur)
        spanRef.current.removeEventListener('keydown', handleSpanKeydown)
      }
    }
  }, [])

  const convertToBraces = (html) => {
    const regex = /<span class="chat-variable-span"[^>]*>(.*?)<\/span>&nbsp;/g
    let newText = html.replace(regex, (match, p1) => `{${p1}}`)
    newText = newText === '<br>' ? '' : newText
    return newText
  }

  const replaceAtIndex = (text, elementIndex, chatVariable) => {
    const regex = /{([^}]+)}/g
    let index = 0
    const newText = text.replace(regex, (match) => {
      if (index === elementIndex) {
        const replacement = `{${chatVariable}}`
        index++
        return replacement
      }
      index++
      return match
    })
    return newText
  }

  useEffect(() => {
    const responses = messageList.filter((item) => item.author === 'Them')
    const responsesLength = responses.length
    if (responsesLength !== 0 && responsesLength === 3 && !loading) {
      setOpenFeedback(true)
    }
  }, [messageList.length, loading])

  useEffect(() => {
    setOpenFeedback(false)
  }, [trackerId])

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current
    if (scrollTop === 0 && hasMoreHistory && !loadingHistory && showHistory) {
      fetchHistory(page + 1)
    }
    const bottomThreshold = 5
    const isAtBottom = scrollHeight - scrollTop - clientHeight < bottomThreshold
    if (isAtBottom) {
      scrollLock.current = true
    } else {
      scrollLock.current = false
    }
  }

  const handleFeedbackSet = async (icon, index, id, isMessageList) => {
    setFeedbackObj({
      reaction: icon,
      feedback: '',
      id,
      index
    })
    setOpenChatFeedback(true)
  }

  const handleFeedbackClose = () => {
    setOpenChatFeedback(false)
  }

  useEffect(() => {
    if (!openChatFeedback) {
      setFeedbackObj('')
    }
  }, [openChatFeedback])

  const handleSubmitFeedback = async (
    isMessageList,
    icon = '',
    id = '',
    idx = ''
  ) => {
    if (starsValue === 0) {
      toast.error('Please provide a rating before submitting feedback')
      return
    }
    const feedbackText = newFeedback || feedbackObj.feedback
    const data = {
      reaction: starsValue,
      feedback: feedbackText || '',
      id: id || feedbackObj.id
    }
    const obj = { ...feedbackObj }
    const { index = idx } = obj
    handleFeedbackClose()
    const res = await chatFeedback(data)
    setStarsValue(0)
    if (res.status !== 200) {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'FAILURE',
        {},
        { details: { reaction: icon, feedback: newFeedback } }
      )
    } else {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'SUCCESS',
        {},
        { details: { reaction: icon, feedback: newFeedback } }
      )
      if (!isMessageList) {
        const newHistory = _.cloneDeep(history)
        newHistory[index].data = {
          ...newHistory[index]?.data,
          reaction: starsValue,
          feedback: feedbackText
        }
        setHistory(newHistory)
      } else {
        const newMessageList = _.cloneDeep(messageList)
        newMessageList[index].data = {
          ...newMessageList[index]?.data,
          reaction: starsValue,
          feedback: feedbackText || ''
        }
        setMessageList(newMessageList)
      }
      const relativeIndex = isMessageList ? history.length + index : index
      updateFeedbackCallback({
        reaction: feedbackObj.reaction,
        feedback: feedbackText,
        sessionId: trackerId,
        chatIndex: relativeIndex,
        chatType
      })
    }
  }

  const handleFeedbackChange = (event) => {
    setNewFeedback(event.target.value)
  }

  const handleCloseSessionFeedback = () => {
    setOpenFeedback(false)
    setNewFeedback('')
  }

  const handleSubmitSessionFeedback = async () => {
    const payload = {
      id: trackerId,
      feedback: newFeedback,
      reaction: starsValue,
      type: 'session'
    }
    const res = await chatFeedback(payload)
    if (res.status === 200) {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'SUCCESS',
        {},
        { details: { reaction: starsValue, feedback: newFeedback } }
      )
    }
    setNewFeedback('')
    setOpenFeedback(false)
    setStarsValue(0)
    setStarsHover(-1)
  }

  const renderFeedback = (message, index, isMessageList) => {
    const { data, author, id } = message || {}
    if (author !== 'Them') {
      return null
    }
    const { feedback = '', reaction = '' } = data || {}

    return (
      <div className="flex mr-2">
        {!reaction && (
          <Tooltip title="Feedback">
            <IconButton
              disableRipple
              onClick={() => handleFeedbackSet('', index, id, isMessageList)}
            >
              <RateReviewOutlinedIcon className="size-4" />
            </IconButton>
          </Tooltip>
        )}

        <Dialog
          open={openChatFeedback}
          onClose={handleFeedbackClose}
          fullWidth
          maxWidth="md"
          className="rounded-xl shadow-2xl border-1 border-grey-400"
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '1rem'
            }
          }}
        >
          <DialogTitle className="flex flex-col p-4">
            <div className="flex justify-between items-center">
              <div className="text-base text-dark mb-1 font-poppins">
                Provide additional feedback
              </div>
              <IconButton
                disableRipple
                edge="end"
                color="inherit"
                onClick={handleFeedbackClose}
                aria-label="close"
              >
                <CloseIcon className="size-4" />
              </IconButton>
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent className="p-4">
            <div className="flex items-center">
              <Rating
                name="hover-feedback"
                value={starsValue}
                onChange={(event, newValue) => {
                  // setValue(newValue)
                  setStarsValue(newValue)
                }}
                onChangeActive={(event, newHover) => {
                  // setHover(newHover)
                  setStarsHover(newHover)
                }}
                emptyIcon={
                  <StarIcon
                    style={{
                      opacity: 0.55,
                      color: 'var(--grey-400)'
                    }}
                    fontSize="32px"
                  />
                }
              />
            </div>
            <TextField
              placeholder="(Optional) You can add any specific feedback/details here"
              variant="outlined"
              fullWidth
              defaultValue={feedback}
              onChange={handleFeedbackChange}
              style={{ margin: '20px 0' }}
              multiline={true}
              minRows={3}
              maxRows={6}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0.5rem',
                  fontSize: '0.875rem'
                }
              }}
            />
            <Button2
              primary
              onClick={() => {
                handleSubmitFeedback(isMessageList)
              }}
              style={{ float: 'right' }}
            >
              Submit
            </Button2>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmitFeedback(isMessageList)}
                style={{ float: 'right' }}
              >
                Submit
              </Button> */}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  const handleToggleChatMode = (event, mode) => {
    if (mode) {
      setChatMode(mode)
    }
  }

  const handleImageOptionsSelect = (value, index, id) => {
    setImageData({
      ...imageData,
      [id]: value
    })
  }

  const handleAssetSave = async (message, url) => {
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Saving Image Into Assets'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )

    const { data } = message || {}
    const { systemPrompt = '' } = data || {}
    const payload = {
      image_url: url,
      prompt: systemPrompt
    }
    const res = await saveImagetoAsset(payload)
    toast.dismiss(toastId)
    if (res.status === 200) {
      trackEvent(mixpanelEvents.CHAT_IMAGE_SAVE_INTO_ASSETS, 'SUCCESS', {}, {})
      toast.success('Image saved to asset')
    } else {
      trackEvent(mixpanelEvents.CHAT_IMAGE_SAVE_INTO_ASSETS, 'FAILED', {}, {})
      toast.error('Error while saving image to asset')
    }
  }

  const handleDownloadImage = async (message, url, isS3Obj = false) => {
    const { id } = message || {}
    const fileName = id + '.png'
    if (isS3Obj) {
      initalizeDownload(url, fileName, s3Obj)
    } else {
      window.open(url, '_blank')
    }
  }

  const isSession = chatType === 'session'

  const isShowEmpty =
    _.isEmpty(messageList) &&
    trackerId === 'new' &&
    !loading &&
    showEmptyChatElements

  const placeholderText =
    chatMode === 'chat' ? textBoxPlaceholder : imageTextBoxPlaceHolder

  function safeJSONParse(text) {
    try {
      return JSON.parse(text)
    } catch (e) {
      return text
    }
  }

  const renderMessage = (message, index, isMessage = false) => {
    const {
      data = {},
      type,
      author,
      id,
      loading: loadingMessage = false
    } = message || {}
    let {
      partialText,
      text,
      error,
      imageUrls = [],
      startedChat = false,
      status
    } = data || {}
    text = safeJSONParse(text)
    const errortext =
      type === 'image'
        ? 'Error during Image Generation\nSomething went wrong! Please try again.'
        : 'Error\nSomething went wrong! Please try again.'
    const textToRender = error
      ? errortext
      : partialText
      ? convertToHTML(partialText)
      : convertToHTML(text)
    const isThem = author === 'Them'
    return (
      <ListItem
        id={isMessage ? 'message' : 'history' + index}
        key={index}
        className={classes.message + ' ' + classes[`message${message.author}`]}
      >
        <div
          className={
            isThem
              ? clsx(classes.messageWrapper, classes.messageBg)
              : classes.messageWrapper
          }
        >
          <div>
            <div className={classes.avatarWrapper}>
              {message.author === 'Them' ? (
                <div className={classes.avatarContainer}>
                  {loadingMessage ? (
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: AiLoader
                      }}
                      height={29}
                    />
                  ) : (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: AiLoader,
                        initialSegment: [141, 142]
                      }}
                      segments={[141, 142]}
                      height={29}
                    />
                  )}
                </div>
              ) : (
                <div className={classes.avatarContainer}>
                  <AvatarIcon className={classes.avatar} />
                </div>
              )}
              <div>
                {type === 'image' && !_.isEmpty(imageUrls) ? (
                  <div className={classes.imageContainer}>
                    {imageUrls.map((url, idx) => {
                      return (
                        <Box key={idx} className={classes.imageWrapper}>
                          <ImageRender
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                              overflow: 'hidden'
                            }}
                            id={id + author + idx}
                            effect="blur"
                            src={url}
                            s3Obj={s3Obj}
                            showLoader={false}
                            overlay={
                              <Box className="overlayButtons">
                                <Tooltip title="View Image">
                                  <IconButton
                                    disableRipple
                                    onClick={() =>
                                      setSelectedImage([{ src: url }])
                                    }
                                  >
                                    <PreviewIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Save to Asset"
                                  onClick={() => handleAssetSave(message, url)}
                                >
                                  <IconButton disableRipple>
                                    <SaveIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Download"
                                  onClick={() =>
                                    handleDownloadImage(message, url, isMessage)
                                  }
                                >
                                  <IconButton disableRipple>
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            }
                          />
                        </Box>
                      )
                    })}
                  </div>
                ) : (
                  <div className={classes.messageText}>
                    {!startedChat &&
                      status &&
                      isThem &&
                      status.map((item, index) => {
                        return (
                          <Box key={index} className={classes.chatStatus}>
                            <SvgIcon>
                              {index === status.length - 1 && !startedChat ? (
                                <ChatStatusLoading />
                              ) : (
                                <ChatStatusLoadingDone />
                              )}
                            </SvgIcon>
                            {item}
                          </Box>
                        )
                      })}
                    <div
                      onDoubleClick={(e) =>
                        handleSelect(e, message, index, isMessage)
                      }
                      onMouseUp={(e) =>
                        handleSelect(e, message, index, isMessage)
                      }
                      style={{ userSelect: 'text' }}
                      dangerouslySetInnerHTML={{
                        __html: `<div style="font-family: 'PoppinsRegular', sans-serif;">${textToRender}</div>`
                      }}
                    />
                  </div>
                )}
                {message?.data?.partialText && (
                  <Box className={classes.additionalDataWrapper}>
                    Generation Stopped by User
                  </Box>
                )}
                {!isSession && (
                  <div>
                    <Box className={classes.feedbackWrappers}>
                      {!loadingMessage && type === 'text' && (
                        <CopyButton text={textToRender} />
                      )}
                      {!loadingMessage &&
                        !message?.data?.partialText &&
                        renderFeedback(message, index, isMessage)}
                    </Box>
                  </div>
                )}
                {!loadingMessage && <TimeStamp message={message} />}
                {!loadingMessage && (
                  <ChatFilterTag
                    message={message}
                    voiceConfigs={voiceConfigs}
                  />
                )}
                {!loadingMessage &&
                  message.author === 'Them' &&
                  isSuperUser &&
                  !message?.data?.partialText && (
                    <>
                      <SystemPrompt
                        text={message?.data?.systemPrompt}
                        convertToHTML={convertToHTML}
                      />
                    </>
                  )}
              </div>
            </div>
          </div>
          {isSession && !loadingMessage && (
            <div>
              <Box className={classes.feedbackWrappers}>
                {type === 'text' && <CopyButton text={textToRender} />}
                {!message?.data?.partialText &&
                  renderFeedback(message, index, isMessage)}
              </Box>
            </div>
          )}
        </div>
      </ListItem>
    )
  }

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  const renderImageOptions = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '10px'
        }}
      >
        <SelectButtonCustom
          disabled={loading}
          options={[
            {
              value: 'Real',
              label: 'Photo - Realistic'
            },
            {
              value: 'Graphical',
              label: 'Graphical'
            },
            {
              value: 'Logo',
              label: 'Logo'
            }
          ]}
          tooltip={'Image Type'}
          id="imageType"
          handleOptionClick={handleImageOptionsSelect}
        />
        <SelectButtonCustom
          disabled={loading}
          options={[
            {
              value: 256,
              label: '256x256'
            },
            {
              value: 512,
              label: '512x512'
            },
            {
              value: 1024,
              label: '1024x1024'
            }
          ]}
          defaultSelectedIndex={1}
          id="imageSize"
          tooltip={'Image Size'}
          handleOptionClick={handleImageOptionsSelect}
        />
        <SelectButtonCustom
          disabled={loading}
          options={[
            {
              value: 1,
              label: '1'
            },
            {
              value: 2,
              label: '2'
            },
            {
              value: 3,
              label: '3'
            },
            {
              value: 4,
              label: '4'
            }
          ]}
          defaultSelectedIndex={3}
          tooltip={'Number of Images'}
          id="imageNumber"
          handleOptionClick={handleImageOptionsSelect}
        />
      </Box>
    )
  }

  const renderTextOptions = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '10px'
        }}
      >
        <Button
          disabled={loading}
          variant="outlined"
          className={classes.customButton}
          onClick={() => {
            setShowPromptLibrary(true)
            trackEvent(
              mixpanelEvents.CHAT_PROMPT_LIBRARY_OPENED,
              'SUCCESS',
              {},
              {}
            )
          }}
        >
          Prompt Library
        </Button>
        {_.isEmpty(voiceOptions) ? (
          <Button
            disabled={loading}
            variant="outlined"
            className={classes.customButton}
            onClick={() => {
              handleMangePrompts()
            }}
          >
            Create Voice
          </Button>
        ) : (
          <SelectButtonCustom
            disabled={loading}
            options={voiceOptions}
            defaultSelectedIndex={defaultVoiceIndex}
            tooltip={'Voice Config'}
            id="voiceConfig"
            handleOptionClick={(value) => {
              setSelectedVoice(value)
            }}
          />
        )}
      </Box>
    )
  }

  const getRandomElements = (combinedArray, num) => {
    const availableElements = Math.min(num, combinedArray.length)
    const result = []
    for (let i = 0; i < availableElements; i++) {
      if (combinedArray.length === 0) {
        break
      }
      const randomIndex = Math.floor(Math.random() * combinedArray.length)
      result.push(combinedArray[randomIndex])
      combinedArray.splice(randomIndex, 1)
    }

    return result
  }

  const renderEmptyChat = () => {
    return (
      <Box className={classes.emptyWrapper}>
        <Box className={classes.emptyTitle}>
          {user_name && (
            <Box>
              <Box className={clsx(classes.gradientTextStyle)}>
                Hello, {user_name}
              </Box>
            </Box>
          )}
          <Box>
            <Box className={clsx(classes.gradientTextStyle)}>
              How can I help you today?
            </Box>
          </Box>
        </Box>
        <Box className={classes.emptyContent}>
          Explore your content in a whole new way! Chat with this AI to generate
          text for your projects, delve deeper into your library, or ask any
          lingering questions.
        </Box>
        <Box className={classes.promptWrapper}>
          {randomPrompts &&
            randomPrompts.map((item, index) => {
              return (
                <Box
                  className={classes.promptbtn}
                  key={index}
                  onClick={() => {
                    setTextBox(item?.prompt)
                  }}
                >
                  {item?.title}
                </Box>
              )
            })}
        </Box>
      </Box>
    )
  }

  const handleMangePrompts = () => {
    window.open(SETTINGS_CHAT, '_blank')
  }

  const handlePromptSelection = (item) => {
    const { prompt } = item
    trackEvent(mixpanelEvents.CHAT_PROMPT_USED, 'SUCCESS', {}, { item })
    console.log('item', item)
    setTextBox(prompt)
    setShowPromptLibrary(false)
  }

  const setItemLoading = (id, promptType, val) => {
    if (promptType === 'domain') {
      setDomainPrompts((prevState) => {
        return {
          ...prevState,
          domain_prompts: prevState.domain_prompts.map((item) =>
            item.id === id ? { ...item, disabled: val } : item
          )
        }
      })
    } else if (promptType === 'default') {
      setDomainPrompts((prevState) => {
        return {
          ...prevState,
          default_prompts: prevState.default_prompts.map((item) =>
            item.id === id ? { ...item, disabled: val } : item
          )
        }
      })
    }
  }

  const handlePromptFavorite = async (id, promptType) => {
    setItemLoading(id, promptType, true)
    const req = {
      domain_id
    }
    if (promptType === 'domain') {
      const fav = domain_prompts_fav.includes(id)
        ? domain_prompts_fav.filter((item) => item !== id)
        : [...domain_prompts_fav, id]
      req.domain_config = {
        ...domain_config,
        prompt_fav: {
          ...promptFav,
          domain_prompts: fav
        }
      }
    } else if (promptType === 'default') {
      const fav = default_prompts_fav.includes(id)
        ? default_prompts_fav.filter((item) => item !== id)
        : [...default_prompts_fav, id]
      req.domain_config = {
        ...domain_config,
        prompt_fav: {
          ...promptFav,
          default_prompts: fav
        }
      }
    }
    dispatch({
      type: DOMAIN_CONFIG_UPDATE,
      payload: { domain_config: req.domain_config }
    })
    const res = await putUserDomain(req)
    if (res.status !== 200) {
      toast.error('Unable to update favourites')
      dispatch({
        type: REVERT_FAVPROMPT_DOMAIN_CONFIG,
        payload: {
          id,
          key: promptType === 'default' ? 'default_prompts' : 'domain_prompts'
        }
      })
    }
    setItemLoading(id, promptType, false)
  }

  const renderPromptLibrary = () => {
    const { domain_prompts = [], default_prompts = [] } = domainPrompts || {}
    const tabs = [
      {
        label: 'Shared Prompts',
        children: (
          <LibraryPrompt
            prompts={domain_prompts}
            handlePromptSelection={handlePromptSelection}
            promptSearch={promptSearch}
            promptFilter={promptFilter}
            showCreateNew={true}
            createNew={handleMangePrompts}
            favPrompts={domain_prompts_fav}
            handlePromptFav={handlePromptFavorite}
            promptType="domain"
            isAdmin={isAdmin}
          />
        )
      },
      {
        label: 'Joist Prompts',
        children: (
          <LibraryPrompt
            prompts={default_prompts}
            title={'Joist Prompts'}
            handlePromptSelection={handlePromptSelection}
            promptSearch={promptSearch}
            promptFilter={promptFilter}
            favPrompts={default_prompts_fav}
            handlePromptFav={handlePromptFavorite}
            promptType="default"
            isAdmin={isAdmin}
          />
        )
      }
    ]
    return (
      <Dialog
        fullScreen
        sx={{ margin: '5vh' }}
        open={showPromptLibrary}
        onClose={() => setShowPromptLibrary(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.dialogWrapper}>
          <Container>
            <SectionFixed>
              <Box className={classes.dialogTitleWrapper}>
                <Box>
                  <Box
                    className={clsx(
                      classes.titleText,
                      classes.gradientTextStyle
                    )}
                  >
                    PROMPT LIBRARY
                  </Box>
                </Box>
                <Box className={classes.dialogButtonWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowPromptLibrary(false)
                    }}
                  >
                    Cancel
                  </Button>
                  {isAdmin && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleMangePrompts()
                      }}
                    >
                      Manage Prompts
                    </Button>
                  )}
                </Box>
              </Box>
            </SectionFixed>
            <Section overFlow>
              <Tab
                sectionOverFlow
                data={tabs}
                isFlex={true}
                extraContent={
                  <Box className={classes.promptTitleWrapper}>
                    <Box className={classes.promptSearchWrapper}>
                      <TextField
                        placeholder="Search"
                        value={promptSearch}
                        onChange={(e) => setPromptSearch(e.target.value)}
                      />
                      <SortLibrary
                        filterBy={promptFilter}
                        setFilterBy={setPromptFilter}
                      />
                    </Box>
                  </Box>
                }
              />
            </Section>
          </Container>
        </Box>
      </Dialog>
    )
  }

  const handleRegeneration = async (prompt) => {
    const { selectedText, message } = { ...anchorEl }
    prompt = prompt[Math.floor(Math.random() * prompt.length)]
    let promptFormatted = prompt
    if (prompt.includes('%selectedText%')) {
      promptFormatted = promptFormatted.replace('%selectedText%', selectedText)
    } else {
      promptFormatted = promptFormatted + '\n' + selectedText
    }
    const { data, id } = message || {}
    const { text = '', inputText } = data || {}
    if (prompt.includes('%prevResponse%')) {
      promptFormatted = promptFormatted.replace('%prevResponse%', text)
    }
    if (prompt.includes('%prevQuestion%')) {
      promptFormatted = promptFormatted.replace('%prevQuestion%', inputText)
    }
    // console.log('promptFormatted', promptFormatted)
    handleClose()
  }

  const loadingChat =
    stateLoading ||
    initalizing ||
    initalizePrompts ||
    socketStatus === 'disconnected' ||
    socketStatus === 'reconnecting' ||
    socketStatus === false ||
    !historyProps
  return socketStatus === 'errored' ? (
    <Error
      errorMessage="Error in establishing connection with the server"
      errorLogoSrc={ServerDown}
    />
  ) : loadingChat ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Loader loading={loadingChat} caption={'loading your chat'} />
    </Box>
  ) : (
    <Container>
      <Section>
        <Container>
          <Section overFlow parentRef={listRef} onScroll={handleScroll}>
            {isShowEmpty ? (
              renderEmptyChat()
            ) : (
              <List className={classes.messageList} id="historyList">
                {prefixElements()}
                {!showHistory ? (
                  <ReactPullToRefresh onRefresh={() => handlePull()}>
                    <div
                      className={classes.pullWrapper}
                      onClick={() => handlePull()}
                    >
                      <div>Pull down or click here to load history</div>
                      <ArrowDownwardIcon />
                    </div>
                  </ReactPullToRefresh>
                ) : (
                  loadingHistory && (
                    <div id="chat_loading" style={{ margin: '10px 0px' }}>
                      <div className={classes.dotsWrapper}>
                        <div className="dot-flashing" />
                      </div>
                    </div>
                  )
                )}
                {(!_.isEmpty(messageList) ||
                  loading ||
                  !_.isEmpty(history)) && (
                  <>
                    {showHistory &&
                      history.map((message, index) =>
                        renderMessage(message, index, false)
                      )}
                    {messageList.map((message, index) =>
                      renderMessage(message, index, true)
                    )}
                  </>
                )}
              </List>
            )}
            <Popover
              open={Boolean(anchorEl)}
              anchorReference="anchorPosition"
              anchorPosition={anchorEl?.position}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box>
                {anchorEl?.edit ? (
                  <MenuList>
                    <MenuItem>
                      <TextField
                        placeholder="Modify with prompt"
                        variant="outlined"
                        size="small"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            const prompt = event.target.value
                            handleRegeneration(prompt)
                          }
                          event.stopPropagation()
                        }}
                      />
                    </MenuItem>
                    {selectPrompts &&
                      selectPrompts.map((item, index) => {
                        const { label, prompt } = item
                        return (
                          <MenuItem
                            sx={{ display: 'flex', gap: '3px' }}
                            key={index}
                            onClick={() => {
                              handleRegeneration(prompt)
                            }}
                          >
                            <AutoAwesomeIcon />
                            {label}
                          </MenuItem>
                        )
                      })}
                  </MenuList>
                ) : (
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setAnchorEl((prev) => {
                        return { ...prev, edit: true }
                      })
                    }}
                  >
                    <AutoAwesomeIcon />
                  </IconButton>
                )}
              </Box>
            </Popover>
          </Section>
          <SectionFixed>
            <Box>
              {showFilter && chatMode === 'chat' && (
                <>
                  <Box className={classes.filterContainer}>
                    <Box className={classes.tagsWrapper}>
                      {/* {messageList.length >= 1 && (
                        <div
                          className={clsx(
                            classes.gradientTextStyle,
                            'text-sm font-poppins flex items-center'
                          )}
                          style={{
                            display: 'flex'
                          }}
                        >
                          How did the chat do?
                          <Button2
                            onClick={() => handleFeedbackSet('', '', '', '')}
                          >
                            Leave feedback
                          </Button2>
                        </div>
                      )} */}
                      <TagFilter
                        showCollectionOptions
                        showButton={false}
                        showTags
                        tags={{
                          source_type: {
                            values: [
                              { label: 'Documents', value: 'documents' },
                              { label: 'Knowledge', value: 'knowledge' }
                            ],
                            type: 'default',
                            editable: false
                          },
                          ...tagsOptions
                        }}
                        disabled={loading}
                        selectedTags={selectedTags}
                        onChange={handleTagsValueChange}
                      />
                    </Box>
                    <Box>
                      <TagFilter
                        showCollectionOptions
                        showButton
                        showTags={false}
                        tags={{
                          source_type: {
                            values: [
                              { label: 'Documents', value: 'documents' },
                              { label: 'Knowledge', value: 'knowledge' }
                            ],
                            type: 'default',
                            editable: false
                          },
                          ...tagsOptions
                        }}
                        disabled={loading}
                        selectedTags={selectedTags}
                        onChange={handleTagsValueChange}
                        filterIcon={
                          _.isEmpty(selectedTags) && (
                            <Tooltip title="Add Filter">
                              <Box className={classes.filterButton}>
                                Add Filter
                              </Box>
                            </Tooltip>
                          )
                        }
                        clearFilter={
                          !_.isEmpty(selectedTags) && (
                            <Tooltip title="Clear Filter">
                              <IconButton
                                sx={{ padding: '0px' }}
                                disabled={loading}
                                disableRipple
                                onClick={() => setSelectedTags({})}
                              >
                                <Box className={classes.filterButton}>
                                  Clear Filter
                                </Box>
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <div
              style={{
                position: 'relative'
              }}
              className={
                showFilter
                  ? classes.inputContainerSession
                  : classes.inputContainer
              }
            >
              {openFeedback && (
                <div
                  style={{
                    width: '97%',
                    position: 'absolute',
                    background: 'var(--grey-700)',
                    zIndex: '99',
                    padding: '12px',
                    color: 'white',
                    borderRadius: '8px',
                    boxSizing: 'border-box'
                  }}
                  className={
                    loading || !isSocketConnected
                      ? clsx(
                          classes.contentEditable,
                          classes.contentEditableDisabled
                        )
                      : classes.contentEditable
                  }
                >
                  <div className="flex justify-between">
                    <div className="text-sm font-medium">Help us Improve</div>
                    <IconButton
                      onClick={() => {
                        handleCloseSessionFeedback()
                      }}
                      style={{
                        height: '24px',
                        width: '24px',
                        color: 'white'
                      }}
                    >
                      <CloseIcon className="size-4" color="red" />
                    </IconButton>
                  </div>
                  <div className="flex mb-2 items-center mt-2">
                    <Rating
                      name="hover-feedback"
                      value={starsValue}
                      onChange={(event, newValue) => {
                        // setValue(newValue)
                        setStarsValue(newValue)
                      }}
                      onChangeActive={(event, newHover) => {
                        // setHover(newHover)
                        setStarsHover(newHover)
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{
                            opacity: 0.55,
                            color: 'var(--grey-400)'
                          }}
                          fontSize="32px"
                        />
                      }
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      placeholder="(Optional) You can add any specific feedback/details here"
                      className="w-full p-2 rounded-md border-box bg-grey-600 text-white text-sm font-poppins input-base"
                      defaultValue={''}
                      onChange={handleFeedbackChange}
                      style={{ margin: '4px 0' }}
                      // multiline={true}
                      // rows={2}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                    />

                    <Button2
                      secondary
                      dark
                      style={{
                        width: '100px',
                        justifyContent: 'center'
                      }}
                      onClick={() => {
                        handleSubmitSessionFeedback()
                      }}
                    >
                      Submit
                    </Button2>
                  </div>
                </div>
              )}
              <div
                className={
                  loading || !isSocketConnected
                    ? clsx(
                        classes.contentEditableWrapper,
                        classes.contentEditableDisabled
                      )
                    : classes.contentEditableWrapper
                }
              >
                <ContentEditable
                  id="chat-input-box"
                  disabled={loading || !isSocketConnected}
                  multiline
                  ref={textBoxRef}
                  html={convertFromBraces(textBox) || ''}
                  placeholder={placeholderText}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.shiftKey === false) {
                      const html = textBoxRef.current.lastHtml
                      let newText = convertToBraces(html)
                      if (newText) {
                        newText = htmlToText(newText)
                        handleChatInit(newText, trackerId, chatMode, imageData)
                      }
                    }
                  }}
                  onChange={(e) => {
                    const html = e.currentTarget.innerHTML
                    const newText = html === '<br>' ? '' : html
                    setTextBox(newText)
                  }}
                  className={
                    loading || !isSocketConnected
                      ? clsx(
                          classes.contentEditable,
                          classes.contentEditableDisabled
                        )
                      : classes.contentEditable
                  }
                />

                <div className={classes.endAdornment}>
                  {isSocketConnected ? (
                    loading ? (
                      <IconButton
                        disableRipple
                        disabled={
                          stopChatLoading[trackerId] || chatMode === 'image'
                        }
                        onClick={() => {
                          handleStopGeneration()
                        }}
                      >
                        <StopIcon />
                      </IconButton>
                    ) : (
                      <Button2
                        id="chat-send-button"
                        primary
                        style={{
                          padding: '7px'
                        }}
                        onClick={() => {
                          const html = textBoxRef.current.lastHtml
                          let newText = convertToBraces(html)
                          if (newText) {
                            newText = htmlToText(newText)
                            handleChatInit(
                              newText,
                              trackerId,
                              chatMode,
                              imageData
                            )
                          }
                        }}
                      >
                        <UpArrowIcon className="size-4" strokeWidth={2} />
                      </Button2>
                    )
                  ) : (
                    <IconButton disableRipple disabled={true}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                </div>
              </div>
              {showImageGeneration && (
                <Box className={classes.imageToolbar}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      <Tooltip title={'Toggle Chat Mode'}>
                        <ToggleButtonGroup
                          value={chatMode}
                          exclusive
                          onChange={handleToggleChatMode}
                          color="primary"
                          size="small"
                          disabled={loading}
                          sx={{
                            '& .MuiToggleButtonGroup-grouped': {
                              padding: '2px 6px',
                              textTransform: 'none'
                            },
                            '& .Mui-selected': {
                              backgroundColor: '#DBEAFF'
                            }
                          }}
                        >
                          <ToggleButton value="chat" aria-label="left aligned">
                            Text
                          </ToggleButton>
                          <ToggleButton value="image" aria-label="centered">
                            Image
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Tooltip>
                    </Box>
                    <Box className={classes.resumeWrapper}>
                      <Tooltip title="Generate Resume" arrow>
                        <Button
                          disabled={loading}
                          onClick={(e) => {
                            !loading &&
                              showModal('Generative AI', (onClose) => (
                                <InsertResumeDailog
                                  activeEditor={null}
                                  onClose={onClose}
                                  source="chat"
                                  handleChatInit={handleChatInit}
                                  trackerId={trackerId}
                                />
                              ))
                          }}
                          variant="outlined"
                          sx={{
                            height: '29px'
                          }}
                        >
                          <SvgIcon color="black">
                            <Robot />
                          </SvgIcon>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                  {chatMode === 'image'
                    ? renderImageOptions()
                    : renderTextOptions()}
                </Box>
              )}
              {disclaimer && (
                <Box className={classes.disclaimer}>
                  <TextLineLimiter
                    content={disclaimer}
                    limit={3}
                    wordBreak={'break-word'}
                    indicatorStyle={{
                      color: 'grey',
                      cursor: 'pointer',
                      fontStyle: 'normal',
                      fontWeight: '100',
                      fontSize: '10px'
                    }}
                  />
                </Box>
              )}
              {selectedImage?.length > 0 && (
                <ImageGallery
                  images={selectedImage}
                  onClose={() => setSelectedImage([])}
                />
              )}
            </div>
            {modal}
            {renderPromptLibrary()}
          </SectionFixed>
        </Container>
      </Section>
    </Container>
  )
}

export default ChatBot
