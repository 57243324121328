import { FETCH_TAGS } from '../../actionTypes'
import { getTagCenter } from '../../api'
import { toast } from 'react-toastify'
import { ROUTES } from '../../../config/routes'
import { checkDomainAccess } from '../../../utils/User'

const getTags = (page, user) => async (dispatch) => {
  try {
    const firstRes = await getTagCenter(page)
    if (firstRes.status !== 200) {
      throw new Error(
        firstRes?.response?.data?.error ||
          'Something went wrong while fetching tags'
      )
    }
    const { data: firstPageData, total_pages, total_tags } = firstRes.data || {}
    if (page === 1 && total_pages > 1) {
      const pageRequests = []
      for (let i = 2; i <= total_pages; i++) {
        pageRequests.push({ page: i, promise: getTagCenter(i) })
      }
      const responses = await Promise.all(
        pageRequests.map(({ promise }) => promise)
      )
      const orderedData = Array(total_pages)
      orderedData[0] = firstPageData
      responses.forEach((res, index) => {
        const pageNumber = pageRequests[index].page
        if (res.status === 200) {
          const pageData = res.data
          orderedData[pageNumber - 1] = pageData
        }
      })
      const allData = orderedData.flat()
      const formatedAllData = formatData(allData, user)
      dispatch({
        type: FETCH_TAGS,
        payload: formatedAllData
      })
    } else {
      dispatch({
        type: FETCH_TAGS,
        payload: formatData(firstPageData, user)
      })
    }
  } catch (error) {
    toast.error(error.message || 'Something went wrong while fetching tags')
    dispatch({
      type: FETCH_TAGS,
      payload: null
    })
  }
}

const formatData = (data, user) => {
  const formatedData = {}

  data?.forEach((element, index) => {
    if (element.is_generated) {
      if (element.is_generated && element.key === 'generated_companies') {
        const hasGeneratedClientAccess = checkDomainAccess(
          'generated_tags_companies',
          false,
          user
        )
        if (!hasGeneratedClientAccess) {
          data.splice(index, 1)
          return
        } else {
          element.value = `[${element.value}](${
            ROUTES?.LIBRARY_CLIENTS_RESUME + '/' + element?.value_id
          })`
          element.type = 'multiurl'
        }
      } else if (element.is_generated && element.key === 'generated_people') {
        const hasGeneratedAccess = checkDomainAccess(
          'generated_tags_people',
          false,
          user
        )
        if (!hasGeneratedAccess) {
          data.splice(index, 1)
          return
        } else {
          element.value = `[${element.value}](${
            ROUTES?.LIBRARY_PEOPLE_RESUME + '/' + element?.value_id
          })`
          element.type = 'multiurl'
        }
      } else if (element.is_generated && element.key === 'generated_projects') {
        const hasGeneratedAccess = checkDomainAccess(
          'generated_tags_projects',
          false,
          user
        )
        if (!hasGeneratedAccess) {
          data.splice(index, 1)
          return
        } else {
          element.value = `[${element.value}](${
            ROUTES?.LIBRARY_PROJECTS + '/' + element?.value_id
          })`
          element.type = 'multiurl'
        }
      }
    }
    if (formatedData[element.key]?.data) {
      formatedData[element.key].data.push(element)
    } else {
      formatedData[element.key] = {
        data: [element],
        type: element.type,
        value_type: element?.value_type,
        key_type: element?.key_type,
        editable: element?.editable !== false,
        id: element.id,
        value: element.value,
        is_generated: element.is_generated
      }
    }
  })

  return formatedData
}

export default getTags
