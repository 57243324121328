import { getInitialsFromUserName } from '../../utils/String'
import { ImageRender } from '../../components'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { ProjectIcon } from '../Icons/Icons'

const ImageAvatarFallback = (props) => {
  const {
    name,
    editMode,
    profilePic,
    type,
    style,
    openImage = () => {},
    s3Obj,
    ...rest
  } = props
  const initials = getInitialsFromUserName(name)
  return profilePic ? (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: '50%',
        ...style
      }}
    >
      <ImageRender
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          objectFit: 'cover',
          color: 'transparent',
          textIndent: '10000px'
        }}
        fallback={<Avatar sx={style}>{initials}</Avatar>}
        showLoader={false}
        src={profilePic}
        s3Obj={s3Obj}
        effect="blur"
        onClick={() => {
          openImage(profilePic)
        }}
        {...rest}
      />
    </Box>
  ) : (
    <span
      onClick={() => {
        openImage(profilePic)
      }}
    >
      {type === 'project' ? (
        <div className="p-2 rounded-md grid place-content-center rounded-full bg-grey-700">
          <ProjectIcon className="size-4 text-grey-100" />
        </div>
      ) : (
        <Avatar sx={style}>{initials}</Avatar>
      )}
    </span>
  )
}

export default ImageAvatarFallback
