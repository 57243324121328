import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'PoppinsRegular'
  },
  titleH3: {
    color: theme.palette.primary.main
  },
  titleH4: {
    color: theme.palette.primary.secondary
  },
  switch: {
    display: 'flex'
  },
  box: {
    padding: 24,
    boxSizing: 'border-box',
    width: '100%'
  },
  loaderHeight: {
    height: '100vh'
  }
}))

export { useStyles }
