import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ChevronDownIcon,
  CircleCheckIcon,
  CloudUpload,
  CrossIcon,
  DocUploadIcon,
  MaximizeIcon,
  SemiCircleIcon,
  TrashIcon
} from '../Icons/Icons'
import Button2 from '../Button/Button2'
import { Dialog, Fade, Popover } from '@mui/material'
import { ReactComponent as PdfIcon } from '../../assets/svg/FileIcons/PDF.svg'
import { ReactComponent as PPTIcon } from '../../assets/svg/FileIcons/PPT.svg'
import { ReactComponent as DOCIcon } from '../../assets/svg/FileIcons/DOC.svg'
import { ReactComponent as ImageIcon } from '../../assets/svg/FileIcons/Image.svg'
import { ReactComponent as VideoIcon } from '../../assets/svg/FileIcons/Video.svg'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AwsS3Multipart from '../../utils/uppy/aws-multipart'
import Uppy from '../../utils/uppy/Uppy'
import _, { isEmpty } from 'lodash'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { useSelector } from 'react-redux'
import HeadlessTable from '../TableV2/HeadlessTable'
import { useDropzone } from 'react-dropzone'
import useConfirmation from '../ConfirmDialog'
import {
  deleteProposals,
  delRfx,
  getUploadedFiles,
  removeTagAssociation
} from '../../store/api'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import moment from 'moment'
import ResourceFileView from '../../sections/ResourceFileView'
import { initalizeS3 } from '../../utils/AWS'
const getFileIcon = (file) => {
  const { name, type } = file || {}
  if (name?.includes('ppt')) {
    return <PPTIcon />
  } else if (name?.includes('doc')) {
    return <DOCIcon />
  }
  if (name?.includes('pdf')) {
    return <PdfIcon />
  }
  if (type.includes('image')) {
    return <ImageIcon />
  }

  if (type.includes('video')) {
    return <VideoIcon />
  }
}

const AttachFilesUI = ({
  files,
  setFiles = () => {},
  loading = false,
  profileId,
  acceptedFileType = '.pdf',
  source = 'people' | 'project' | 'client'
}) => {
  const [localFiles, setLocalFiles] = React.useState([])
  const [filesModal, setFilesModal] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [s3Obj, sets3Obj] = useState({})
  const [loadingFiles, setLoadingFiles] = useState(false)

  const refresh = async () => {
    setLoadingFiles(true)
    const response = await getUploadedFiles(profileId)
    if (response.status === 200) {
      setFiles(response.data.files)
    }
    setLoadingFiles(false)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  const handleFileUpload = (e) => {
    const files = e.target.files

    console.log('files', files)
    if (files.length > 0) {
      setFilesModal(true)
      setLocalFiles([...files])
    }
  }

  const onDrop = (acceptedFiles) => {
    console.log('dropped files', acceptedFiles)

    handleFileUpload({ target: { files: acceptedFiles } })
  }

  const onDropFunction = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles)
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0]
    if (errors[0].code === 'file-invalid-type') {
      toast.error(`Invalid file type. Supports only ${fileType} files.`)
    } else if (errors[0].code === 'file-too-large') {
      toast.error('File size exceeds the limit.')
    } else {
      toast.error(errors?.[0]?.message || 'Something went wrong.')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropFunction,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
    accept: {
      application: [acceptedFileType]
    }
    // ...otherProps
  })

  const handleDeleteDocument = async (row) => {
    let src = source
    if (src === 'client') {
      src = 'companies'
    }
    showConfirmDialog({
      deleteType: true,
      confirmButtonText: 'Delete Document',
      extraButtons: [
        {
          text: 'Delete Association to ' + src,
          onClick: async () => {
            const { id } = row
            const data = {
              document_id: id,
              tag_id: profileId
            }
            const response = await removeTagAssociation(data)
            if (response.status === 200) {
              refresh()
            } else {
              toast.error('Failed to delete document association')
            }
          },
          icon: '',
          tooltip: 'clear',
          buttonProps: {
            primary: true
          }
        }
      ],
      onConfirm: async () => {
        const { id, document_type } = row
        let response = {}
        if (document_type === 'rfx') {
          const payload = {
            list_of_ids: [id]
          }
          response = await delRfx(payload)
        } else {
          response = await deleteProposals(id)
        }
        if (response.status === 200) {
          refresh()
        } else {
          toast.error('Failed to delete document')
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the document "${
        row?.proposal_name || row?.document_name || ''
      }" ?`
    })
  }
  if (loading) {
    return (
      <div
        className="grid place-content-center h-full text-center"
        style={{
          border: '1px dashed var(--grey-300)',
          height: 'calc(100% - 50px)',
          borderRadius: '10px',
          margin: '20px'
        }}
        {...getRootProps({})}
      >
        <CircularProgress size={32} />
      </div>
    )
  }
  if (files.length === 0) {
    return (
      <div
        className="grid place-content-center h-full text-center"
        style={{
          border: '1px dashed var(--grey-300)',
          height: 'calc(100% - 50px)',
          borderRadius: '10px',
          margin: '20px'
        }}
        {...getRootProps({})}
      >
        <div className="flex flex-col gap-1 items-center">
          <DocUploadIcon className="size-12 text-grey-500" />
          <p className="text-base m-0 text-grey-700">Drop your files here</p>
          <p className="m-0 text-grey-500 text-xxs">or</p>
          <label htmlFor="docUpload" className="cursor-pointer mt-2">
            <input
              {...getInputProps()}
              type="file"
              multiple
              className="hidden"
              accept="application/pdf"
              id="docUpload"
              onInput={(e) => handleFileUpload(e)}
            />
            <div
              className="flex items-center gap-1 text-xs bg-grey-800 text-white rounded-lg"
              style={{ padding: '6px 20px' }}
            >
              Choose Files
            </div>
          </label>
        </div>
        {filesModal && (
          <FilesModal
            source={source}
            refresh={() => {
              refresh()
              setFilesModal(false)
              document.getElementById('docUpload').value = null
            }}
            acceptedFileType={acceptedFileType}
            profileId={profileId}
            isOpen={filesModal}
            onClose={() => {
              setFilesModal(false)
              document.getElementById('docUpload').value = null
            }}
            files={localFiles}
            setFiles={() => {
              setLocalFiles([])
            }}
          />
        )}
      </div>
    )
  }

  const columns = [
    {
      id: 'name',
      label: 'File Name',
      accessorKey: 'proposal_name',
      children: (row) => {
        return (
          <div className="flex gap-2 items-center w-44">
            <div className="size-4">
              {getFileIcon({
                name: row?.proposal_name || row?.document_name,
                type: row.file_type
              })}
            </div>
            <p
              className="m-0 text-xxs text-left"
              style={{
                width: '80%'
              }}
            >
              {row?.proposal_name || row?.document_name}
            </p>
          </div>
        )
      }
    },
    {
      id: 'document_type',
      label: 'File Type',
      accessorKey: 'document_type',
      children: (row) => {
        return (
          <p className="m-0 text-xxs"> {_.startCase(row?.document_type)}</p>
        )
      }
    },
    {
      id: 'uploaded_at',
      label: 'Uploaded At',
      accessorKey: 'created_at',
      children: (row) => {
        return (
          <p className="m-0 text-xxs">
            {' '}
            {moment.utc(row.created_at).local().format('ll')}
          </p>
        )
      }
    },
    {
      id: '',
      label: '',
      children: (row) => {
        return (
          <div className="flex items-center ml-auto gap-1">
            <Button2
              secondary
              noOutline
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteDocument(row)
              }}
            >
              <TrashIcon className="size-4" />
            </Button2>
            <Button2
              secondary
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
            >
              <MaximizeIcon className="size-4" />
            </Button2>
          </div>
        )
      }
    }
  ]

  const handleFileOpen = (row) => {
    const event = {
      people: mixpanelEvents.PEOPLE_FILES_OPENED,
      client: mixpanelEvents.CLIENT_FILES_OPENED,
      project: mixpanelEvents.PROJECT_FILES_OPENED
    }
    trackEvent(
      event[source],
      'SUCCESS',
      {},
      {
        profile_id: profileId,
        file_id: row.id
      }
    )

    const { document_type } = row
    if (document_type === 'rfx') {
      const { id, document_name } = row
      const file = {
        id,
        file_type: 'rfx',
        file_name: document_name,
        other_data: {
          page_number: 0
        }
      }
      setSelectedFile(file)
    } else {
      const { id, proposal_name } = row
      const file = {
        id,
        file_type: document_type,
        file_name: proposal_name,
        other_data: {
          page_number: 0
        }
      }
      setSelectedFile(file)
    }
  }

  return (
    <div
      className=""
      style={{
        height: 'calc(100% - 100px)'
        // marginTop: '20px'
      }}
    >
      {loadingFiles ? (
        <div
          className="grid place-content-center h-full text-center"
          style={{
            border: '1px dashed var(--grey-300)',
            height: 'calc(100% - 50px)',
            borderRadius: '10px',
            margin: '20px'
          }}
          {...getRootProps({})}
        >
          <CircularProgress size={32} />
        </div>
      ) : (
        <>
          <div className="mt-4 flex justify-end mb-2">
            <label htmlFor="docUpload" className="cursor-pointer">
              <input
                type="file"
                multiple
                className="hidden"
                accept="application/pdf"
                id="docUpload"
                onInput={(e) => handleFileUpload(e)}
              />
              <div
                className="flex items-center gap-2 text-xxs bg-grey-800 text-white rounded-lg font-medium"
                style={{ padding: '6px 16px' }}
              >
                <CloudUpload className="size-4" />
                Choose Files
              </div>
            </label>
          </div>
          <HeadlessTable
            data={files}
            columns={columns}
            containerStyle={{
              height: '100%'
            }}
            onRowClick={(row) => {
              handleFileOpen(row)
            }}
            style={{
              height: 'calc(100% - 100px)',
              overflow: 'auto'
            }}
            hidePagination
          />
        </>
      )}
      {ConfirmDialog}
      {filesModal && (
        <FilesModal
          source={source}
          refresh={() => {
            console.log('refresh')
            refresh()
            setFilesModal(false)
            document.getElementById('docUpload').value = null
          }}
          dropZone={{
            // onDrop: onDropFunction,
            root: { ...getRootProps({}) },
            input: { ...getInputProps() }
          }}
          profileId={profileId}
          isOpen={filesModal}
          onClose={() => {
            setFilesModal(false)
            document.getElementById('docUpload').value = null
          }}
          files={localFiles}
          setFiles={() => {
            setLocalFiles([])
          }}
        />
      )}

      {selectedFile && (
        <Dialog
          fullScreen
          sx={{ margin: '5vh' }}
          disableEnforceFocus={true}
          keepMounted
          open={!!selectedFile}
          onClose={() => setSelectedFile(null)}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%'
            }}
          >
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <ResourceFileView
                s3Obj={s3Obj}
                file={selectedFile}
                setFile={setSelectedFile}
                onClose={() => setSelectedFile(null)}
                onDeleteCallback={(e) => {
                  console.log(e)
                }}
                isModelOpen={!!selectedFile}
                onUpdateCallback={(e) => {
                  console.log(e)
                  refresh()
                }}
                appendToURL
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </div>
  )
}

export default AttachFilesUI

const FilesModal = ({
  files,
  setFiles,
  isOpen,
  onClose,
  profileId,
  dropZone,
  acceptedFileType = '.pdf',
  refresh,
  source
}) => {
  const [uppy, setUppy] = useState({})
  const [uploadedFiles, setUploadedFiles] = useState(files)
  const [tempFiles, setTempFiles] = useState(files)
  const filesProperties = useRef(null)
  const [currentUploadPercentage, setCurrentUploadPercentage] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [documentTypes, setDocumentTypes] = useState(null)

  const onDropFunction = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles, documentTypes)
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0]
    if (errors[0].code === 'file-invalid-type') {
      toast.error(`Invalid file type. Supports only ${fileType} files.`)
    } else if (errors[0].code === 'file-too-large') {
      toast.error('File size exceeds the limit.')
    } else {
      toast.error(errors?.[0]?.message || 'Something went wrong.')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropFunction,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
    accept: {
      application: [acceptedFileType]
    }
    // ...otherProps
  })

  const onDrop = (acceptedFiles) => {
    setTempFiles([...tempFiles, ...acceptedFiles])

    const docTypes = getDocTypes()
    const fileProperties = Array.from(acceptedFiles).map((file) => ({
      document_tag: docTypes.find((item) => {
        if (source === 'people') {
          return item.label === 'Resume'
        }

        return item.label === 'Document'
      }),
      name: file.name
    }))

    const tempFileProps = [...filesProperties.current]

    fileProperties.forEach((item) => {
      tempFileProps.push(item)
    })

    filesProperties.current = tempFileProps
    // setFilesProperties(tempFileProps)
  }

  useEffect(() => {
    if (!isEmpty(uppy)) {
      uppy.on('file-added', (file) => {
        refreshFileState()
      })

      uppy.on('file-removed', () => {
        refreshFileState()
      })
      uppy.on('upload-progress', () => {
        refreshFileState()
      })
      uppy.on('progress', (progress) => {
        setCurrentUploadPercentage(progress)
        if (progress > 0 && progress < 100 && !isUploading) {
          // setIsUploading(true)
        } else {
          refreshFileState()
        }
      })
      uppy.on('upload-error', (file, error, response) => {
        refreshFileState()
      })
      uppy.on('complete', (result) => {
        setTimeout(() => {
          refresh()
          setIsUploading(false)
        }, 3000)
      })
      refreshFileState()
    }
  }, [uppy])

  useEffect(() => {
    async function initalize() {
      const newUppy = new Uppy({
        autoProceed: true,
        restrictions: {
          allowedFileTypes: [
            'application/pdf',
            'video/mp4',
            'video/webm',
            'video/mpeg',
            'video/ogg',
            'video/avi',
            'image/jpeg',
            'image/png',
            'image/jpg',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ]
        },
        showSelectedFiles: true
      }).use(AwsS3Multipart, {
        limit: 4,
        companionUrl: process.env.REACT_APP_API_GATEWAY_URL,
        uploaderType: 'proposal'
      })
      setUppy(newUppy)
    }
    initalize()
  }, [])

  useEffect(() => {
    const options2 = getDocTypes()
    const selectedOption = options2.find((item) => {
      if (source === 'people') {
        return item.label === 'Resume'
      }

      return item.label === 'Document'
    })

    const ImageOption = options2.find((item) => item.label === 'Image')

    const finalProps = tempFiles.map((file) => {
      if (file.type.includes('image')) {
        return { document_tag: ImageOption, name: file.name }
      }

      return { document_tag: selectedOption, name: file.name }
    })
    filesProperties.current = finalProps
    getDocTypes()
  }, [])

  const getDocTypes = () => {
    const values = Object.entries(tagsCenterStateTags || {}).reduce(
      (acc, [key, item]) => {
        if (item.value_type?.includes('document_type')) {
          acc.push({ key, ...item })
        }
        return acc
      },
      []
    )
    const options = values[0]?.data
      ? values[0]?.data
          .filter((item) => {
            return item.value
          })
          .map((item) => {
            return {
              value: item.id,
              label: item.value,
              value_type: item.value_type
            }
          })
      : []

    setDocumentTypes(options)

    return options
  }
  const refreshFileState = () => {
    const uppyfiles = uppy.getFiles()
    setUploadedFiles(uppyfiles)
  }

  const addFiles = (files) => {
    const descriptors = files.map((file, index) => ({
      source: 'resource',
      name: file.name,
      extension: file.name.split('.').pop(),
      type: file.type,
      data: file,
      meta: {
        document_tag: {
          ...filesProperties.current[index].document_tag
        }
      },
      profile_id: profileId
    }))
    try {
      console.log('descriptors', descriptors)
      const event = {
        people: mixpanelEvents.PEOPLE_FILES_UPLOADED,
        client: mixpanelEvents.CLIENT_FILES_UPLOADED,
        project: mixpanelEvents.PROJECT_FILES_UPLOADED
      }
      trackEvent(
        event[source],
        'SUCCESS',
        {},
        {
          profile_id: profileId,
          files: descriptors
        }
      )
      setIsUploading(true)
      uppy.addFiles(descriptors)
    } catch (err) {
      refreshFileState()
      console.log(err)
    }
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Fade}
      keepMounted
      onClose={() => {
        if (!isUploading) {
          onClose()
        }
      }}
    >
      <div
        className="p-4"
        style={{
          width: '500px',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <label htmlFor="moreFiles">
          <div
            style={{
              height: '100px',
              border: '1px dashed var(--grey-300)'
            }}
            {...getRootProps()}
            className="bg-grey-100 rounded-md grid place-content-center cursor-pointer"
          >
            <input
              id="moreFiles"
              type="file"
              multiple
              {...getInputProps()}
              className="hidden"
              onChange={(e) => {
                const files = e.target.files
                setTempFiles([...tempFiles, ...files])

                const fileProperties = Array.from(files).map((file) => ({
                  document_tag: documentTypes.find(
                    (item) => item.label === 'Document'
                  ),
                  name: file.name
                }))

                filesProperties.current = [
                  ...filesProperties.current,
                  ...fileProperties
                ]
              }}
            />
            <div className="flex flex-col justify-center items-center text-xxs">
              <DocUploadIcon className="size-6 text-grey-500" />
              <p className="m-0 mt-1">Drop more files here</p>
            </div>
          </div>
        </label>

        {documentTypes && filesProperties && (
          <div className="flex flex-col gap-2">
            {uploadedFiles.length > 0
              ? uploadedFiles.map((file, index) => (
                  <FileListItem
                    key={index}
                    file={file}
                    documentOptions={documentTypes}
                    properties={filesProperties.current[index]}
                    disableEdit={true}
                    removeFile={() => {
                      const filter = uploadedFiles.filter((f, i) => i !== index)
                      setUploadedFiles(filter)
                    }}
                  />
                ))
              : tempFiles.map((file, index) => (
                  <FileListItem
                    editable={true}
                    key={index}
                    file={file}
                    totalFiles={tempFiles.length}
                    documentOptions={documentTypes}
                    properties={filesProperties.current[index]}
                    removeFile={() => {
                      const filter = tempFiles.filter((f, i) => i !== index)
                      setTempFiles(filter)

                      const filterProperties = filesProperties.current.filter(
                        (f, i) => i !== index
                      )

                      filesProperties.current = filterProperties
                    }}
                    updateProperties={(properties) => {
                      const updatedProperties = filesProperties.current.map(
                        (item, i) => (i === index ? properties : item)
                      )

                      filesProperties.current = updatedProperties
                    }}
                  />
                ))}
          </div>
        )}
        <div className="flex items-center justify-end mt-auto">
          {!isUploading && (
            <Button2 secondary noOutline onClick={() => onClose()}>
              Close
            </Button2>
          )}
          {isUploading ? (
            <div
              className="p-2 rounded-lg text-white text-xxs flex items-center gap-2 font-medium"
              style={{
                backgroundColor: '#ffedd5',
                color: '#ea580c'
              }}
            >
              <SemiCircleIcon className="size-4 animate-spin" />
              Uploading
            </div>
          ) : (
            <Button2
              primary
              onClick={() => addFiles(tempFiles)}
              style={{
                backgroundColor: isUploading && '#f97316 !important'
              }}
            >
              Upload
            </Button2>
          )}
        </div>
      </div>
    </Dialog>
  )
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" size={32} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary', fontSize: '10px' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const FileListItem = ({
  file,
  removeFile,
  documentOptions,
  properties,
  updateProperties,
  disableEdit,
  totalFiles = 0
}) => {
  const [progress, setProgress] = React.useState(
    file?.progress?.percentage ?? 0
  )

  const [filteredTagsOptions, setFilteredTagsOptions] =
    useState(documentOptions)

  const [selectedDocumentType, setSelectedDocumentType] = useState(null)

  console.log('file properties', properties, selectedDocumentType)

  const [searchTag, setSearchTag] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    console.log('percentage', file?.progress?.percentage)
    setProgress(file?.progress?.percentage ?? 0)
  }, [file?.progress?.percentage])

  useEffect(() => {
    console.log('selected', properties)
    setSelectedDocumentType(properties?.document_tag)
  }, [properties?.document_tag])

  return (
    <div className="flex items-center gap-2 bg-grey-100 p-2 rounded-lg text-grey-800">
      <div className="size-6">{getFileIcon(file)}</div>
      <div
        className="flex flex-col gap-1"
        style={{
          width: '80%'
        }}
      >
        <p className="m-0 text-xxs">{file.name}</p>
        <p
          className="m-0 bg-grey-700 rounded-full w-fit text-white flex items-center justify-center gap-1"
          style={{
            padding: '3px 8px',
            paddingLeft: '10px',
            fontSize: '10px',
            pointerEvents: disableEdit && 'none'
          }}
          onClick={handleClick}
        >
          {selectedDocumentType?.label} <ChevronDownIcon className="size-3" />
        </p>

        <Popover
          open={open}
          anchorEl={anchorEl}
          TransitionComponent={Fade}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className="overflow-hidden p-2">
            <SearchNew
              value={searchTag}
              onChange={(value) => {
                setSearchTag(value)

                setFilteredTagsOptions(
                  documentOptions.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                  )
                )
              }}
            />

            <div className="flex flex-col h-36 overflow-auto mt-1 px-1">
              {filteredTagsOptions.map((item, index) => (
                <Button2
                  secondary
                  noOutline
                  onClick={() => {
                    updateProperties({ ...properties, document_tag: item })
                    setSelectedDocumentType(item)
                    handleClose()
                  }}
                  // className="m-0 text-xxs px-4"
                  key={index}
                >
                  {item.label}
                </Button2>
              ))}
            </div>
          </div>
        </Popover>
      </div>
      <div className="flex items-center ml-auto">
        {!progress ? (
          <div
            className="px-2 text-grey-500 grid place-content-center cursor-pointer"
            onClick={removeFile}
            style={{
              display: totalFiles === 1 ? 'none' : 'block'
            }}
          >
            <CrossIcon className="size-4" />
          </div>
        ) : progress >= 100 ? (
          <CircleCheckIcon className="size-4 mr-2" />
        ) : (
          <CircularProgressWithLabel value={progress} />
        )}
      </div>
    </div>
  )
}
