import React, { useState, useRef, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { useStyles } from './styles'
import _, { add, pad, set } from 'lodash'
import { checkExpiry } from '../../utils/AWS'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Loader, ImageRender, ImageGallery } from '../../components'
import MarkdownIt from 'markdown-it'
import Tooltip from '@mui/material/Tooltip'

import { Card, CardContent, Drawer, Fade, Menu, Skeleton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@material-ui/core/Box'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  getAllProjectIds,
  addProjectToClient,
  deleteProjectFromClient,
  getClearBitData,
  updateClientData,
  getClearBitDomainData,
  getIndividualClient,
  changeClientInfo,
  contentSearch,
  moveClient
} from '../../store/api'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import Button2 from '../../components/Button/Button2'
import {
  CircleLoader,
  CrossIcon,
  DeleteIcon,
  DocumentIcon,
  LocationIcon,
  MailIcon,
  MaximizeIcon,
  MinimizeIcon2,
  PlusIcon,
  RedirectIcon,
  SearchIcon,
  StatsIcon,
  ThreeDotsIcon,
  VerifiedIcon,
  WebIcon,
  TrashIcon,
  PencilIcon,
  EditIcon
} from '../../components/Icons/Icons'
import SearchNew from '../ListFilter/SearchNew'
import Notebook from '../Notebook/Notebook'
import DisplayTags from '../ResumeResult/DisplayTags'
import TagCenterUpdate from '../TagCenterUpdate'
import { toast } from 'react-toastify'
import EditClientModal from './EditClientModal'
import AttachFilesUI from '../../components/AttachFilesUI/AttachFilesUI'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import ClientMoveModal from './ClientMoveModal'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import EditProjectModal from '../ProjectResult/EditProjectModal'

function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: '80px',
      width: '80px'
    },
    children: `${name?.split(' ')?.[0]?.[0] ? name.split(' ')[0][0] : ''}${
      name?.split(' ')?.[1]?.[0] ? name.split(' ')[1][0] : ''
    }`
  }
}

const Text = (props) => {
  const { text, style, onClick } = props
  return (
    <span
      style={style}
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={onClick}
    />
  )
}

const ClientResult = (props) => {
  const {
    currentClient: currentClientTemp = {},
    data = {},
    handleCloseProject = () => {},
    s3Obj,
    openType = '',
    refresh = () => {},
    thumbnail_url_list = [],
    screenshotIndex = 0,
    originProposalNames = [],
    topVariatonList = [],
    setTopVariatonList = () => {},
    singleData = null,
    setSingleData = () => {}
  } = props
  const [selectedImage, setSelectedImage] = useState([])
  const md = new MarkdownIt() // this is used to convert textToHtml
  const [signedThumbnailList, setSignedThumbnailList] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [addNewProjectDialog, setAddNewProjectDialog] = useState(false)
  const [projectNames, setProjectNames] = useState([])
  const [addProjectLoading, setAddProjectLoading] = useState(false)
  const [deleteProjectDialog, setDeleteProjectDialog] = useState(false)
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState('')
  const [loading, setLoading] = useState(false)
  const [currentClient, setCurrentClient] = useState(currentClientTemp)
  const [moveModal, setMoveModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [notes, setNotes] = useState(currentClient?.notes)
  const dispatch = useDispatch()
  const [editClient, setEditClient] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [enrichedData, setEnrichedData] = useState(null)
  const [isVerified, setClientVerified] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [contentSearching, setContentSearching] = useState(false)
  const [editProject, setEditProject] = useState(false)
  const open = Boolean(anchorEl)
  const [files, setFiles] = useState([])
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(signedThumbnailList)
      return
    }
    trackEvent(
      mixpanelEvents.CLIENT_DOCUMENT_CONTENT_SEARCHED,
      'SUCCESS',
      {},
      {
        client_id: currentClient?.id,
        search_query: searchQuery
      }
    )
    const filtered = signedThumbnailList.filter((project) => {
      return project.projectName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    })
    setFilteredData(filtered)
  }, [searchQuery])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllProjectIds()
        const enrichedDataRes = await getClearBitData({
          client_group_id: currentClient?.id
        })

        if (enrichedDataRes.status === 200) {
          if (enrichedDataRes) {
            setEnrichedData(enrichedDataRes?.data?.data)
            setClientVerified(enrichedDataRes?.data?.is_verified)
            if (!enrichedDataRes?.data?.is_verified) {
              setShowVerificationModal(true)
            }
          }
        } else {
          setEnrichedData({})
          setShowVerificationModal(true)
        }

        if (res.status === 200) {
          const results = res?.data?.project
          const options = results.map((project) => ({
            label: project?.title,
            value: project?.content_ids,
            name: project?.id
          }))
          setProjectNames(options)
        }
      } catch (error) {
        console.error('Error fetching project ids:', error)
      }
    }
    const { uploaded_files = [] } = currentClient || {}
    setFiles(uploaded_files)
    fetchData()
  }, [currentClient])

  useEffect(() => {
    if (activeTab === 1) {
      trackEvent(
        mixpanelEvents.CLIENT_NOTES_TAB_OPENED,
        'SUCCESS',
        {},
        {
          client_id: currentClient?.id
        }
      )
    }

    if (activeTab === 2) {
      trackEvent(
        mixpanelEvents.CLIENT_FILE_TAB_OPENED,
        'SUCCESS',
        {},
        {
          client_id: currentClient?.id
        }
      )
    }
  }, [activeTab])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClickOpen = () => {
    setAddNewProjectDialog(true)
  }

  const handleClose = () => {
    if (deleteProjectLoading) {
      return
    }
    setAddNewProjectDialog(false)
    setDeleteProjectDialog(false)
    setSelectedClientId('')
  }

  const handleDeleteProjectOpen = (index) => {
    const id = Object.keys(currentClient?.projects)[index]
    setSelectedClientId(id)
    setDeleteProjectDialog(true)
  }

  const handleAddProject = async (selectedProject = null) => {
    setAddProjectLoading(true)
    console.log('selectedProject: ', selectedProject)
    const addProjectData = {
      client_name: currentClient?.title,
      client_group_id: currentClient?.id,
      project_collection_id: selectedProject?.name
    }
    const res = await addProjectToClient(addProjectData)
    handleClose()
    if (res.status === 200) {
      const projects = res?.data?.data?.data
      refresh()

      setCurrentClient({
        ...res?.data?.data
      })
    }
    setAddProjectLoading(false)
  }

  const refreshData = async () => {
    setLoading(true)
    const res = await getIndividualClient({
      client_group_id: currentClient?.id
    })
    if (res.status === 200) {
      const result = res.data
      const notes = result.clients.notes
      setCurrentClient({
        ...result.clients,
        projects: result.projects
      })
      setNotes(notes)
      setLoading(false)
    } else {
      toast.error('Unable to fetch clients')
      setLoading(false)
    }
  }

  const handleDeleteProject = async () => {
    setDeleteProjectLoading(true)
    const deleteProjectData = {
      project_collection_id: selectedClientId,
      client_group_id: currentClient?.id
    }

    const res = await deleteProjectFromClient(deleteProjectData)
    if (res.status === 200) {
      refresh()
    }
    setSelectedClientId('')
    setDeleteProjectLoading(false)
    handleClose()
  }

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []

    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          let signedSrc = ''
          if (imageUrl) {
            signedSrc = await checkExpiry(
              imageUrl?.src ? imageUrl?.src : imageUrl,
              s3Obj
            )
          }
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      const projects = currentClient?.projects
      const proposalInfo = projects?.map((project) => {
        return {
          projectName: project.title,
          projectId: project.id,
          projectType: project?.properties?.project_type?.value ?? 'unknown'
        }
      })
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          ...proposalInfo[index]
        })
      })
      console.log('thumbnailList', thumbnailList)
      setSignedThumbnailList(thumbnailList)
      setFilteredData(thumbnailList)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    // setLoading(true)
    const thumbnailsList = []
    if (currentClient?.projects) {
      Object.keys(currentClient.projects).forEach((project) => {
        if (currentClient.projects[project]?.proposal_id) {
          const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${project?.domain_id}/${currentClient.projects[project]?.proposal_id}/original/1.png`
          thumbnailsList.push(thumbnail_url)
        } else {
          thumbnailsList.push('')
        }
      })
    }
    if (thumbnailsList.length > 0) {
      gets3Urls(thumbnailsList, s3Obj)
    }
  }, [currentClient])

  const preprocessMarkdown = (markdownText) => {
    let format = markdownText.replace(/<br>/g, '  \n')
    format = format.replace(/\*/g, '\n+')
    format = format.replace(/<mark>/g, '')
    format = format.replace(/<\/mark>/g, '')
    return format
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    const preprocessedText = preprocessMarkdown(markdownText)
    return md.render(preprocessedText)
  }

  const renderObjectProperties = (object) => {
    return Object.keys(object).map((key) => (
      <div key={key}>
        {object?.[key] && (
          <>
            <Text
              text={_.startCase(key) + ' - '}
              style={{ fontWeight: 'bold' }}
            />
            {typeof object[key] === 'object' ? (
              renderObjectProperties(object[key])
            ) : (
              <Text text={object[key] + '<br/>'} />
            )}
          </>
        )}
      </div>
    ))
  }

  const viewImage = (image) => {
    setSelectedImage(image)
  }

  const downloadImage = async (payload) => {
    const { proposalName, screenShot } = payload
    const name = proposalName
      ? proposalName.split('.').slice(0, -1).join('.')
      : 'image'
    await initalizeDownload(screenShot, name + '.png', s3Obj)
  }

  const handleVerification = async (verified, domain, clientData) => {
    console.log(
      'verified: ',
      {
        client_id: currentClient?.id,
        is_verified: verified,
        domain
      },
      data
    )

    let body = {}
    if (verified) {
      body = {
        client_id: currentClient?.id,
        is_verified: verified,
        domain,
        logo: clientData?.logo
      }
    } else {
      body = {
        client_id: currentClient?.id,
        is_verified: verified,
        domain: null,
        logo: null
      }
    }

    const res = await updateClientData(body)

    if (res.status === 200) {
      setShowVerificationModal(false)
      setEnrichedData({
        ...enrichedData,
        domain,
        is_verified: verified
      })

      setCurrentClient({
        ...currentClient,
        enrichment_data: {
          ...currentClient.enrichment_data
        },
        is_verified: verified
      })
    }
  }

  const handleMoveClient = async (selectedClient, asNew = false) => {
    console.log('selectedProject', selectedProject, selectedClient)

    if (asNew) {
      moveClient({
        from_client_group_id: currentClient.id,
        client_id: selectedProject.client_id,
        client_name: selectedClient
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            refreshData()
            setMoveModal(false)
          }, 2000)
        }
      })

      return
    }
    moveClient({
      from_client_group_id: currentClient.id,
      to_client_group_id: selectedClient,
      client_id: selectedProject.client_id
      // client_name:
    }).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          refreshData()
          setMoveModal(false)
        }, 2000)
      }
    })
  }

  const columns = [
    {
      id: 'projectName',
      label: 'Project Name',
      children: (row) => {
        return (
          <div className="flex gap-2" style={{ textAlign: 'start' }}>
            {row.projectName}
          </div>
        )
      }
    },
    {
      id: '',
      label: '',
      children: (row, index) => {
        return (
          <div className="flex items-center ml-auto gap-1">
            <Button2
              secondary
              noOutline
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteProjectOpen(index)
              }}
            >
              <TrashIcon className="size-4" />
            </Button2>
            <Button2
              secondary
              noOutline
              style={{
                padding: '6px',
                borderRadius: '6px'
              }}
              onClick={(e) => {
                e.stopPropagation()
                setEditProject({
                  index,
                  id: row?.projectId,
                  title: row?.projectName,
                  properties: {
                    project_type: {
                      value: row?.projectType
                    }
                  }
                })
              }}
            >
              <EditIcon className="size-4" />
            </Button2>
          </div>
        )
      }
    }
  ]

  return openType === 'search_results' ? (
    <></>
  ) : loading ? (
    <Loader loading={loading} caption={''} flex />
  ) : (
    <div className="w-full h-full">
      <div className="h-full">
        <div
          style={{
            width: '70%',
            margin: 'auto',
            padding: '20px',
            paddingBottom: '0px'
          }}
        >
          <div className="flex gap-2 items-center text-xxs text-grey-500 mb-3">
            <span className="cursor-pointer" onClick={handleCloseProject}>
              Companies
            </span>
            <span> {'>'} </span>
            <span className="text-grey-700">{currentClient.title}</span>
          </div>

          <ClientCard
            setEditClient={setEditClient}
            currentClient={currentClient}
            setCurrentClient={setCurrentClient}
            refreshData={refreshData}
            isVerified={isVerified}
            clientData={enrichedData}
            header={true}
            changeDomain={() => setShowVerificationModal(true)}
            removeVerfication={handleVerification}
            fallback={{
              name: currentClient?.title,
              location: currentClient?.location
            }}
          />

          <div className="flex mt-3">
            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => setActiveTab(0)}
              style={{
                borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
              }}
            >
              Projects
            </p>
            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => {
                setActiveTab(1)
                // refreshData()
              }}
              style={{
                borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
              }}
            >
              Notes
            </p>

            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => setActiveTab(2)}
              style={{
                borderBottom: activeTab === 2 && '2px solid var(--grey-700)'
              }}
            >
              Files
            </p>
            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => setActiveTab(3)}
              style={{
                borderBottom: activeTab === 3 && '2px solid var(--grey-700)'
              }}
            >
              Insights
            </p>
          </div>
        </div>

        <div
          className="bg-grey-100 p-4"
          style={{
            height: 'calc(100% - 200px)'
          }}
        >
          <div
            className="rounded-lg"
            style={{
              width: '70%',
              margin: 'auto',
              height: 'calc(100% - 20px)',
              overflowY: 'auto',
              backgroundColor: 'white'
            }}
          >
            {activeTab === 0 && (
              <div
                style={{
                  padding: '10px 20px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '8px 0px',
                    marginBottom: '5px'
                  }}
                >
                  <div>
                    <Button2
                      primary
                      style={{
                        marginLeft: 'auto',
                        flex: 'none',
                        padding: '7.5px 12px'
                      }}
                      onClick={() => {
                        handleClickOpen()
                      }}
                    >
                      <PlusIcon className="size-3 " />
                      Add Project
                    </Button2>
                  </div>
                  <SearchNew
                    value={searchQuery}
                    onChange={(value) => {
                      setSearchQuery(value)
                    }}
                    onClear={() => {
                      setSearchQuery('')
                    }}
                  />
                </div>
                {contentSearching ? (
                  <div className="grid place-content-center">
                    Seraching Content
                  </div>
                ) : (
                  <div className="flex flex-col gap-1">
                    <HeadlessTable
                      data={filteredData}
                      columns={columns}
                      onRowClick={(row) => {
                        window.open(
                          `/library/projects/${row.projectId}`,
                          '_blank'
                        )
                      }}
                      hideMultiSelect
                      containerStyle={{
                        height: '100%'
                      }}
                      style={{
                        height: 'calc(100% - 100px)',
                        overflow: 'auto'
                      }}
                      hidePagination
                    />
                  </div>
                )}
              </div>
            )}

            {activeTab === 1 && currentClient && (
              <Notebook
                notesData={notes}
                source={{
                  id: currentClient?.id,
                  name: currentClient?.title,
                  type: 'client'
                }}
                refresh={refreshData}
                // key={notes}
              />
            )}

            {activeTab === 2 && (
              <div
                style={{
                  padding: '10px 20px',
                  height: '95%'
                }}
              >
                <AttachFilesUI
                  files={files ?? []}
                  setFiles={setFiles}
                  profileId={currentClient?.id}
                  source={'client'}
                />
              </div>
            )}

            {activeTab === 3 && (
              <div
                className="grid place-content-center "
                style={{
                  height: 'calc(100% - 100px)',
                  padding: '10px 20px'
                }}
              >
                <p className="flex items-center gap-2">
                  <StatsIcon /> Coming Soon
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {editProject && (
        <EditProjectModal
          open={editProject}
          setOpen={() => setEditProject(false)}
          project={editProject || {}}
          onUpdate={(data) => {
            console.log('data', data)
            setSignedThumbnailList((prev) => {
              prev[editProject.index] = {
                ...prev[editProject.index],
                projectName: data.name,
                projectType: data.projectType
              }
              return [...prev]
            })
            setEditProject(false)
          }}
        />
      )}

      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}

      <AddProjectModal
        projectList={projectNames}
        s3Obj={s3Obj}
        open={addNewProjectDialog}
        setOpen={setAddNewProjectDialog}
        onAdd={handleAddProject}
      />

      <Dialog
        open={deleteProjectDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '700px',
            padding: '10px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Delete a project'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteProjectLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={deleteProjectLoading}
            onClick={handleDeleteProject}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {enrichedData && (
        <VerifyClient
          clientData={enrichedData}
          handleClose={() => {
            setShowVerificationModal(false)
          }}
          showVerificationModal={showVerificationModal}
          handleVerification={handleVerification}
        />
      )}

      {editClient && (
        <EditClientModal
          open={editClient}
          client={currentClient}
          logo={currentClient?.enrichment_data?.logo}
          setOpen={() => setEditClient(false)}
          onUpdate={() => {
            refreshData()
            setEditClient(false)
          }}
        />
      )}

      <ClientMoveModal
        open={moveModal}
        selectedPerson={currentClient}
        onMove={(selectedClient) => {
          handleMoveClient(selectedClient)
        }}
        onMoveNewPerson={(selectedClient) => {
          handleMoveClient(selectedClient, true)
        }}
        setOpen={setMoveModal}
        onClose={() => {
          setMoveModal(false)
        }}
      />
    </div>
  )
}

export default ClientResult

const VerifyClient = ({
  clientData,
  handleClose,
  showVerificationModal,
  handleVerification
}) => {
  const [domain, setDomain] = useState(clientData?.domain)
  const [validClearBitData, setValidClearBitData] = useState(clientData)

  useEffect(() => {
    if (domain) {
      const timer = setTimeout(() => {
        if (showVerificationModal) {
          getClearBitDomainData({ domain }).then((res) => {
            setValidClearBitData(res?.data?.data)
          })
        }
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [domain])

  return (
    <Dialog
      open={showVerificationModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          width: '400px',
          padding: '10px'
        }
      }}
    >
      <ClientCard clientData={validClearBitData} setDomainChange={setDomain} />
      <div className="flex gap-2">
        <Button2
          secondary
          onClick={handleClose}
          style={{
            justifyContent: 'center',
            width: '100%',
            padding: '8px 20px'
          }}
        >
          Not now
        </Button2>
        <Button2
          style={{
            justifyContent: 'center',
            width: '100%',
            padding: '8px 20px'
          }}
          primary
          disabled={!(domain?.length > 0 && validClearBitData?.name)}
          onClick={() => {
            console.log('handleVerification(true, domain) ', domain)
            handleVerification(true, domain, validClearBitData)
          }}
          autoFocus
        >
          Verify Company
        </Button2>
      </div>
    </Dialog>
  )
}

const ClientCard = ({
  isVerified,
  clientData,
  header,
  currentClient,
  setCurrentClient,
  fallback,
  setDomainChange,
  changeDomain,
  removeVerfication,
  setEditClient,
  refreshData
}) => {
  const [basicInfo, setBasicInfo] = useState({
    name: fallback?.name,
    email: clientData?.email ?? fallback?.email,
    id: clientData?.id,
    bio: clientData?.bio,
    logo: currentClient?.enrichment_data?.logo,
    domain: clientData?.domain,
    location: clientData?.location ?? fallback?.location,
    tags: clientData?.tags,
    industry: clientData?.category?.industry,
    clientType: currentClient?.properties?.client_type?.value ?? 'client'
  })
  // console.log('clientData', clientData)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [domain, setDomain] = useState(basicInfo?.domain)
  const [editName, setEditName] = useState(null)
  useEffect(() => {
    setDomain(basicInfo?.domain)
    if (clientData) {
      setBasicInfo({
        name: fallback?.name,
        email: clientData?.email ?? fallback?.email,
        id: clientData?.id,
        bio: clientData?.bio,
        logo: currentClient?.enrichment_data?.logo,
        domain: clientData?.domain,
        location: clientData?.location ?? fallback?.location,
        tags: clientData?.tags,
        industry: clientData?.category?.industry,
        clientType: currentClient?.properties?.client_type?.value ?? 'client'
      })
    }
  }, [clientData])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNameChange = async (newName) => {
    const newToast = toast.info('Updating client name...')
    const data = {
      client_group_id: currentClient.id,
      client_group_name: newName
    }
    console.log('data: ', data)
    const callback = () => {
      setBasicInfo({
        ...basicInfo,
        name: newName
      })
      toast.dismiss(newToast)
    }
    const res = await changeClientInfo(data)
    if (res.status === 200) {
      callback()
    } else {
      toast.error('Error updating client name')
    }
  }

  if (!clientData && header && !clientData) {
    return (
      <div className="flex gap-2 items-center">
        <Skeleton animation="wave" variant="circular" width={60} height={60} />
        <div>
          <Skeleton
            animation="wave"
            height={10}
            width={150}
            style={{ marginBottom: 2 }}
          />
          <Skeleton animation="wave" height={10} width="80%" />

          <Skeleton
            animation="wave"
            height={10}
            width="40%"
            style={{ marginTop: 2 }}
          />
        </div>
      </div>
    )
  }

  if (header) {
    return (
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          {basicInfo.logo ? (
            <img
              src={basicInfo.logo}
              style={{
                width: '50px',
                height: '50px',
                padding: '6px',
                borderRadius: '10px',
                border: '1px solid var(--grey-200)'
              }}
            />
          ) : (
            <div
              style={{
                width: '50px',
                height: '50px',
                padding: '6px',
                borderRadius: '10px',
                border: '1px solid var(--grey-200)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'var(--grey-100)'
              }}
            >
              {basicInfo?.name?.split(' ')?.[0]?.[0] || ''}
            </div>
          )}

          <div
            // className="w-full"
            style={{
              width: '70%'
            }}
          >
            <p className="m-0 font-medium leading-5 flex items-center gap-1 mb-1 w-full">
              {basicInfo.name}
              {isVerified && <VerifiedIcon className="size-4 text-grey-800" />}

              <span className="bg-grey-100 px-2 text-xxs rounded-full">
                {basicInfo.clientType}
              </span>
            </p>

            <p className="m-0 font-medium text-grey-700 text-xxs mb-1 flex items-center gap-2">
              {basicInfo.domain}{' '}
              <DisplayTags
                tags={currentClient?.tags}
                setShowTagDrawer={setShowTagDrawer}
              />
            </p>
            <div className="flex text-grey-600 gap-2 items-center">
              <p className="m-0  text-xxs">{basicInfo.location}</p>
              {basicInfo.location && basicInfo.industry && (
                <span className="h-1 w-1 bg-grey-500 rounded-full"></span>
              )}
              <p className="m-0 text-xxs">{basicInfo.industry}</p>
            </div>
          </div>
          <Button2
            secondary
            style={{
              marginLeft: 'auto',
              flex: 'none'
            }}
            onClick={() => {
              setEditClient(true)
            }}
          >
            <PencilIcon className="size-3 text-grey-700 " />
            Edit Company
          </Button2>

          {!isVerified && (
            <Button2
              primary
              onClick={() => {
                changeDomain()
              }}
            >
              Verify
            </Button2>
          )}

          {domain && isVerified && (
            <>
              <Button2
                secondary
                onClick={handleClick}
                style={{
                  marginLeft: 'auto',
                  padding: '8px'
                }}
              >
                <ThreeDotsIcon className="size-4 text-grey-500" />
              </Button2>

              <Menu
                elevation={2}
                TransitionComponent={Fade}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                className="p-1"
              >
                <Button2
                  noOutline
                  secondary
                  style={{
                    width: '100%'
                  }}
                  onClick={() => changeDomain()}
                >
                  Change Domain
                </Button2>
                {isVerified ? (
                  <Button2
                    noOutline
                    secondary
                    style={{
                      width: '100%'
                    }}
                    onClick={() => {
                      console.log(
                        'removeVerfication(false, domain) ',
                        clientData,
                        domain
                      )
                      removeVerfication(false, domain, clientData)
                    }}
                  >
                    Remove Verification
                  </Button2>
                ) : (
                  <Button2
                    noOutline
                    secondary
                    style={{
                      width: '100%'
                    }}
                    onClick={() => removeVerfication(true, domain)}
                  >
                    Verify Company
                  </Button2>
                )}
              </Menu>
            </>
          )}
        </div>

        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => {
            setShowTagDrawer(false)
          }}
        >
          <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
            <TagCenterUpdate
              id={currentClient?.id}
              filterTags="clientgroup"
              document_type={'clientgroup'}
              onUpdateCallback={() => {
                refreshData()
              }}
              cancelCallback={() => {
                setShowTagDrawer(false)
              }}
            />
          </Box>
        </Drawer>
      </div>
    )
  }

  return (
    <div className="">
      {clientData?.name ? (
        <>
          <div className="flex flex-col justify-center gap-2 items-center">
            <img
              src={basicInfo.logo}
              style={{
                width: '50px',
                height: '50px',
                padding: '6px',
                borderRadius: '10px',
                border: '1px solid var(--grey-200)'
              }}
            />
            <div className="text-center">
              <p className="m-0 font-medium ">{basicInfo.name}</p>
              <p className="m-0 text-grey-700 text-xs">{basicInfo.location}</p>
            </div>
          </div>
          <div className="mb-3">
            <label className="text-xxs font-medium text-grey-700 ml-1">
              Industry
            </label>
            <div className="flex gap-2 items-center text-xs font-medium ml-1">
              {basicInfo.industry}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center font-medium text-sm">
          No Company Data Found
          <p className="m-0 text-xs font-regular text-grey-500">
            Please enter valid domain
          </p>
        </div>
      )}
      <div className="mb-3">
        <label className="text-xxs font-medium text-grey-700 ml-1">
          Domain
        </label>
        <div className="flex gap-2 items-center bg-grey-50 p-2 px-3 rounded-lg border-1 border-grey-200">
          <WebIcon className="size-4 text-grey-500" />
          <input
            type="text"
            className="input-base bg-grey-50 w-full"
            placeholder="Search..."
            value={domain}
            onChange={(event) => {
              setDomain(event.target.value)
              setDomainChange(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onEnter(event.target.value)
              }
            }}
          />

          <CrossIcon
            className={`size-4 text-grey-500 cursor-pointer ml-auto ${
              domain?.length > 0 ? 'visible' : 'invisible'
            }`}
            onClick={() => setDomain('')}
          />
        </div>
      </div>
    </div>
  )
}

const AddProjectModal = ({
  open,
  setOpen,
  s3Obj,
  onAdd,
  onMoveToPeople,
  selectedPerson,
  projectList,
  personName
}) => {
  const [adding, setAdding] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setProjects(projectList)
  }, [projectList])

  useEffect(() => {
    const filterProjects = projectList.filter((project) => {
      return project.label.toLowerCase().includes(search.toLowerCase())
    })

    setProjects(filterProjects)
  }, [search])

  const handleClose = () => {
    setSelectedProject(null)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      style={{}}
    >
      <div
        className="p-3 border-box border-b-05"
        style={{
          minWidth: '450px'
        }}
      >
        <SearchNew
          value={search}
          onChange={(value) => {
            console.log('first', value)
            setSearch(value)
          }}
          onEnter={(value) => {
            //
          }}
        />
      </div>
      <DialogContent
        style={{
          padding: '0',
          minHeight: '350px',
          maxWidth: '450px'
        }}
      >
        {projects?.map((project, index) => {
          const { name, value, label } = project

          return (
            <div
              className={`flex justify-between  gap-2 px-4 text-center text-sm border-b-05 p-2 row text-grey-600 ${
                selectedProject?.name === name ? 'bg-grey-100' : ''
              }`}
              key={index}
              onClick={() => {
                if (selectedProject?.name === name) {
                  setSelectedProject(null)
                } else {
                  setSelectedProject(project)
                }
              }}
            >
              <div className="font-medium w-full flex gap-2 text-capitalize text-left">
                <span>{label.toLowerCase()}</span>
              </div>

              <div
                className="flex items-center h-full cursor-pointer py-1"
                onClick={(e) => {
                  //   e.stopPropagation()
                  //   handleSetSelectedPeople(id)
                }}
              >
                <input
                  type="checkbox"
                  className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3  ${
                    selectedProject?.name === name ? 'bg-grey-700' : ''
                  }`}
                  checked={selectedProject?.name === name}

                  // onChange={(e) => {
                  //   e.stopPropagation()
                  // }}
                />
              </div>
            </div>
          )
        })}
      </DialogContent>
      <div className="flex justify-end gap-2 items-center p-2 border-t-1">
        <Button2
          secondary
          noOutline={true}
          onClick={handleClose}
          style={{
            padding: '8px 16px'
          }}
        >
          Cancel
        </Button2>
        <Button2
          onClick={() => {
            setAdding(true)
            onAdd(selectedProject)
          }}
          disabled={!selectedProject}
          primary
          style={{
            padding: '8px 16px'
          }}
        >
          {adding ? (
            <CircleLoader className="animate-spin text-white size-4" />
          ) : (
            'Add Project'
          )}
        </Button2>
      </div>
    </Dialog>
  )
}
