import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Switch } from 'react-router'
import { Grid } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth, Hub } from 'aws-amplify'
import { ROUTES } from './config/routes'
import { usePostHog } from 'posthog-js/react'
import { ToastContainer as ReactToastifyContainer } from 'react-toastify'
import ToastContainer from './sections/ToasterAlert/ToasterAlert'
import 'react-toastify/dist/ReactToastify.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/search/lib/styles/index.css'
import '@react-pdf-viewer/highlight/lib/styles/index.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-resizable/css/styles.css'
import store from './store/store'
import { Provider } from 'react-redux'
import { userSignIn, userSignOut } from './store/Authentication/Actions'
import { setLoading, fetchPrompts } from './store/Common/Actions'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './config/theme'
import Sidebar from './components/Sidebar'
import PrivateRoute from './components/PrivateRoute'
import Landing from './pages/Landing'
import Library from './pages/ListPage'
import PdfViewer from './pages/PdfViewer'
import RfxPdfViewer from './pages/RfxPdfViewer'
import Analytic from './pages/Analytics'
import Dashboard from './pages/Dashboard'
import Domain from './pages/Domain'
import Amplify from './pages/Amplify'
import SessionChat from './pages/SessionChat'
import IndesignSSO from './pages/IndesignSSO'
import DashboardResult from './containers/DashboardResult'
import ConnectionChecker from './components/ConnectionChecker'
import Error from './components/Error'
import { Worker } from '@react-pdf-viewer/core'
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles'
import './styles/App.css'
import './styles/utility.css'
import { getDashboardImage } from './config/constants'
import { addInterCom } from './utils/Intercom/Intercom'
import { fetchCollections } from './store/Library/Actions'
import { getSearchHistory } from './store/Chat/Actions'
import { getCollections } from './store/Resource/Action'
import { getTags } from './store/TagCenter/Actions'
import SessionChatViewers from './pages/SessionChatViewer'
import ShareRfx from './pages/ShareRfx'
import { useColor } from './ThemeContext'
import { initalizeS3, getSignedUrl } from './utils/AWS'
import PeopleResume from './containers/PeopleResume'
import ProjectList from './containers/ProjectList'
import ClientList from './containers/ClientList'
import WorkspaceList from './pages/Workspace/List'
import WorkspaceHome from './pages/Workspace/Home'
import WorkspaceDraft from './pages/Workspace/Draft'
import UserInsight from './components/UserInsight/UserInsight'

const ResourceViewer = lazy(() => import('./pages/ResourceViewer'))
const ResumeResult = lazy(() => import('./sections/ResumeResult'))
const Uppy = lazy(() => import('./components/Uppy'))
const AnalyticsPdfViewer = lazy(() => import('./pages/AnalyticsPdfViewer'))
const ShareAnalytics = lazy(() => import('./pages/ShareAnalytics'))
const Settings = lazy(() => import('./pages/Settings'))
const generateClassName = createGenerateClassName({
  seed: 'AppFDS'
})

const App = () => {
  const [indesignCode, setIndesignCode] = useState('')
  useEffect(() => {
    const currentUrl = window.location.href
    if (currentUrl.includes('indesign-sso')) {
      setIndesignCode(currentUrl)
    }
  }, [])

  const {
    LANDING,
    DOMAIN,
    LIBRARY,
    SETTINGS,
    PDF_VIEWER,
    EDITOR_PDF_VIEWER,
    ANALYTICS_HOME,
    WORKSPACE,
    WORKSPACE_HOME,
    WORKSPACE_DRAFT,
    ANALYTICS_PDF_VIEWER,
    RFX_PDF_VIEWER,
    DASHBOARD,
    DASHBOARD_RESULT,
    SIGNIN,
    CHAT,
    SESSION_CHAT_FEEDBACK,
    SHARE_RFX_VIEW,
    SHARE_ANALYTICS_VIEW,
    FILES,
    LIBRARY_INDIVIDUAL_MASTER_PEOPLE_RESUME,
    LIBRARY_INDIVIDUAL_PEOPLE_RESUME,
    LIBRARY_PROJECTS_VIEW,
    LIBRARY_PROJECTS_VARIATION_VIEW,
    LIBRARY_CLIENT_RESUME_VIEW,
    INDESIGNSSO,
    USER_INSIGHTS
  } = ROUTES

  const [s3Obj, sets3Obj] = useState({})

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  if (process.env.REACT_APP_ENV === 'production') {
    document.body.appendChild(addInterCom())
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    document.body.appendChild(addInterCom())
  }

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    const handleAuthEvent = (data) => {
      const { payload = {} } = data
      const { event } = payload
      if (event === 'signOut') {
        const additionalParams = {
          userId: data.payload.data.attributes.sub,
          email: data.payload.data.attributes.email,
          user_name: data.payload.data.attributes.name
        }
        // trackEvent(eventMapping["USER_SIGN_OUT"], "SUCCESS", {}, additionalParams);
        window.location.href = SIGNIN
      }
    }
    Hub.listen('auth', handleAuthEvent)
    return () => {
      Hub.remove('auth', handleAuthEvent)
    }
  }, [])

  const [userSigned, setUserSigned] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const posthog = usePostHog()

  const {
    updateColor,
    updateLogo,
    updateNavigationColor,
    updateBackgroundWallpaper
  } = useColor()

  const [domainPreferences, setDomainPreferences] = useState({})

  useEffect(() => {
    const setTheme = async () => {
      const {
        domain_wallpaper = [],
        domain_logo = '',
        theme_color
      } = domainPreferences
      if (domain_logo) {
        const signedLogo = await getSignedUrl(domain_logo, s3Obj)
        updateLogo(signedLogo)
      } else {
        updateLogo('')
      }
      if (theme_color?.general) {
        updateColor(theme_color?.general)
      }
      if (theme_color?.navigation) {
        updateNavigationColor(theme_color?.navigation)
      }
      if (!_.isEmpty(domain_wallpaper)) {
        const randomWallpaper =
          domain_wallpaper[Math.floor(Math.random() * domain_wallpaper.length)]
        const signedUrl = await getSignedUrl(randomWallpaper, s3Obj)
        updateBackgroundWallpaper(signedUrl)
      } else {
        updateBackgroundWallpaper('')
      }
    }
    if (!_.isEmpty(domainPreferences) && !_.isEmpty(s3Obj)) {
      setTheme()
    }
  }, [s3Obj, domainPreferences])

  const checkUser = () => {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        setUserSigned(true)
        delete user.pool
        delete user.storage
        const userSignInCallback = async (res, userObj) => {
          setShowSidebar(true)
          const { domain_id, domain_wallpaper, domain_logo, theme_color } = res
          setDomainPreferences({
            domain_id,
            domain_wallpaper,
            domain_logo,
            theme_color
          })
          posthog?.identify(user.attributes.sub, {
            email: user.attributes.email,
            domain: domain_id
          })

          store.dispatch(getTags(1, userObj))
          store.dispatch(fetchCollections())
          store.dispatch(fetchPrompts())
          store.dispatch(getCollections())
        }
        store.dispatch(userSignIn({ ...user }, userSignInCallback))

        const session = await Auth.currentSession()
        const accessToken = session?.accessToken?.getJwtToken()
        if (['local', 'staging'].includes(process.env.REACT_APP_ENV)) {
          console.log('Token', accessToken)
        }
      })
      .catch((err) => {
        setUserSigned(false)
        store.dispatch(userSignOut())
        store.dispatch(setLoading(false))
      })
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <Router>
              <ConnectionChecker userSigned={userSigned}>
                {showSidebar && <Sidebar />}
                <Uppy />
                <Grid
                  style={{
                    width: showSidebar ? 'calc(100% - 50px)' : '100%'
                  }}
                >
                  <Suspense
                    fallback={
                      <div className="h-screen grid place-content-center">
                        Loading...
                      </div>
                    }
                  >
                    <Routes>
                      <Route path={LANDING} element={<Landing />} />
                      <Route
                        path={INDESIGNSSO}
                        element={
                          <IndesignSSO
                            indesignCode={indesignCode}
                            setIndesignCode={setIndesignCode}
                          />
                        }
                      />
                      <Route path={SIGNIN} element={<Amplify />} />
                      <Route path="/" element={<PrivateRoute />}>
                        <Route path={DOMAIN} element={<Domain />} />
                        <Route path={LIBRARY} element={<Library />} />
                        <Route path={SETTINGS} element={<Settings />} />
                        <Route path={PDF_VIEWER} element={<PdfViewer />} />
                        <Route
                          path={EDITOR_PDF_VIEWER}
                          element={<PdfViewer />}
                        />
                        <Route
                          path={ANALYTICS_PDF_VIEWER}
                          element={<AnalyticsPdfViewer />}
                        />
                        <Route
                          path={RFX_PDF_VIEWER}
                          element={<RfxPdfViewer />}
                        />
                        <Route path={ANALYTICS_HOME} element={<Analytic />} />
                        <Route path={WORKSPACE} element={<WorkspaceList />} />
                        <Route
                          path={WORKSPACE_HOME}
                          element={<WorkspaceHome />}
                        />
                        <Route
                          path={WORKSPACE_DRAFT}
                          element={<WorkspaceDraft />}
                        />
                        <Route
                          path={DASHBOARD}
                          element={
                            <Dashboard
                              imgURL={getDashboardImage()}
                              s3Obj={s3Obj}
                            />
                          }
                        />
                        <Route
                          path={DASHBOARD_RESULT}
                          element={<DashboardResult />}
                        />
                        <Route path={CHAT} element={<SessionChat />} />
                        <Route
                          path={SESSION_CHAT_FEEDBACK}
                          element={<SessionChatViewers />}
                        />
                        <Route path={FILES} element={<ResourceViewer />} />
                        <Route
                          path={LIBRARY_INDIVIDUAL_MASTER_PEOPLE_RESUME}
                          element={<PeopleResume />}
                        />
                        <Route
                          path={LIBRARY_INDIVIDUAL_PEOPLE_RESUME}
                          element={
                            <ResumeResult displayMode="resume" s3Obj={s3Obj} />
                          }
                        />
                        <Route
                          path={LIBRARY_PROJECTS_VIEW}
                          element={<ProjectList />}
                        />
                        <Route
                          path={LIBRARY_PROJECTS_VARIATION_VIEW}
                          element={<ProjectList />}
                        />
                        <Route
                          path={LIBRARY_CLIENT_RESUME_VIEW}
                          element={<ClientList />}
                        />
                      </Route>
                      <Route path={SHARE_RFX_VIEW} element={<ShareRfx />} />
                      <Route
                        path={SHARE_ANALYTICS_VIEW}
                        element={<ShareAnalytics />}
                      />
                      <Route path={USER_INSIGHTS} element={<UserInsight />} />
                      <Route path="*" element={<Error refresh={false} />} />
                    </Routes>
                  </Suspense>
                </Grid>
              </ConnectionChecker>
            </Router>
          </ThemeProvider>
        </StylesProvider>
        <ToastContainer />
        <ReactToastifyContainer />
      </Provider>
    </Worker>
  )
}

export default App
