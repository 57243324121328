import React, { useEffect, useState } from 'react'
import { Container } from '../../components/Container'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useStyles } from './styles'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import AutoCompleteSearch from '../../components/AutoCompleteSearch'
import { Box, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { ReactComponent as Arrow_Right } from '../../assets/images/Blue_Arrow_Right.svg'
import { checkUserRoleAnnotator } from '../../utils/User'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import SvgIcon from '@mui/material/SvgIcon'
import clsx from 'clsx'
import Button2 from '../../components/Button/Button2'
import { MagicWand } from '../../components/Icons/Icons'
import { toast } from 'react-toastify'
import { Button } from '../../components'
import Checkbox from '@mui/material/Checkbox'

const DashboardSearch = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    bgImage,
    recentBlur,
    iconSearchBar,
    recentBox,
    iconWrapper,
    recentWrapperText,
    recentWrapper,
    searchField,
    container,
    timeContainer,
    greetingContainer,
    radioWrapper,
    searchInputContainer,
    searchInpurOutterWrapper,
    enterIcon,
    disableEnter,
    searchTypeHightlight,
    typeSelection
  } = classes
  const { DASHBOARD_RESULT } = ROUTES
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name, domain_id, title = '', team_name = '' } = domain || {}
  const [searchKey, setSearchKey] = useState()
  const [searchType, setSearchType] = useState('all')
  const [greeting, setGreeting] = useState('')
  const isAnnotator = checkUserRoleAnnotator()

  useEffect(() => {
    const date = new Date()
    const hour = date.getHours()
    if (hour >= 0 && hour < 12) {
      setGreeting('Good Morning')
    } else if (hour >= 12 && hour < 18) {
      setGreeting('Good Afternoon')
    } else {
      setGreeting('Good Evening')
    }
    if (
      (!title || !team_name) &&
      domain_id &&
      !localStorage.getItem('profileUpdateToast')
    ) {
      toast(
        <div className="font-poppins">
          <div className="text-base text-dark mb-1 font-poppins">
            Profile Incomplete
          </div>
          <div className="text-sm mb-3 font-poppins text-grey-600">
            Please complete your profile to get the best experience
          </div>
          <div className="flex justify-end">
            <div className="flex items-center text-xs mr-1">
              <Button2
                secondary
                noOutline
                onClick={() => {
                  localStorage.setItem('profileUpdateToast', true)
                }}
              >
                Skip for now
              </Button2>
            </div>
            <div>
              <Button2
                primary
                onClick={() => {
                  navigate(ROUTES.SETTINGS_PROFILE)
                }}
              >
                Complete
              </Button2>
            </div>
          </div>
        </div>,
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          closeButton: false,
          className:
            'rounded-xl shadow-2xl bg-grey-100 border-1 border-grey-400'
        }
      )
    }
  }, [])

  const handleKeyDown = (e, val) => {
    if (e.key !== 'Enter') return
    e.preventDefault()
    e.target.blur()
    let newSearchKey = ''
    let newSearchType = ''
    if (val) {
      if (val?.type === 'recent') {
        newSearchKey = val?.search_keyword
        newSearchType = val?.search_type
      } else {
        if (val) {
          if (val?.label) {
            newSearchKey = val?.label
          } else {
            newSearchKey = val
          }
        }
        newSearchType = searchType
      }
      setSearchKey(newSearchKey)
      setSearchType(newSearchType)
    }
    if ((newSearchKey && newSearchType) || e.target.value) {
      handleSearch(newSearchKey, newSearchType)
    }
  }

  const handleSearch = (newKey, keyType) => {
    if (newKey || searchKey) {
      if (
        JSON.stringify(keyType || searchType).replaceAll('"', '') === 'chat'
      ) {
        const sessionId = 'new'
        navigate(`/chat/${sessionId}`, {
          state: {
            searchKey: JSON.stringify(newKey || searchKey).replaceAll('"', '')
          }
        })
        trackEvent(
          mixpanelEvents.DASHBOARD_CHAT,
          'SUCCESS',
          {},
          { searchKey: JSON.stringify(newKey || searchKey).replaceAll('"', '') }
        )
      } else {
        const keyword = JSON.stringify(newKey || searchKey)
        const type = keyType || searchType
        const searchParams = createSearchParams({
          keyword,
          searchType: type
        }).toString()
        navigate(`${DASHBOARD_RESULT}?${searchParams}`)
      }
    }
  }

  const getFormatedSearchKey = (searchKeyword = '') => {
    const keyWords = searchKeyword
      .replace(' in text', '')
      .replace(' in assets', '')
      .replace(' in knowledge', '')
    return keyWords
  }

  const handleTypeSelect = (e) => {
    if (e.target.value === 'chat') {
      trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TYPE_CHAT, 'SUCCESS', {}, {})
    }

    if (e.target.value === 'all') {
      trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TYPE_TEXT, 'SUCCESS', {}, {})
    }

    if (e.target.value === 'asset') {
      trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TYPE_ASSETS, 'SUCCESS', {}, {})
    }

    const value = e.target.value

    setSearchType(value)
  }

  return (
    <Container>
      <Box className={container}>
        <Box className={searchInpurOutterWrapper}>
          <Box
            className="glass-card"
            style={{
              width: '80%',
              padding: '40px 50px'
            }}
          >
            <Box className="text-grey-900 text-3xl font-medium">
              {greeting}, {user_name}
            </Box>
            <Box className="text-grey-700 text-base font-medium">
              {moment().format('dddd, MMMM DD, YYYY')}
            </Box>
            <Box className={searchField}>
              <AutoCompleteSearch
                id="dashboard-search-input-field"
                s3Obj={props?.s3Obj}
                setSearchKey={setSearchKey}
                setSearchType={(e) => {
                  if (e === 'text') {
                    setSearchType('all')
                  } else {
                    setSearchType(e)
                  }
                }}
                searchType={searchType}
                showSearchIcon={false}
                searchKey={getFormatedSearchKey(searchKey)}
                handleKeyDown={handleKeyDown}
                endAdornment={
                  <Button2
                    id="dashboard-search-button"
                    primary
                    onClick={() => handleSearch()}
                    disabled={!searchKey}
                  >
                    <MagicWand className="size-4" />
                    {searchType === 'chat' ? 'Ask' : 'Search'}
                  </Button2>
                  // <Box
                  //   className={searchKey ? '' : disableEnter}
                  //   onClick={() => handleSearch()}
                  // >
                  //   <Box className={clsx(enterIcon, iconSearchBar)}>
                  //     <SvgIcon>
                  //       <Arrow_Right />
                  //     </SvgIcon>
                  //   </Box>
                  // </Box>
                }
                initalState={false}
              />
            </Box>
            <Box>
              <RadioGroup
                className={radioWrapper}
                row
                value={searchType || ''}
                onChange={(e) => handleTypeSelect(e)}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio className={typeSelection} />}
                  label={<span className="text-sm">Text</span>}
                />
                <FormControlLabel
                  value="asset"
                  control={<Radio className={typeSelection} />}
                  label={<span className="text-sm">Assets</span>}
                />
                {!isAnnotator && (
                  <FormControlLabel
                    value="chat"
                    control={<Radio className={typeSelection} />}
                    label={<span className="text-sm">Chat</span>}
                  />
                )}
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
export default DashboardSearch
